import { Box } from "@mui/material";

interface TabPanelProps {
  children?: React.ReactNode;
  tab: string | number;
  value: string | number;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, tab } = props;

  return (
    <div role="tabpanel" hidden={value !== tab}>
      {value === tab && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};
