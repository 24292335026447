const theme = {
  appBarColor: "#2C1244",
  primaryColor: "#FF8C00",
  tabTitle: "Linx Sistemas",
  faviconUrl:
    "https://www.linx.com.br/app/themes/linx/webpack/dist/images/favicons/apple-icon-57x57.png",
  loginRightBackgroundColor: "#2C1244",
  hideLogoWhenMenuUncollapsed: true,
};
export default theme;
