import {
  EntryFormPage_EntryQuery,
  EntryFormPage_InitialDataQuery,
} from "../../../../generated/graphql";
import { DataGrid, GridColumns, GridRowsProp } from "@mui/x-data-grid";
import { useState, forwardRef, useImperativeHandle } from "react";
import { TableContainer, TableTitleText } from "./components";

type ProductsTableProps = {
  rows: GridRowsProp;
  columns: GridColumns;
};

type ProductRowMap = Record<
  string,
  {
    consultantId: string;
    productId: string;
    quantity: number;
  }
>;

export type ProductsTableHandler = {
  getValue: () => ProductRowMap;
};

export const ProductsTable = forwardRef<
  ProductsTableHandler,
  ProductsTableProps
>(({ rows, columns }, ref) => {
  const [value, setValue] = useState<ProductRowMap>({});
  useImperativeHandle(
    ref,
    () => ({
      getValue: () => value,
    }),
    [value]
  );
  return (
    <TableContainer>
      <TableTitleText>Produtos</TableTitleText>
      <DataGrid
        rows={rows}
        columns={columns}
        hideFooter
        disableColumnFilter
        disableColumnMenu
        onCellEditCommit={(params) => {
          setValue({
            ...value,
            [`${[params.id]}:${params.field}`]: {
              consultantId: params.field,
              productId: params.id as string,
              quantity: params.value as number,
            },
          });
        }}
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            textOverflow: "clip",
            whiteSpace: "break-spaces",
            lineHeight: 1,
          },
        }}
      />
    </TableContainer>
  );
});

type ParseProductTablePropsParams = {
  products: EntryFormPage_InitialDataQuery["products"];
  consultants: EntryFormPage_InitialDataQuery["consultants"];
  entry: EntryFormPage_EntryQuery["entry"] | undefined;
};

export const parseProductTableProps = ({
  products,
  consultants,
  entry,
}: ParseProductTablePropsParams) => {
  const productColumns: GridColumns = [
    {
      field: "product",
      headerName: "Product",
      editable: false,
      width: 400,
    },
    ...(consultants.map((i) => ({
      field: i.uuid,
      headerName: i.name,
      editable: true,
      width: 250,
      type: "number",
    })) ?? []),
  ];
  // const productColumns: GridColumns = [
  //   {
  //     field: "product",
  //     headerName: "Product",
  //     editable: false,
  //     width: 250,
  //   },
  //   ...(products.map((i) => ({
  //     field: i.uuid,
  //     headerName: `${i.ProductCatalog?.name} ${
  //       i.ProductCatalog?.code ? `(${i.ProductCatalog.code})` : ""
  //     }`,
  //     editable: true,
  //     width: 400,
  //     type: "number",
  //   })) ?? []),
  // ];
  const createdRows: Record<
    string,
    EntryFormPage_EntryQuery["entry"]["ProductEntries"][0]
  > =
    entry?.ProductEntries.reduce(
      (acc, i) => ({
        ...acc,
        [`${i.Product.uuid}:${i.Consultant.uuid}`]: i,
      }),
      {}
    ) ?? {};
  const productRows: GridRowsProp =
    products.map((i) => ({
      id: i.uuid,
      product: `${i.ProductCatalog?.name} ${
        i.productCode ? `(${i.productCode})` : ""
      }`,
      ...consultants.reduce(
        (acc, j) => ({
          ...acc,
          [j.uuid]: createdRows[`${i.uuid}:${j.uuid}`]?.quantity ?? 0,
          entry: createdRows[`${i.uuid}:${j.uuid}`],
        }),
        {}
      ),
    })) ?? [];
  return {
    productColumns,
    productRows,
  };
};

export const createProductConsultantList = ({
  products,
  consultants,
}: CreateProductConsultantList) => {
  const obj: Record<
    string,
    { consultantId: string; productId: string; quantity: 0 }
  > = {};
  for (var i of products) {
    for (var j of consultants) {
      obj[`${i.uuid}:${j.uuid}`] = {
        productId: i.uuid,
        consultantId: j.uuid,
        quantity: 0,
      };
    }
  }
  return obj;
};

type CreateProductConsultantList = {
  products: EntryFormPage_InitialDataQuery["products"];
  consultants: EntryFormPage_InitialDataQuery["consultants"];
};
