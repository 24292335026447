import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useTenant } from "../context/TenantContext";

type DealershipDropdownProps = {
  disabled?: boolean;
};

export const DealershipDropdown: React.FC<DealershipDropdownProps> = ({
  disabled,
}) => {
  const { dealerships, dealershipId, setDealershipId } = useTenant();

  return (
    <Autocomplete
      options={dealerships}
      getOptionLabel={(option) => option.fantasyName ?? ""}
      renderInput={(params) => <TextField {...params} label="Concessionária" />}
      onChange={(_e, value) => setDealershipId(value?.uuid)}
      value={dealerships.filter((i) => i.uuid === dealershipId)?.[0] || null}
      sx={{ width: "100%", marginBottom: 2 }}
      disabled={disabled}
    />
  );
};
