import styled from "@emotion/styled";
import {
  Icon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import _ from "lodash";
import {
  useDealershipTablePage_DealershipsQuery,
  useDealershipTablePage_DeleteDealershipMutation,
} from "../../../../generated/graphql";
import { useTenant } from "../../../context/TenantContext";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../../../components/DeleteModal";
import { useState } from "react";
import { GroupSelectorFrame } from "../../../components/GroupSelectorFrame";

const Container = styled(Paper)`
  margin: 32px;
`;

const CToolbar = styled(Toolbar)`
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
`;

const Title = styled(Typography)`
  flex: 1;
`;

export const DealershipTablePage = () => {
  const { groupId } = useTenant();
  const navigate = useNavigate();
  const [deleteId, setDeleteId] = useState<string | undefined>();
  const [deleteDealership, { loading: deleteLoading }] =
    useDealershipTablePage_DeleteDealershipMutation();
  const { data, refetch } = useDealershipTablePage_DealershipsQuery({
    variables: { groupId: groupId! },
    skip: !groupId,
  });

  return (
    <GroupSelectorFrame>
      <Container>
        <CToolbar>
          <Title>Empresas</Title>
          <IconButton onClick={() => navigate("/ravpro/dealerships/create")}>
            <Icon>add</Icon>
          </IconButton>
        </CToolbar>
        <Table>
          {data?.dealerships && data.dealerships.length > 0 ? (
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Grupo</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {_.orderBy(data?.dealerships, "fantasyName").map((row) => (
              <TableRow key={row.uuid} hover>
                <TableCell>{row.fantasyName}</TableCell>
                <TableCell>{row.Group.name}</TableCell>
                <TableCell align="right">
                  <IconButton
                    onClick={() =>
                      navigate(`/ravpro/dealerships/update/${row.uuid}`)
                    }
                  >
                    <Icon>edit</Icon>
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setDeleteId(row.uuid);
                    }}
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {data?.dealerships && data.dealerships.length === 0 ? (
              <TableRow>
                <TableCell align="center">Nenhum registro encontrado</TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
        <DeleteModal
          open={!!deleteId}
          onClose={() => setDeleteId(undefined)}
          loading={deleteLoading}
          onConfirm={async () => {
            await deleteDealership({ variables: { uuid: deleteId! } });
            setDeleteId(undefined);
            refetch();
          }}
        />
      </Container>
    </GroupSelectorFrame>
  );
};
