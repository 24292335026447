import React from "react";
import {
  TenantContext_GroupsQuery,
  useTenantContext_GroupsQuery,
} from "../../generated/graphql";

type Groups = TenantContext_GroupsQuery["groups"];

type Dealerships = TenantContext_GroupsQuery["groups"][0]["Dealerships"];

export type TenantContextData = {
  groupId?: string | null;
  dealershipId?: string | null;
  setGroupId: (groupId?: string) => void;
  setDealershipId: (dealershipId?: string) => void;
  groups: Groups;
  dealerships: Dealerships;
  refetch: () => void;
};

export const TenantContext = React.createContext<TenantContextData>(null!);

export const setStoredGroupId = (groupId: string) =>
  localStorage.setItem("groupId", groupId);

export const getStoredGroupId = () => localStorage.getItem("groupId");

export const deleteStoredDealershipId = () =>
  localStorage.removeItem("dealershipId");

export const setStoredDealershipId = (dealershipId?: string) => {
  if (dealershipId) {
    localStorage.setItem("dealershipId", dealershipId);
  } else {
    localStorage.removeItem("dealershipId");
  }
};

export const getStoredDealershipId = () => localStorage.getItem("dealershipId");

export function TenantProvider({ children }: { children: React.ReactNode }) {
  const [groupId, setGroupId] = React.useState<string | undefined | null>(
    getStoredGroupId()
  );
  const [dealershipId, setDealershipId] = React.useState<
    string | undefined | null
  >(getStoredDealershipId());
  const { data, refetch } = useTenantContext_GroupsQuery();

  return (
    <TenantContext.Provider
      value={{
        groupId,
        dealershipId,
        setGroupId: (groupId) => {
          if (groupId) {
            setStoredGroupId(groupId);
            setGroupId(groupId);
            deleteStoredDealershipId();
            setDealershipId(undefined);
          }
        },
        setDealershipId: (dealershipId) => {
          setStoredDealershipId(dealershipId);
          setDealershipId(dealershipId);
        },
        groups: data?.groups ?? [],
        dealerships:
          data?.groups.filter((i) => i.uuid === groupId)?.[0]?.Dealerships ??
          [],
        refetch: () => refetch(),
      }}
    >
      {children}
    </TenantContext.Provider>
  );
}

export function useTenant() {
  return React.useContext(TenantContext);
}
