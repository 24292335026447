import { Box } from "@mui/material";
import { icons } from "../assets/icons";
import { styled } from "@mui/material/styles";
import { Icon } from "../components/Icon";

const StyledIcon = styled(Icon)`
  color: #54667a;
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 24px;
  font-size: 24px;
`;

const List = styled("div")`
  display: flex;
  flex-direction: column;
`;

const ListItemContainer = styled("div")`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
`;

export function IconPage() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <List>
        {icons.map((i) => (
          <ListItemContainer key={i}>
            <StyledIcon type={i} />
            <div>{i}</div>
          </ListItemContainer>
        ))}
      </List>
      ;
    </Box>
  );
}
