import { Button, LinearProgress, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import {
  usePremiumDescriptionFormPage_GroupQuery,
  usePremiumDescriptionFormPage_UpsertPremiumDescriptionMutation,
} from "../../../../generated/graphql";
import styled from "@emotion/styled";
import { toast } from "react-hot-toast";
import { useTenant } from "../../../context/TenantContext";
import { GroupSelectorFrame } from "../../../components/GroupSelectorFrame";

type PremiumDescriptionFormData = {
  premium1?: string;
  premium2?: string;
  premium3?: string;
  premium4?: string;
  premium5?: string;
  premium6?: string;
};

export function PremiumDescriptionFormPage() {
  const { groupId } = useTenant();

  const { data, loading } = usePremiumDescriptionFormPage_GroupQuery({
    variables: { uuid: groupId! },
    skip: !groupId,
  });
  const [upsertPremiumDescription, { loading: upsertLoading }] =
    usePremiumDescriptionFormPage_UpsertPremiumDescriptionMutation();
  const onSubmit = async (data: PremiumDescriptionFormData) => {
    await toast.promise(
      upsertPremiumDescription({
        variables: {
          groupId: groupId!,
          premium1: data.premium1,
          premium2: data.premium2,
          premium3: data.premium3,
          premium4: data.premium4,
          premium5: data.premium5,
          premium6: data.premium6,
        },
      }),
      {
        loading: "Cadastrando",
        success: "Sucesso!",
        error: "Erro, tente novamente.",
      }
    );
  };
  return (
    <GroupSelectorFrame>
      {!loading ? (
        <PremiumDescriptionForm
          {...data?.group.PremiumDescription}
          onSubmit={onSubmit}
          loading={upsertLoading}
        />
      ) : (
        <LinearProgress />
      )}
    </GroupSelectorFrame>
  );
}

const Form = styled("form")`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 400px;
  margin: 16px auto;
`;

type PremiumDescriptionFormProps = PremiumDescriptionFormData & {
  onSubmit: (data: PremiumDescriptionFormData) => void;
  loading: boolean;
};

const PremiumDescriptionForm: React.FC<PremiumDescriptionFormProps> = ({
  premium1,
  premium2,
  premium3,
  premium4,
  premium5,
  premium6,
  onSubmit,
  loading,
}) => {
  const defaultValues = {
    premium1: premium1 ?? "",
    premium2: premium2 ?? "",
    premium3: premium3 ?? "",
    premium4: premium4 ?? "",
    premium5: premium5 ?? "",
    premium6: premium6 ?? "",
  };
  const { handleSubmit, control } = useForm({
    defaultValues,
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="premium1"
        control={control}
        defaultValue={defaultValues.premium1 ?? ""}
        render={({ field }) => (
          <TextField inputProps={field} label="Prêmio 1" />
        )}
      />
      <Controller
        name="premium2"
        control={control}
        defaultValue={defaultValues.premium2 ?? ""}
        render={({ field }) => (
          <TextField inputProps={field} label="Prêmio 2" />
        )}
      />
      <Controller
        name="premium3"
        control={control}
        defaultValue={defaultValues.premium3 ?? ""}
        render={({ field }) => (
          <TextField inputProps={field} label="Prêmio 3" />
        )}
      />
      <Controller
        name="premium4"
        control={control}
        defaultValue={defaultValues.premium4 ?? ""}
        render={({ field }) => (
          <TextField inputProps={field} label="Prêmio 4" />
        )}
      />
      <Controller
        name="premium5"
        control={control}
        defaultValue={defaultValues.premium5 ?? ""}
        render={({ field }) => (
          <TextField inputProps={field} label="Prêmio 5" />
        )}
      />
      <Controller
        name="premium6"
        control={control}
        defaultValue={defaultValues.premium6 ?? ""}
        render={({ field }) => (
          <TextField inputProps={field} label="Prêmio 6" />
        )}
      />
      <Button type="submit" variant="contained" disabled={loading}>
        Cadastrar
      </Button>
    </Form>
  );
};
