const theme = {
  appBarColor: "#333943",
  primaryColor: "#FF0000",
  tabTitle: "DPASCHOAL - SCALAR/",
  faviconUrl: "https://www.dpaschoal.com.br/arquivos/favicon.png",
  loginRightBackgroundColor: "#FFFFFF",
  hideLogoWhenMenuUncollapsed: true,
};

export default theme;
