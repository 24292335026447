import Box from "@mui/material/Box";
import { Button, LinearProgress, TextField, Typography } from "@mui/material";
import {
  useOrderEntryFormPage_InitialDataQuery,
  PremiumCalculationType,
  useOrderEntryFormPage_EntryQuery,
  useOrderEntryFormPage_UpsertEntryMutation,
} from "../../../../generated/graphql";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useTenant } from "../../../context/TenantContext";
import { BackToolbar } from "../../../components/BackToolbar";
import styled from "@emotion/styled";
import { useRef, useState, useEffect } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { TenantSelectorFrame } from "../../../components/TenantSelectorFrame";
import { addDays, format, subDays } from "date-fns";
import uniqBy from "lodash.uniqby";
import {
  OrderProductTable,
  ProductOrdersTableHandler,
  createProductOrderList,
  parseProductOrderTableProps,
} from "./order_product_table";

const TablesContainer = styled("div")<{ $display: boolean }>`
  padding: 0 32px 32px 32px;
  display: ${(props) => (props.$display ? "block" : "none")};
`;

const Spacer = styled("div")`
  flex: 1;
`;

const ButtonsContainer = styled("div")`
  display: flex;
  flex-direction: row;
  margin-top: 32px;
`;
const StyledDatePickerTextField = styled(TextField)`
  width: 100%;
  margin-top: 32px;
`;

const ExtractSuggestionText = styled(Typography)`
  margin: 32px 0;
  color: #d32f2f;
`;

const NoEntriesText = styled(Typography)`
  width: 100%;
  text-align: center;
`;

type EntriesMap = Record<string, { quantity: number; entryId: string }>;

export function OrderEntry() {
  const { id } = useParams();
  const productsTableRef = useRef<ProductOrdersTableHandler>(null);
  const [date, setDate] = useState<Date | null>(new Date());
  const [searchParams] = useSearchParams();
  const premiumCalculationType = searchParams.get(
    "premiumCalculationType"
  ) as PremiumCalculationType;
  const { dealershipId } = useTenant();
  const { data, loading } = useOrderEntryFormPage_EntryQuery({
    variables: { uuid: id! },
    skip: !id,
  });

  const [upsertEntry, { loading: upsertLoading }] =
    useOrderEntryFormPage_UpsertEntryMutation();
  const { data: initialData, loading: initialDataLoading } =
    useOrderEntryFormPage_InitialDataQuery({
      variables: {
        dealershipId: dealershipId!,
      },
      skip: !dealershipId,
    });
  const navigate = useNavigate();

  const productEntries = uniqBy(
    data?.entry.ProductOrderEntries.map((i) => i.Product),
    "uuid"
  );
  const products =
    productEntries.length > 0 ? productEntries : initialData?.products ?? [];

  useEffect(() => {
    if (data?.entry.date) {
      setDate(new Date(data?.entry.date));
    }
  }, [data]);

  const onSubmit = async () => {
    const productEntries = productsTableRef.current?.getValue() ?? {};
    const createdProductEntries =
      data?.entry.ProductOrderEntries.reduce<EntriesMap>(
        (acc, i) => ({
          ...acc,
          [i.Product.uuid]: {
            quantity: i.quantity,
            entryId: i.uuid,
          },
        }),
        {}
      );

    const productOrderList = createProductOrderList({
      products,
    });
    console.log({
      productEntries,
      createdProductEntries,
      productOrderList,
      ProductOrderEntries: Object.entries(productOrderList).map(
        ([key, value]) => {
          return {
            uuid: createdProductEntries?.[key]?.entryId,
            productId: value.productId,
            quantity:
              productEntries[key]?.quantity ??
              createdProductEntries?.[key]?.quantity ??
              value.quantity,
          };
        }
      ),
    });

    await toast.promise(
      upsertEntry({
        variables: {
          uuid: id,
          ProductOrderEntries: Object.entries(productOrderList).map(
            ([key, value]) => ({
              uuid: createdProductEntries?.[key]?.entryId,
              productId: value.productId,
              quantity:
                productEntries[key]?.quantity ??
                createdProductEntries?.[key]?.quantity ??
                value.quantity,
            })
          ),
          date: date || new Date(),
          dealershipId: dealershipId!,
        },
      }),
      {
        loading: "Cadastrando, por favor aguarde...",
        success: "Sucesso!",
        error: "Erro, tente novamente.",
      }
    );
    navigate(
      `/ravpro/entries?premiumCalculationType=${premiumCalculationType}`
    );
  };

  const { productColumns, productRows } = parseProductOrderTableProps({
    products,
    entry: data?.entry,
  });

  let extractionSuggestions;

  if (initialData?.lastEntryDate) {
    const lastEntryDateString = format(
      new Date(initialData?.lastEntryDate),
      "dd/MM/yyyy"
    );

    const extractSuggestionStartString = initialData?.lastEntryDate
      ? format(addDays(new Date(initialData.lastEntryDate), 1), "dd/MM/yyyy")
      : "";
    const extractSuggestionEndString = initialData?.lastEntryDate
      ? format(subDays(new Date(), 1), "dd/MM/yyyy")
      : "";

    extractionSuggestions = `Último lançamento feito para esta empresa foi no dia ${lastEntryDateString}, por favor extraia o relatório a partir de ${extractSuggestionStartString} até ${extractSuggestionEndString}. `;
  }

  const noEntries = productColumns.length === 1;
  console.log(productRows);
  return (
    <Box>
      <BackToolbar />
      {noEntries && !loading && !initialDataLoading ? (
        <NoEntriesText>
          Nenhum Produto, Serviço ou Kit registrado.
        </NoEntriesText>
      ) : null}
      {!loading && !initialDataLoading ? (
        <TablesContainer $display={!noEntries}>
          <TenantSelectorFrame>
            <DatePicker
              label="Data do lançamento (Data do ultimo dia do relatório gerado no sistema ERP. Ex. Relatório do dia 01/07/2020 até 31/07/2020, informar 31/07/2020)"
              value={date}
              onChange={(date) => setDate(date)}
              renderInput={(params) => (
                <StyledDatePickerTextField {...params} />
              )}
              views={["year", "month", "day"]}
            />
            <ExtractSuggestionText>
              {extractionSuggestions}
            </ExtractSuggestionText>
            {productColumns.length > 1 ? (
              <OrderProductTable
                ref={productsTableRef}
                rows={productRows}
                columns={productColumns}
              />
            ) : null}

            <ButtonsContainer>
              <Button
                onClick={() => {
                  navigate(-1);
                }}
                sx={{ width: 220 }}
                disabled={upsertLoading}
              >
                Cancelar
              </Button>
              <Spacer />
              <Button
                variant="contained"
                onClick={onSubmit}
                sx={{ width: 220 }}
                disabled={upsertLoading}
              >
                Cadastrar
              </Button>
            </ButtonsContainer>
          </TenantSelectorFrame>
        </TablesContainer>
      ) : (
        <LinearProgress />
      )}
    </Box>
  );
}
