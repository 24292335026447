import styled from "@emotion/styled";
import {
  Icon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import _ from "lodash";
import {
  useKitTablePage_KitsQuery,
  useKitTablePage_DeleteKitMutation,
} from "../../../../generated/graphql";
import { useTenant } from "../../../context/TenantContext";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../../../components/DeleteModal";
import { useState } from "react";
import { GroupDropdown } from "../../../components/GroupDropdown";
import { DealershipDropdown } from "../../../components/DealershipDropdown";

const Container = styled("div")`
  margin: 32px;
`;

const CToolbar = styled(Toolbar)`
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
`;

const Title = styled(Typography)`
  flex: 1;
`;

export const KitTablePage = () => {
  const { groupId, dealershipId } = useTenant();
  const navigate = useNavigate();
  const [deleteId, setDeleteId] = useState<string | undefined>();
  const [deleteKit, { loading: deleteLoading }] =
    useKitTablePage_DeleteKitMutation();
  const { data, refetch } = useKitTablePage_KitsQuery({
    variables: { groupId: groupId!, dealershipId: dealershipId ?? undefined },
    skip: !groupId,
  });

  return (
    <Container>
      <GroupDropdown />
      <DealershipDropdown />
      <Paper>
        <CToolbar>
          <Title>Kits</Title>
          <IconButton onClick={() => navigate("/ravpro/kits/create")}>
            <Icon>add</Icon>
          </IconButton>
        </CToolbar>
        <Table>
          {data?.kits && data?.kits.length > 0 ? (
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Código</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {_.orderBy(data?.kits, "KitCatalog.name").map((row) => (
              <TableRow key={row.uuid} hover>
                <TableCell>{row.KitCatalog?.name}</TableCell>
                <TableCell>{row.systemCode}</TableCell>
                <TableCell align="right">
                  <IconButton
                    onClick={() => navigate(`/ravpro/kits/update/${row.uuid}`)}
                  >
                    <Icon>edit</Icon>
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setDeleteId(row.uuid);
                    }}
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {data?.kits && data.kits.length === 0 ? (
              <TableRow>
                <TableCell align="center">Nenhum registro encontrado</TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </Paper>
      <DeleteModal
        open={!!deleteId}
        onClose={() => setDeleteId(undefined)}
        loading={deleteLoading}
        onConfirm={async () => {
          await deleteKit({ variables: { uuid: deleteId! } });
          setDeleteId(undefined);
          refetch();
        }}
      />
    </Container>
  );
};
