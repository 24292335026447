import Box from "@mui/material/Box";
import {
  Autocomplete,
  Button,
  Icon,
  InputAdornment,
  LinearProgress,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import {
  KitFormPage_InitialDataQuery,
  KitFormPage_KitQuery,
  useKitFormPage_InitialDataQuery,
  useKitFormPage_KitQuery,
  useKitFormPage_UpsertKitMutation,
} from "../../../../generated/graphql";
import { useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { toast } from "react-hot-toast";
import { useTenant } from "../../../context/TenantContext";
import { BackToolbar } from "../../../components/BackToolbar";
import {
  formatCurrency,
  MoneyField,
  parseFieldToFloat,
  parseFieldToDecimal,
  parseIntToField,
} from "../../../../components/MoneyField";
import orderBy from "lodash.orderby";
import { GroupDropdown } from "../../../components/GroupDropdown";

type KitFormData = {
  uuid?: string;
  systemCode?: string;
  registrationPremium?: string;
  KitCatalog?: KitFormPage_KitQuery["kit"]["KitCatalog"] | null;
  premium1?: string;
  premium2?: string;
  premium3?: string;
  premium4?: string;
  premium5?: string;
  premium6?: string;
  kitDealerships: KitFormPage_KitQuery["kit"]["Dealerships"];
};

export function KitFormPage() {
  const { id } = useParams();
  const { groupId } = useTenant();
  const { data, loading } = useKitFormPage_KitQuery({
    variables: { uuid: id! },
    skip: !id,
  });
  const [upsertKit, { loading: upsertLoading }] =
    useKitFormPage_UpsertKitMutation();
  const { data: initialData, loading: initialDataLoading } =
    useKitFormPage_InitialDataQuery({
      variables: {
        groupId: groupId!,
      },
      skip: !groupId,
    });
  const navigate = useNavigate();

  const onSubmit = async (data: KitFormData) => {
    if (data.kitDealerships.length === 0) {
      toast.error("Pelo menos uma concessionária precisa ser selecionada.");
      return;
    }
    if (!data.KitCatalog) {
      toast.error("Kit obrigatòrio.");
      return;
    }
    const parsedPremiums = {
      registrationPremium: parseFieldToDecimal(data.registrationPremium),
      premium1: parseFieldToDecimal(data.premium1),
      premium2: parseFieldToDecimal(data.premium2),
      premium3: parseFieldToDecimal(data.premium3),
      premium4: parseFieldToDecimal(data.premium4),
      premium5: parseFieldToDecimal(data.premium5),
      premium6: parseFieldToDecimal(data.premium6),
    };
    if (
      parsedPremiums.premium1 +
        parsedPremiums.premium2 +
        parsedPremiums.premium3 +
        parsedPremiums.premium4 +
        parsedPremiums.premium5 +
        parsedPremiums.premium6 >
      parsedPremiums.registrationPremium
    ) {
      toast.error("Soma dos prêmios não pode ultrapassar prêmio de cadastro.");
    } else {
      await toast.promise(
        upsertKit({
          variables: {
            uuid: id,
            kitCatalogId: data.KitCatalog.uuid,
            systemCode: data.systemCode,
            dealershipIds: data.kitDealerships.map((i) => i.uuid) ?? [],
            ...parsedPremiums,
          },
        }),
        {
          loading: "Cadastrando",
          success: "Sucesso!",
          error: "Erro, tente novamente.",
        }
      );
      navigate("/ravpro/kits");
    }
  };
  return (
    <Box>
      <BackToolbar />
      {!loading && !initialDataLoading ? (
        <KitForm
          {...data?.kit}
          registrationPremium={parseIntToField(data?.kit.registrationPremium)}
          premium1={parseIntToField(data?.kit.premium1)}
          premium2={parseIntToField(data?.kit.premium2)}
          premium3={parseIntToField(data?.kit.premium3)}
          premium4={parseIntToField(data?.kit.premium4)}
          premium5={parseIntToField(data?.kit.premium5)}
          premium6={parseIntToField(data?.kit.premium6)}
          KitCatalog={data?.kit.KitCatalog}
          premiumDescription={initialData?.group.PremiumDescription}
          kitCatalogs={initialData?.kitCatalogs ?? []}
          onSubmit={onSubmit}
          kitDealerships={data?.kit.Dealerships ?? []}
          dealerships={initialData?.group.Dealerships ?? []}
          loading={upsertLoading}
        />
      ) : (
        <LinearProgress />
      )}
    </Box>
  );
}

const Form = styled("form")`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 400px;
  margin: 16px auto;
`;

const Line = styled("div")`
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
`;

type PremiumTextProps = {
  $invalid: boolean;
};

const PremiumText = styled("span")<PremiumTextProps>`
  color: ${(props) => (props.$invalid ? "red" : "black")};
  text-align: right;
`;

type KitFormProps = KitFormData & {
  kitCatalogs: KitFormPage_InitialDataQuery["kitCatalogs"];
  premiumDescription: KitFormPage_InitialDataQuery["group"]["PremiumDescription"];
  dealerships: KitFormPage_InitialDataQuery["group"]["Dealerships"];
  onSubmit: (data: KitFormData) => void;
  loading: boolean;
};

const KitForm: React.FC<KitFormProps> = ({
  KitCatalog,
  kitCatalogs,
  systemCode,
  registrationPremium,
  premium1,
  premium2,
  premium3,
  premium4,
  premium5,
  premium6,
  premiumDescription,
  dealerships,
  kitDealerships,
  onSubmit,
  loading,
}) => {
  const defaultValues = {
    KitCatalog,
    systemCode,
    registrationPremium,
    premium1,
    premium2,
    premium3,
    premium4,
    premium5,
    premium6,
    kitDealerships,
  };
  const { handleSubmit, control, setValue, watch } = useForm({
    defaultValues,
  });
  const premium1Float = parseFieldToFloat(watch("premium1")) ?? 0;
  const premium2Float = parseFieldToFloat(watch("premium2")) ?? 0;
  const premium3Float = parseFieldToFloat(watch("premium3")) ?? 0;
  const premium4Float = parseFieldToFloat(watch("premium4")) ?? 0;
  const premium5Float = parseFieldToFloat(watch("premium5")) ?? 0;
  const premium6Float = parseFieldToFloat(watch("premium6")) ?? 0;
  const premiumTotal =
    premium1Float +
    premium2Float +
    premium3Float +
    premium4Float +
    premium5Float +
    premium6Float;

  const registrationPremiumWatched = watch("registrationPremium");
  const registrationPremiumFloat =
    parseFieldToFloat(registrationPremiumWatched) ?? 0;

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <GroupDropdown disabled />
      <Line />
      <Autocomplete
        multiple
        options={orderBy(dealerships, "name")}
        renderInput={(params) => (
          <TextField {...params} label="Concessionárias" />
        )}
        onChange={(_e, value) => setValue("kitDealerships", value)}
        isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
        getOptionLabel={(i) => i.fantasyName ?? ""}
        defaultValue={defaultValues.kitDealerships}
        sx={{ width: "100%" }}
      />
      <Autocomplete
        options={orderBy(kitCatalogs, "KitCatalog.hierarchy")}
        renderInput={(params) => <TextField {...params} label="Kit" />}
        onChange={(_e, value) => setValue("KitCatalog", value)}
        isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
        getOptionLabel={(i) => i.name}
        defaultValue={defaultValues.KitCatalog}
      />
      <Controller
        name="systemCode"
        control={control}
        defaultValue={defaultValues.systemCode ?? ""}
        render={({ field }) => (
          <TextField inputProps={field} label="Codigo Sistema" />
        )}
      />

      <Line />
      <Typography>Prêmios: </Typography>

      <Controller
        name="registrationPremium"
        control={control}
        defaultValue={defaultValues.registrationPremium ?? ""}
        render={({ field: { onChange, name, value } }) => (
          <MoneyField
            label="Prêmio cadastro"
            name={name}
            onChange={onChange}
            value={value}
            helperText={
              registrationPremiumFloat
                ? `Valor sugerido para premiações: ${Intl.NumberFormat(
                    "pt-BR",
                    {
                      style: "currency",
                      currency: "BRL",
                      maximumFractionDigits: 2,
                    }
                  ).format(registrationPremiumFloat * 0.85)}`
                : ""
            }
          />
        )}
      />
      <Controller
        name="premium1"
        control={control}
        defaultValue={defaultValues.premium1 ?? ""}
        render={({ field: { onChange, name, value } }) => (
          <MoneyField
            label="Prêmio 1"
            name={name}
            onChange={onChange}
            value={value}
            InputProps={{
              endAdornment: <Info title={premiumDescription?.premium1} />,
            }}
          />
        )}
      />
      <Controller
        name="premium2"
        control={control}
        defaultValue={defaultValues.premium2 ?? ""}
        render={({ field: { onChange, name, value } }) => (
          <MoneyField
            label="Prêmio 2"
            name={name}
            onChange={onChange}
            value={value}
            InputProps={{
              endAdornment: <Info title={premiumDescription?.premium2} />,
            }}
          />
        )}
      />
      <Controller
        name="premium3"
        control={control}
        defaultValue={defaultValues.premium3 ?? ""}
        render={({ field: { onChange, name, value } }) => (
          <MoneyField
            label="Prêmio 3"
            name={name}
            onChange={onChange}
            value={value}
            InputProps={{
              endAdornment: <Info title={premiumDescription?.premium3} />,
            }}
          />
        )}
      />
      <Controller
        name="premium4"
        control={control}
        defaultValue={defaultValues.premium4 ?? ""}
        render={({ field: { onChange, name, value } }) => (
          <MoneyField
            label="Prêmio 4"
            name={name}
            onChange={onChange}
            value={value}
            InputProps={{
              endAdornment: <Info title={premiumDescription?.premium4} />,
            }}
          />
        )}
      />
      <Controller
        name="premium5"
        control={control}
        defaultValue={defaultValues.premium5 ?? ""}
        render={({ field: { onChange, name, value } }) => (
          <MoneyField
            label="Prêmio 5"
            name={name}
            onChange={onChange}
            value={value}
            InputProps={{
              endAdornment: <Info title={premiumDescription?.premium5} />,
            }}
          />
        )}
      />
      <Controller
        name="premium6"
        control={control}
        defaultValue={defaultValues.premium6 ?? ""}
        render={({ field: { onChange, name, value } }) => (
          <MoneyField
            label="Prêmio 6"
            name={name}
            onChange={onChange}
            value={value}
            InputProps={{
              endAdornment: <Info title={premiumDescription?.premium6} />,
            }}
          />
        )}
      />
      <PremiumText $invalid={premiumTotal > registrationPremiumFloat * 0.85}>
        Total Prêmios: {formatCurrency(premiumTotal)}
      </PremiumText>
      <Button type="submit" variant="contained" disabled={loading}>
        Cadastrar
      </Button>
    </Form>
  );
};

type InfoProps = {
  title?: string;
};

const Info: React.FC<InfoProps> = ({ title }) =>
  title && title.length > 0 ? (
    <InputAdornment position="end">
      <Tooltip title={title}>
        <Icon style={{ color: "rgba(0,0,0,0.4)" }}>info</Icon>
      </Tooltip>
    </InputAdornment>
  ) : (
    <></>
  );
