import { InputProps, TextField } from "@mui/material";
import { NumericFormat } from "react-number-format";

export type NumberFieldProps = {
  label?: string;
  name?: string;
  onChange: (...event: any[]) => void;
  value: string | undefined;
  InputProps?: InputProps;
  helperText?: string;
};

export const NumberField: React.FC<NumberFieldProps> = ({
  label,
  name,
  onChange,
  value,
  InputProps,
  helperText,
}) => {
  return (
    <NumericFormat
      InputProps={InputProps}
      label={label}
      name={name}
      onChange={onChange}
      value={value}
      customInput={TextField}
      decimalScale={0}
      allowNegative={false}
      helperText={helperText}
    />
  );
};
