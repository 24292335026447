import { IconType } from "../assets/icons";
import { Icon } from "./Icon";
import { styled } from "@mui/material/styles";

export type IconButtonProps = {
  className?: string;
  type: IconType;
  onClick: () => void;
};

const IconButtonContainer = styled("div")`
  :hover {
    background: rgba(0, 0, 0, 0.1);
  }
  :active {
    background-color: #484848;
    background-size: 100%;
    transition: background 0s;
  }
  background-position: center;
  transition: background 0.8s;
  padding: 14px;
  display: inline-block;
`;

export const IconButton: React.FC<IconButtonProps> = ({
  className,
  type,
  onClick,
}) => {
  return (
    <IconButtonContainer className={className} onClick={onClick}>
      <Icon type={type} />
    </IconButtonContainer>
  );
};
