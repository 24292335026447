import Box from "@mui/material/Box";
import { useState } from "react";
import { Button, Icon, TextField, Typography } from "@mui/material";
import toast from "react-hot-toast";
import { usePasswordChangePage_ChangePasswordMutation } from "../../generated/graphql";
import { styled } from "@mui/material/styles";

const is6CharsLong = (str: string) => str.length >= 6;
const containsUpperCase = (str: string) => /[A-Z]/.test(str);
const containsLowerCase = (str: string) => /[a-z]/.test(str);
const containsNumber = (str: string) => /[0-9]/.test(str);
const containsSpecialChar = (str: string) => /[!@#$%^&*(),.?":{}|<>]/.test(str);

export function PasswordChangePage() {
  const [changePasswordMutation] =
    usePasswordChangePage_ChangePasswordMutation();

  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordRepeat, setNewPasswordRepeat] = useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    await toast.promise(
      changePasswordMutation({
        variables: {
          newPassword: newPasswordRepeat,
        },
      }),
      {
        loading: "Cadastrando",
        success: "Sucesso!",
        error: "Erro, tente novamente.",
      }
    );
    setLoading(false);
    setNewPassword("");
    setNewPasswordRepeat("");
  };
  const validations = [
    is6CharsLong(newPassword),
    containsUpperCase(newPassword),
    containsLowerCase(newPassword),
    containsNumber(newPassword),
    containsSpecialChar(newPassword),
    newPassword === newPasswordRepeat && newPassword.length > 0,
  ];

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ padding: "16px 0" }}>
        <Box
          width={400}
          display="flex"
          alignItems="center"
          flexDirection="column"
          margin="0 auto"
        >
          <TextField
            style={{ width: 400, marginBottom: 32 }}
            type="password"
            label="Senha"
            value={newPassword}
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
          />
          <TextField
            style={{ width: 400, marginBottom: 32 }}
            type="password"
            label="Repetir senha"
            value={newPasswordRepeat}
            onChange={(e) => {
              setNewPasswordRepeat(e.target.value);
            }}
          />
          <Requirement valid={validations[0]}>
            Pelo menos 6 caracteres
          </Requirement>
          <Requirement valid={validations[1]}>
            Pelo menos uma letra maiúscula
          </Requirement>
          <Requirement valid={validations[2]}>
            Pelo menos uma letra minúscula
          </Requirement>
          <Requirement valid={validations[3]}>Pelo menos um número</Requirement>
          <Requirement valid={validations[4]}>
            Pelo menos um caractere especial
          </Requirement>
          <Requirement valid={validations[5]}>
            Os dois campos devem ser iguais
          </Requirement>
          <Button
            style={{ width: 400, marginTop: 18 }}
            type="submit"
            variant="contained"
            disabled={
              loading || !validations.reduce((acc, i) => acc && i, true)
            }
          >
            Cadastrar
          </Button>
        </Box>
      </Box>
    </form>
  );
}

type RequirementProps = {
  valid: number;
};

const RequirementContainer = styled("div")`
  display: flex;
  flex-direction: row;
  width: 400px;
  margin-bottom: 12px;
`;

const RequirementIcon = styled(Icon)<RequirementProps>`
  color: ${(props) => (props.valid ? "green" : "red")};
  margin-right: 16px;
`;

const RequirementText = styled(Typography)<RequirementProps>`
  color: ${(props) => (props.valid ? "green" : "red")};
`;

const Requirement = ({
  children,
  valid,
}: {
  children: string;
  valid: boolean;
}) => {
  return (
    <RequirementContainer>
      <RequirementIcon valid={valid ? 1 : 0}>
        {valid ? "check_circle" : "cancel"}
      </RequirementIcon>
      <RequirementText valid={valid ? 1 : 0}>{children}</RequirementText>
    </RequirementContainer>
  );
};
