const theme = {
  appBarColor: "#000000",
  primaryColor: "#000000",
  tabTitle: "Toyota do Brasil Ltda | SCALAR/",
  faviconUrl: "https://www.usoft.com.br/scalar/favicon.ico",
  loginRightBackgroundColor: "#000000",
  hideLogoWhenMenuUncollapsed: true,
  loginLogoHeight: 180,
};

export default theme;
