import Box from "@mui/material/Box";
import { Autocomplete, Button, LinearProgress, TextField } from "@mui/material";
import {
  useUserPermissionsFormPage_SetUserDealershipPermissionsMutation,
  useUserPermissionsFormPage_UserPermissionsQuery,
} from "../../../../generated/graphql";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useTenant } from "../../../context/TenantContext";
import { BackToolbar } from "../../../components/BackToolbar";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { uniq } from "lodash";

const Container = styled("div")`
  padding: 32px;
`;

export function UserPermissionsFormPage() {
  const { email } = useParams();
  const { data, loading } = useUserPermissionsFormPage_UserPermissionsQuery({
    variables: { email: email! },
    skip: !email,
  });
  const [setUserDealershipPermissions, { loading: upsertLoading }] =
    useUserPermissionsFormPage_SetUserDealershipPermissionsMutation();
  const navigate = useNavigate();
  const [dealershipIds, setDealershipIds] = useState<string[]>([]);
  const [groupId, setGroupId] = useState<string | undefined>();
  const { groups, refetch } = useTenant();

  useEffect(() => {
    if (data?.userDealershipPermissions) {
      refetch();
      setDealershipIds(
        data.userDealershipPermissions.map((i) => i.Dealership.uuid)
      );
    }
  }, [data]);

  const onSubmit = async () => {
    await toast.promise(
      (async () =>
        email &&
        setUserDealershipPermissions({
          variables: {
            dealershipIds,
            email,
          },
        }))(),
      {
        loading: "Cadastrando",
        success: "Sucesso!",
        error: "Erro, tente novamente.",
      }
    );
    navigate("/ravpro/user-permissions");
  };

  const dealerships = groups
    .map((i) => i.Dealerships.map((j) => ({ ...j, groupUuid: i.uuid })))
    .flat();
  const dealershipMap = dealerships.reduce(
    (acc, i) => ({ ...acc, [i.uuid]: i }),
    {} as any
  );
  return (
    <Box>
      <BackToolbar />
      {!loading ? (
        <Container>
          <Autocomplete
            options={groups}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label="Grupo" />}
            onChange={(_e, value) => setGroupId(value?.uuid)}
            value={groups.filter((i) => i.uuid === groupId)?.[0] || null}
            sx={{ marginBottom: 1, width: "100%" }}
          />
          <Autocomplete
            multiple
            options={dealerships.filter((i) => i.groupUuid === groupId) ?? []}
            renderInput={(params) => (
              <TextField {...params} label="Concessionárias" />
            )}
            onChange={(_e, value) => {
              setDealershipIds(
                uniq([...dealershipIds, ...value.map((i) => i.uuid)])
              );
            }}
            isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
            getOptionLabel={(i) => i.fantasyName ?? ""}
            value={dealershipIds
              .map((i) => dealershipMap[i])
              .filter((i) => groupId === i.groupUuid)}
            sx={{ marginBottom: 2, width: "100%" }}
          />
          <Button
            onClick={onSubmit}
            variant="contained"
            fullWidth
            disabled={upsertLoading}
          >
            Cadastrar
          </Button>
        </Container>
      ) : (
        <LinearProgress />
      )}
    </Box>
  );
}
