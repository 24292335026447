export const icons: IconType[] = [
  "arrow-up",
  "arrow-right",
  "arrow-left",
  "arrow-down",
  "arrows-vertical",
  "arrows-horizontal",
  "angle-up",
  "angle-right",
  "angle-left",
  "angle-down",
  "angle-double-up",
  "angle-double-right",
  "angle-double-left",
  "angle-double-down",
  "move",
  "fullscreen",
  "arrow-top-right",
  "arrow-top-left",
  "arrow-circle-up",
  "arrow-circle-right",
  "arrow-circle-left",
  "arrow-circle-down",
  "arrows-corner",
  "split-v",
  "split-v-alt",
  "split-h",
  "hand-point-up",
  "hand-point-right",
  "hand-point-left",
  "hand-point-down",
  "back-right",
  "back-left",
  "exchange-vertical",
  "wand",
  "save",
  "save-alt",
  "direction",
  "direction-alt",
  "user",
  "link",
  "unlink",
  "trash",
  "target",
  "tag",
  "desktop",
  "tablet",
  "mobile",
  "email",
  "star",
  "spray",
  "signal",
  "shopping-cart",
  "shopping-cart-full",
  "settings",
  "search",
  "zoom-in",
  "zoom-out",
  "cut",
  "ruler",
  "ruler-alt-2",
  "ruler-pencil",
  "ruler-alt",
  "bookmark",
  "bookmark-alt",
  "reload",
  "plus",
  "minus",
  "close",
  "pin",
  "pencil",
  "pencil-alt",
  "paint-roller",
  "paint-bucket",
  "na",
  "medall",
  "medall-alt",
  "marker",
  "marker-alt",
  "lock",
  "unlock",
  "location-arrow",
  "layout",
  "layers",
  "layers-alt",
  "key",
  "image",
  "heart",
  "heart-broken",
  "hand-stop",
  "hand-open",
  "hand-drag",
  "flag",
  "flag-alt",
  "flag-alt-2",
  "eye",
  "import",
  "export",
  "cup",
  "crown",
  "comments",
  "comment",
  "comment-alt",
  "thought",
  "clip",
  "check",
  "check-box",
  "camera",
  "announcement",
  "brush",
  "brush-alt",
  "palette",
  "briefcase",
  "bolt",
  "bolt-alt",
  "blackboard",
  "bag",
  "world",
  "wheelchair",
  "car",
  "truck",
  "timer",
  "ticket",
  "thumb-up",
  "thumb-down",
  "stats-up",
  "stats-down",
  "shine",
  "shift-right",
  "shift-left",
  "shift-right-alt",
  "shift-left-alt",
  "shield",
  "notepad",
  "server",
  "pulse",
  "printer",
  "power-off",
  "plug",
  "pie-chart",
  "panel",
  "package",
  "music",
  "music-alt",
  "mouse",
  "mouse-alt",
  "money",
  "microphone",
  "menu",
  "menu-alt",
  "map",
  "map-alt",
  "location-pin",
  "light-bulb",
  "info",
  "infinite",
  "id-badge",
  "hummer",
  "home",
  "help",
  "headphone",
  "harddrives",
  "harddrive",
  "gift",
  "game",
  "filter",
  "files",
  "file",
  "zip",
  "folder",
  "envelope",
  "dashboard",
  "cloud",
  "cloud-up",
  "cloud-down",
  "clipboard",
  "calendar",
  "book",
  "bell",
  "basketball",
  "bar-chart",
  "bar-chart-alt",
  "archive",
  "anchor",
  "alert",
  "alarm-clock",
  "agenda",
  "write",
  "wallet",
  "video-clapper",
  "video-camera",
  "vector",
  "support",
  "stamp",
  "slice",
  "shortcode",
  "receipt",
  "pin2",
  "pin-alt",
  "pencil-alt2",
  "eraser",
  "more",
  "more-alt",
  "microphone-alt",
  "magnet",
  "line-double",
  "line-dotted",
  "line-dashed",
  "ink-pen",
  "info-alt",
  "help-alt",
  "headphone-alt",
  "gallery",
  "face-smile",
  "face-sad",
  "credit-card",
  "comments-smiley",
  "time",
  "share",
  "share-alt",
  "rocket",
  "new-window",
  "rss",
  "rss-alt",
  "control-stop",
  "control-shuffle",
  "control-play",
  "control-pause",
  "control-forward",
  "control-backward",
  "volume",
  "control-skip-forward",
  "control-skip-backward",
  "control-record",
  "control-eject",
  "paragraph",
  "uppercase",
  "underline",
  "text",
  "Italic",
  "smallcap",
  "list",
  "list-ol",
  "align-right",
  "align-left",
  "align-justify",
  "align-center",
  "quote-right",
  "quote-left",
  "layout-width-full",
  "layout-width-default",
  "layout-width-default-alt",
  "layout-tab",
  "layout-tab-window",
  "layout-tab-v",
  "layout-tab-min",
  "layout-slider",
  "layout-slider-alt",
  "layout-sidebar-right",
  "layout-sidebar-none",
  "layout-sidebar-left",
  "layout-placeholder",
  "layout-menu",
  "layout-menu-v",
  "layout-menu-separated",
  "layout-menu-full",
  "layout-media-right",
  "layout-media-right-alt",
  "layout-media-overlay",
  "layout-media-overlay-alt",
  "layout-media-overlay-alt-2",
  "layout-media-left",
  "layout-media-left-alt",
  "layout-media-center",
  "layout-media-center-alt",
  "layout-list-thumb",
  "layout-list-thumb-alt",
  "layout-list-post",
  "layout-list-large-image",
  "layout-line-solid",
  "layout-grid4",
  "layout-grid3",
  "layout-grid2",
  "layout-grid2-thumb",
  "layout-cta-right",
  "layout-cta-left",
  "layout-cta-center",
  "layout-cta-btn-right",
  "layout-cta-btn-left",
  "layout-column4",
  "layout-column3",
  "layout-column2",
  "layout-accordion-separated",
  "layout-accordion-merged",
  "layout-accordion-list",
  "widgetized",
  "widget",
  "widget-alt",
  "view-list",
  "view-list-alt",
  "view-grid",
  "upload",
  "download",
  "loop",
  "layout-sidebar-2",
  "layout-grid4-alt",
  "layout-grid3-alt",
  "layout-grid2-alt",
  "layout-column4-alt",
  "layout-column3-alt",
  "layout-column2-alt",
  "flickr",
  "flickr-alt",
  "instagram",
  "google",
  "github",
  "facebook",
  "dropbox",
  "dropbox-alt",
  "dribbble",
  "apple",
  "android",
  "yahoo",
  "trello",
  "stack-overflow",
  "soundcloud",
  "sharethis",
  "sharethis-alt",
  "reddit",
  "microsoft",
  "microsoft-alt",
  "linux",
  "jsfiddle",
  "joomla",
  "html5",
  "css3",
  "drupal",
  "wordpress",
  "tumblr",
  "tumblr-alt",
  "skype",
  "youtube",
  "vimeo",
  "vimeo-alt",
  "twitter",
  "twitter-alt",
  "linkedin",
  "pinterest",
  "pinterest-alt",
  "themify-logo",
  "themify-favicon",
  "themify-favicon-alt",
];
export type IconType =
  | "arrow-up"
  | "arrow-right"
  | "arrow-left"
  | "arrow-down"
  | "arrows-vertical"
  | "arrows-horizontal"
  | "angle-up"
  | "angle-right"
  | "angle-left"
  | "angle-down"
  | "angle-double-up"
  | "angle-double-right"
  | "angle-double-left"
  | "angle-double-down"
  | "move"
  | "fullscreen"
  | "arrow-top-right"
  | "arrow-top-left"
  | "arrow-circle-up"
  | "arrow-circle-right"
  | "arrow-circle-left"
  | "arrow-circle-down"
  | "arrows-corner"
  | "split-v"
  | "split-v-alt"
  | "split-h"
  | "hand-point-up"
  | "hand-point-right"
  | "hand-point-left"
  | "hand-point-down"
  | "back-right"
  | "back-left"
  | "exchange-vertical"
  | "wand"
  | "save"
  | "save-alt"
  | "direction"
  | "direction-alt"
  | "user"
  | "link"
  | "unlink"
  | "trash"
  | "target"
  | "tag"
  | "desktop"
  | "tablet"
  | "mobile"
  | "email"
  | "star"
  | "spray"
  | "signal"
  | "shopping-cart"
  | "shopping-cart-full"
  | "settings"
  | "search"
  | "zoom-in"
  | "zoom-out"
  | "cut"
  | "ruler"
  | "ruler-alt-2"
  | "ruler-pencil"
  | "ruler-alt"
  | "bookmark"
  | "bookmark-alt"
  | "reload"
  | "plus"
  | "minus"
  | "close"
  | "pin"
  | "pencil"
  | "pencil-alt"
  | "paint-roller"
  | "paint-bucket"
  | "na"
  | "medall"
  | "medall-alt"
  | "marker"
  | "marker-alt"
  | "lock"
  | "unlock"
  | "location-arrow"
  | "layout"
  | "layers"
  | "layers-alt"
  | "key"
  | "image"
  | "heart"
  | "heart-broken"
  | "hand-stop"
  | "hand-open"
  | "hand-drag"
  | "flag"
  | "flag-alt"
  | "flag-alt-2"
  | "eye"
  | "import"
  | "export"
  | "cup"
  | "crown"
  | "comments"
  | "comment"
  | "comment-alt"
  | "thought"
  | "clip"
  | "check"
  | "check-box"
  | "camera"
  | "announcement"
  | "brush"
  | "brush-alt"
  | "palette"
  | "briefcase"
  | "bolt"
  | "bolt-alt"
  | "blackboard"
  | "bag"
  | "world"
  | "wheelchair"
  | "car"
  | "truck"
  | "timer"
  | "ticket"
  | "thumb-up"
  | "thumb-down"
  | "stats-up"
  | "stats-down"
  | "shine"
  | "shift-right"
  | "shift-left"
  | "shift-right-alt"
  | "shift-left-alt"
  | "shield"
  | "notepad"
  | "server"
  | "pulse"
  | "printer"
  | "power-off"
  | "plug"
  | "pie-chart"
  | "panel"
  | "package"
  | "music"
  | "music-alt"
  | "mouse"
  | "mouse-alt"
  | "money"
  | "microphone"
  | "menu"
  | "menu-alt"
  | "map"
  | "map-alt"
  | "location-pin"
  | "light-bulb"
  | "info"
  | "infinite"
  | "id-badge"
  | "hummer"
  | "home"
  | "help"
  | "headphone"
  | "harddrives"
  | "harddrive"
  | "gift"
  | "game"
  | "filter"
  | "files"
  | "file"
  | "zip"
  | "folder"
  | "envelope"
  | "dashboard"
  | "cloud"
  | "cloud-up"
  | "cloud-down"
  | "clipboard"
  | "calendar"
  | "book"
  | "bell"
  | "basketball"
  | "bar-chart"
  | "bar-chart-alt"
  | "archive"
  | "anchor"
  | "alert"
  | "alarm-clock"
  | "agenda"
  | "write"
  | "wallet"
  | "video-clapper"
  | "video-camera"
  | "vector"
  | "support"
  | "stamp"
  | "slice"
  | "shortcode"
  | "receipt"
  | "pin2"
  | "pin-alt"
  | "pencil-alt2"
  | "eraser"
  | "more"
  | "more-alt"
  | "microphone-alt"
  | "magnet"
  | "line-double"
  | "line-dotted"
  | "line-dashed"
  | "ink-pen"
  | "info-alt"
  | "help-alt"
  | "headphone-alt"
  | "gallery"
  | "face-smile"
  | "face-sad"
  | "credit-card"
  | "comments-smiley"
  | "time"
  | "share"
  | "share-alt"
  | "rocket"
  | "new-window"
  | "rss"
  | "rss-alt"
  | "control-stop"
  | "control-shuffle"
  | "control-play"
  | "control-pause"
  | "control-forward"
  | "control-backward"
  | "volume"
  | "control-skip-forward"
  | "control-skip-backward"
  | "control-record"
  | "control-eject"
  | "paragraph"
  | "uppercase"
  | "underline"
  | "text"
  | "Italic"
  | "smallcap"
  | "list"
  | "list-ol"
  | "align-right"
  | "align-left"
  | "align-justify"
  | "align-center"
  | "quote-right"
  | "quote-left"
  | "layout-width-full"
  | "layout-width-default"
  | "layout-width-default-alt"
  | "layout-tab"
  | "layout-tab-window"
  | "layout-tab-v"
  | "layout-tab-min"
  | "layout-slider"
  | "layout-slider-alt"
  | "layout-sidebar-right"
  | "layout-sidebar-none"
  | "layout-sidebar-left"
  | "layout-placeholder"
  | "layout-menu"
  | "layout-menu-v"
  | "layout-menu-separated"
  | "layout-menu-full"
  | "layout-media-right"
  | "layout-media-right-alt"
  | "layout-media-overlay"
  | "layout-media-overlay-alt"
  | "layout-media-overlay-alt-2"
  | "layout-media-left"
  | "layout-media-left-alt"
  | "layout-media-center"
  | "layout-media-center-alt"
  | "layout-list-thumb"
  | "layout-list-thumb-alt"
  | "layout-list-post"
  | "layout-list-large-image"
  | "layout-line-solid"
  | "layout-grid4"
  | "layout-grid3"
  | "layout-grid2"
  | "layout-grid2-thumb"
  | "layout-cta-right"
  | "layout-cta-left"
  | "layout-cta-center"
  | "layout-cta-btn-right"
  | "layout-cta-btn-left"
  | "layout-column4"
  | "layout-column3"
  | "layout-column2"
  | "layout-accordion-separated"
  | "layout-accordion-merged"
  | "layout-accordion-list"
  | "widgetized"
  | "widget"
  | "widget-alt"
  | "view-list"
  | "view-list-alt"
  | "view-grid"
  | "upload"
  | "download"
  | "loop"
  | "layout-sidebar-2"
  | "layout-grid4-alt"
  | "layout-grid3-alt"
  | "layout-grid2-alt"
  | "layout-column4-alt"
  | "layout-column3-alt"
  | "layout-column2-alt"
  | "flickr"
  | "flickr-alt"
  | "instagram"
  | "google"
  | "github"
  | "facebook"
  | "dropbox"
  | "dropbox-alt"
  | "dribbble"
  | "apple"
  | "android"
  | "yahoo"
  | "trello"
  | "stack-overflow"
  | "soundcloud"
  | "sharethis"
  | "sharethis-alt"
  | "reddit"
  | "microsoft"
  | "microsoft-alt"
  | "linux"
  | "jsfiddle"
  | "joomla"
  | "html5"
  | "css3"
  | "drupal"
  | "wordpress"
  | "tumblr"
  | "tumblr-alt"
  | "skype"
  | "youtube"
  | "vimeo"
  | "vimeo-alt"
  | "twitter"
  | "twitter-alt"
  | "linkedin"
  | "pinterest"
  | "pinterest-alt"
  | "themify-logo"
  | "themify-favicon"
  | "themify-favicon-alt";
