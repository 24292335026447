import styled from "@emotion/styled";
import {
  Icon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { useAxios } from "../../../../api";

const Container = styled(Paper)`
  margin: 32px;
`;

const CToolbar = styled(Toolbar)`
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
`;

const Title = styled(Typography)`
  flex: 1;
`;

type UserList = {
  ContactNumber: string;
  DirectoryType: string;
  DisplayName: string;
  Email: string;
  ExternalProviderId: string;
  FirstName: string;
  IsActive: boolean;
  Lastname: string;
  UserId: number;
  UserStatus: number;
  Username: string;
}[];

type UserListRequest = {
  UserList: UserList;
};

export const UserPermissionsTablePage = () => {
  const navigate = useNavigate();
  const [{ data }] = useAxios<UserListRequest>({
    url: "/v4.0/users",
    params: {
      page_size: 100,
    },
  });
  return (
    <Container>
      <CToolbar>
        <Title>Permissões</Title>
      </CToolbar>
      <Table>
        <TableBody>
          {_.orderBy(data?.UserList, "name")
            .filter((i) => i.Email.includes("ravpro"))
            .map((row) => (
              <TableRow key={row.UserId} hover>
                <TableCell>{row.DisplayName}</TableCell>
                <TableCell>{row.Email}</TableCell>
                <TableCell align="right">
                  <IconButton
                    onClick={() =>
                      navigate(`/ravpro/user-permissions/update/${row.Email}`)
                    }
                  >
                    <Icon>edit</Icon>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Container>
  );
};
