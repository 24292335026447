import Box from "@mui/material/Box";
import { Button, LinearProgress, TextField, Typography } from "@mui/material";
import {
  useEntryFormPage_InitialDataQuery,
  useEntryFormPage_EntryQuery,
  useEntryFormPage_UpsertEntryMutation,
  PremiumCalculationType,
} from "../../../../generated/graphql";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useTenant } from "../../../context/TenantContext";
import { BackToolbar } from "../../../components/BackToolbar";
import styled from "@emotion/styled";
import { useRef, useState, useEffect } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { TenantSelectorFrame } from "../../../components/TenantSelectorFrame";
import { addDays, format } from "date-fns";
import {
  createServiceConsultantList,
  parseServiceTableProps,
  ServicesTable,
  ServicesTableHandler,
} from "./service_table";
import {
  createProductConsultantList,
  parseProductTableProps,
  ProductsTable,
  ProductsTableHandler,
} from "./product_table";
import {
  createKitConsultantList,
  KitsTable,
  KitsTableHandler,
  parseKitTableProps,
} from "./kit_table";
import uniqBy from "lodash.uniqby";

const TablesContainer = styled("div")<{ $display: boolean }>`
  padding: 0 32px 32px 32px;
  display: ${(props) => (props.$display ? "block" : "none")};
`;

const Spacer = styled("div")`
  flex: 1;
`;

const ButtonsContainer = styled("div")`
  display: flex;
  flex-direction: row;
  margin-top: 32px;
`;
const StyledDatePickerTextField = styled(TextField)`
  width: 100%;
  margin-top: 32px;
`;

const ExtractSuggestionText = styled(Typography)`
  margin: 32px 0;
  color: #d32f2f;
`;

const NoEntriesText = styled(Typography)`
  width: 100%;
  text-align: center;
`;

type EntriesMap = Record<string, { quantity: number; entryId: string }>;

export function SaleEntry() {
  const { id } = useParams();
  const productsTableRef = useRef<ProductsTableHandler>(null);
  const servicesTableRef = useRef<ServicesTableHandler>(null);
  const kitsTableRef = useRef<KitsTableHandler>(null);
  const [date, setDate] = useState<Date | null>(new Date());
  const [searchParams] = useSearchParams();
  const premiumCalculationType = searchParams.get(
    "premiumCalculationType"
  ) as PremiumCalculationType;
  const { dealershipId } = useTenant();
  const { data, loading } = useEntryFormPage_EntryQuery({
    variables: { uuid: id! },
    skip: !id,
  });

  const [upsertEntry, { loading: upsertLoading }] =
    useEntryFormPage_UpsertEntryMutation();
  const { data: initialData, loading: initialDataLoading } =
    useEntryFormPage_InitialDataQuery({
      variables: {
        dealershipId: dealershipId!,
        PremiumCalculationType: premiumCalculationType,
      },
      skip: !dealershipId,
    });
  const navigate = useNavigate();

  const constultants = initialData?.consultants.filter((i) => i.active) ?? [];
  const productEntries = uniqBy(
    data?.entry.ProductEntries.map((i) => i.Product),
    "uuid"
  );
  const products =
    productEntries.length > 0 ? productEntries : initialData?.products ?? [];

  const serviceEntries = uniqBy(
    data?.entry.ServiceEntries.map((i) => i.Service),
    "uuid"
  );
  const services =
    serviceEntries.length > 0 ? serviceEntries : initialData?.services ?? [];

  const kitEntries = uniqBy(
    data?.entry.KitEntries.map((i) => i.Kit),
    "uuid"
  );
  const kits = kitEntries.length > 0 ? kitEntries : initialData?.kits ?? [];

  useEffect(() => {
    if (data?.entry.date) {
      setDate(new Date(data?.entry.date));
    }
  }, [data]);

  const onSubmit = async () => {
    const productEntries = productsTableRef.current?.getValue() ?? {};
    const serviceEntries = servicesTableRef.current?.getValue() ?? {};
    const kitEntries = kitsTableRef.current?.getValue() ?? {};
    const createdProductEntries = data?.entry.ProductEntries.reduce<EntriesMap>(
      (acc, i) => ({
        ...acc,
        [`${i.Product.uuid}:${i.Consultant.uuid}`]: {
          quantity: i.quantity,
          entryId: i.uuid,
        },
      }),
      {}
    );
    const createdServiceEntries = data?.entry.ServiceEntries.reduce<EntriesMap>(
      (acc, i) => ({
        ...acc,
        [`${i.Service.uuid}:${i.Consultant.uuid}`]: {
          quantity: i.quantity,
          entryId: i.uuid,
        },
      }),
      {}
    );
    const createdKitEntries = data?.entry.KitEntries.reduce<EntriesMap>(
      (acc, i) => ({
        ...acc,
        [`${i.Kit.uuid}:${i.Consultant.uuid}`]: {
          quantity: i.quantity,
          entryId: i.uuid,
        },
      }),
      {}
    );
    const createdRevisionEntries =
      data?.entry.RevisionEntries.reduce<EntriesMap>(
        (acc, i) => ({
          ...acc,
          [`revision:${i.Consultant.uuid}`]: {
            quantity: i.quantity,
            entryId: i.uuid,
          },
        }),
        {}
      );
    const productConsultantList = createProductConsultantList({
      products,
      consultants: constultants,
    });
    const serviceConsultantList = createServiceConsultantList({
      services,
      consultants: constultants,
    });

    const kitConsultantList = createKitConsultantList({
      kits,
      consultants: constultants,
    });

    await toast.promise(
      upsertEntry({
        variables: {
          uuid: id,
          ProductEntries: Object.entries(productConsultantList).map(
            ([key, value]) => ({
              uuid: createdProductEntries?.[key]?.entryId,
              productId: value.productId,
              consultantId: value.consultantId,
              quantity:
                productEntries[key]?.quantity ??
                createdProductEntries?.[key]?.quantity ??
                value.quantity,
            })
          ),
          ServiceEntries: Object.entries(serviceConsultantList).map(
            ([key, value]) => ({
              uuid: createdServiceEntries?.[key]?.entryId,
              serviceId: value.serviceId,
              consultantId: value.consultantId,
              quantity:
                serviceEntries[key]?.quantity ??
                createdServiceEntries?.[key]?.quantity ??
                value.quantity,
            })
          ),
          RevisionEntries:
            constultants.map((i) => ({
              uuid: createdRevisionEntries?.[`revision:${i.uuid}`]?.entryId,
              consultantId: i.uuid,
              quantity:
                kitEntries[`revision:${i.uuid}`]?.quantity ??
                createdRevisionEntries?.[`revision:${i.uuid}`]?.quantity ??
                0,
            })) ?? [],
          KitEntries: kitConsultantList.map(([key, value]) => ({
            uuid: createdKitEntries?.[key]?.entryId,
            kitId: value.kitId,
            consultantId: value.consultantId,
            quantity:
              kitEntries[key]?.quantity ??
              createdKitEntries?.[key]?.quantity ??
              value.quantity,
          })),
          date: date || new Date(),
          dealershipId: dealershipId!,
          PremiumCalculationType: premiumCalculationType,
        },
      }),
      {
        loading: "Cadastrando, por favor aguarde...",
        success: "Sucesso!",
        error: "Erro, tente novamente.",
      }
    );
    navigate(
      `/ravpro/entries?premiumCalculationType=${premiumCalculationType}`
    );
  };

  const { productColumns, productRows } = parseProductTableProps({
    products,
    consultants: constultants ?? [],
    entry: data?.entry,
  });
  const { serviceColumns, serviceRows } = parseServiceTableProps({
    services,
    consultants: constultants ?? [],
    entry: data?.entry,
  });
  const { kitColumns, kitRows } = parseKitTableProps({
    kits,
    consultants: constultants ?? [],
    entry: data?.entry,
  });

  let extractionSuggestions;

  if (initialData?.lastEntryDate) {
    const lastEntryDateString = format(
      new Date(initialData?.lastEntryDate),
      "dd/MM/yyyy"
    );

    const extractSuggestionStartString = initialData?.lastEntryDate
      ? format(addDays(new Date(initialData.lastEntryDate), 1), "dd/MM/yyyy")
      : "";
    // const extractSuggestionEndString = initialData?.lastEntryDate
    //   ? format(subDays(new Date(), 1), "dd/MM/yyyy")
    //   : "";

    extractionSuggestions = `Último lançamento feito para esta empresa foi no dia ${lastEntryDateString}, por favor extraia o relatório a partir de ${extractSuggestionStartString}. `;
  }

  const noEntries =
    productColumns.length === 1 &&
    serviceColumns.length === 2 &&
    kitColumns.length === 1;

  return (
    <Box>
      <BackToolbar />
      {noEntries && !loading && !initialDataLoading ? (
        <NoEntriesText>
          Nenhum Produto, Serviço ou Kit registrado.
        </NoEntriesText>
      ) : null}
      {!loading && !initialDataLoading ? (
        <TablesContainer $display={!noEntries}>
          <TenantSelectorFrame>
            <DatePicker
              label="Data do lançamento (Data do ultimo dia do relatório gerado no sistema ERP. Ex. Relatório do dia 01/07/2020 até 31/07/2020, informar 31/07/2020)"
              value={date}
              onChange={(date) => {
                if (date) {
                  setDate(new Date(date?.toDateString()));
                }
              }}
              renderInput={(params) => (
                <StyledDatePickerTextField {...params} />
              )}
              views={["year", "month", "day"]}
            />
            <ExtractSuggestionText>
              {extractionSuggestions}
            </ExtractSuggestionText>
            {productColumns.length > 1 ? (
              <ProductsTable
                ref={productsTableRef}
                rows={productRows}
                columns={productColumns}
              />
            ) : null}

            {serviceColumns.length > 1 &&
            premiumCalculationType !== PremiumCalculationType.OrderVhsys ? (
              <ServicesTable
                ref={servicesTableRef}
                rows={serviceRows}
                columns={serviceColumns}
              />
            ) : null}
            {kitColumns.length > 1 &&
            premiumCalculationType !== PremiumCalculationType.OrderVhsys ? (
              <KitsTable
                ref={kitsTableRef}
                rows={kitRows}
                columns={kitColumns}
              />
            ) : null}
            <ButtonsContainer>
              <Button
                onClick={() => {
                  navigate(-1);
                }}
                sx={{ width: 220 }}
                disabled={upsertLoading}
              >
                Cancelar
              </Button>
              <Spacer />
              <Button
                variant="contained"
                onClick={onSubmit}
                sx={{ width: 220 }}
                disabled={upsertLoading}
              >
                Cadastrar
              </Button>
            </ButtonsContainer>
          </TenantSelectorFrame>
        </TablesContainer>
      ) : (
        <LinearProgress />
      )}
    </Box>
  );
}
