import { InputProps, TextField } from "@mui/material";
import { NumericFormat } from "react-number-format";

export type MoneyFieldProps = {
  label?: string;
  name?: string;
  onChange?: (...event: any[]) => void;
  value: string | undefined;
  InputProps?: InputProps;
  helperText?: string;
  disabled?: boolean;
};

export const MoneyField: React.FC<MoneyFieldProps> = ({
  label,
  name,
  onChange,
  value,
  InputProps,
  helperText,
  disabled,
}) => {
  return (
    <NumericFormat
      disabled={disabled}
      InputProps={InputProps}
      label={label}
      name={name}
      onChange={onChange}
      value={value}
      customInput={TextField}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      helperText={helperText}
    />
  );
};

export const parseFieldToFloat = (x: string | undefined) => {
  return x && x.length > 0
    ? parseFloat(x.replaceAll(".", "").replaceAll(",", "."))
    : undefined;
};

export const formatCurrency = (x: number) =>
  Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    maximumFractionDigits: 2,
  }).format(x);

export const parseFieldToDecimal = (x: string | undefined): number => {
  const y = x && x.length > 0 ? x.split(".").join("") : undefined;
  return y ? parseFloat(y.split(",").join(".")) : 0;
};

export const parseIntToField = (x: number | undefined) =>
  x ? x.toFixed(2).replace(".", ",") : "";
