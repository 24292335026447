import Box from "@mui/material/Box";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Icon,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import {
  Manufacturers,
  PremiumCalculationType,
  ProductFormPage_InitialDataQuery,
  ProductFormPage_ProductCatalogsQuery,
  ProductFormPage_ProductQuery,
  UserRole,
  useProductFormPage_InitialDataQuery,
  useProductFormPage_ProductCatalogsQuery,
  useProductFormPage_ProductQuery,
  useProductFormPage_UpsertProductMutation,
} from "../../../../generated/graphql";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "@emotion/styled";
import { toast } from "react-hot-toast";
import { useTenant } from "../../../context/TenantContext";
import { BackToolbar } from "../../../components/BackToolbar";
import {
  formatCurrency,
  MoneyField,
  parseFieldToFloat,
  parseFieldToDecimal,
  parseIntToField,
} from "../../../../components/MoneyField";
import orderBy from "lodash.orderby";
import { GroupDropdown } from "../../../components/GroupDropdown";
import { useAuth } from "../../../../contexts/AuthContext";

type ProductFormData = {
  uuid?: string;
  productCatalogId?: string;
  active?: boolean;
  productCatalogName?: string;
  productCatalogCode?: string;
  productCatalogManufacturer?: Manufacturers | string;
  newProductCatalog?: boolean;
  productCode?: string;
  serviceCode?: string;
  Kit?: ProductFormPage_ProductQuery["product"]["Kit"] | null;
  costPrice?: string;
  salePrice?: string;
  serviceSalePrice?: string;
  registrationPremium?: string;
  premium1?: string;
  premium2?: string;
  premium3?: string;
  premium4?: string;
  premium5?: string;
  premium6?: string;
  premiumCalculationType?: string;
  productDealerships: ProductFormPage_ProductQuery["product"]["Dealerships"];
  manufacturer?: Manufacturers;
};

export function ProductFormPage() {
  const { id } = useParams();
  const { groupId } = useTenant();
  const [searchParams] = useSearchParams();
  const manufacturer = searchParams.get("manufacturer") as Manufacturers;
  const premiumCalculationType = searchParams.get(
    "premiumCalculationType"
  ) as PremiumCalculationType;
  const { data, loading } = useProductFormPage_ProductQuery({
    variables: { uuid: id! },
    skip: !id,
  });
  const { data: productCatalogs, loading: productCatalogsLoading } =
    useProductFormPage_ProductCatalogsQuery({
      variables: {
        Manufacturer: manufacturer,
      },
    });
  const [upsertProduct, { loading: upsertLoading }] =
    useProductFormPage_UpsertProductMutation();
  const { data: initialData, loading: initialDataLoading } =
    useProductFormPage_InitialDataQuery({
      variables: {
        groupId: groupId!,
      },
      skip: !groupId,
    });
  const navigate = useNavigate();

  const onSubmit = async (data: ProductFormData) => {
    if (data.productDealerships.length === 0) {
      toast.error("Pelo menos uma concessionária precisa ser selecionada.");
      return;
    }
    const parsedPremiums = {
      registrationPremium: parseFieldToDecimal(data.registrationPremium),
      premium1: parseFieldToDecimal(data.premium1),
      premium2: parseFieldToDecimal(data.premium2),
      premium3: parseFieldToDecimal(data.premium3),
      premium4: parseFieldToDecimal(data.premium4),
      premium5: parseFieldToDecimal(data.premium5),
      premium6: parseFieldToDecimal(data.premium6),
    };
    if (
      parsedPremiums.premium1 +
        parsedPremiums.premium2 +
        parsedPremiums.premium3 +
        parsedPremiums.premium4 +
        parsedPremiums.premium5 +
        parsedPremiums.premium6 >
      parsedPremiums.registrationPremium
    ) {
      toast.error("Soma dos prêmios não pode ultrapassar prêmio de cadastro.");
    } else {
      await toast.promise(
        upsertProduct({
          variables: {
            uuid: id,
            productCatalogId: data.productCatalogId,
            active: data.active ?? true,
            kitId: data.Kit?.uuid,
            productCode: data.productCode,
            serviceCode: data.serviceCode,
            dealershipIds: data.productDealerships.map((i) => i.uuid) ?? [],
            serviceSalePrice: parseFieldToDecimal(data.serviceSalePrice),
            costPrice: parseFieldToDecimal(data.costPrice),
            salePrice: parseFieldToDecimal(data.salePrice),
            PremiumCalculationType: data.premiumCalculationType ?? "SALE",
            ...parsedPremiums,
          },
        }),
        {
          loading: "Cadastrando",
          success: "Sucesso!",
          error: "Erro, tente novamente.",
        }
      );
      navigate(
        `/ravpro/products?manufacturer=${manufacturer}&premiumCalculationType=${premiumCalculationType}`
      );
    }
  };
  return (
    <Box>
      <BackToolbar />
      {!loading && !initialDataLoading && !productCatalogsLoading ? (
        <ProductForm
          {...data?.product}
          serviceSalePrice={parseIntToField(data?.product.serviceSalePrice)}
          costPrice={parseIntToField(data?.product.costPrice)}
          salePrice={parseIntToField(data?.product.salePrice)}
          registrationPremium={parseIntToField(
            data?.product.registrationPremium
          )}
          premium1={parseIntToField(data?.product.premium1)}
          premium2={parseIntToField(data?.product.premium2)}
          premium3={parseIntToField(data?.product.premium3)}
          premium4={parseIntToField(data?.product.premium4)}
          premium5={parseIntToField(data?.product.premium5)}
          premium6={parseIntToField(data?.product.premium6)}
          productCatalogId={data?.product.ProductCatalog?.uuid}
          productCatalogs={productCatalogs?.productCatalogs ?? []}
          manufacturer={manufacturer}
          kits={initialData?.kits ?? []}
          premiumDescription={initialData?.group.PremiumDescription}
          premiumCalculationType={premiumCalculationType}
          onSubmit={onSubmit}
          productDealerships={data?.product.Dealerships ?? []}
          dealerships={initialData?.group.Dealerships ?? []}
          loading={upsertLoading}
        />
      ) : (
        <LinearProgress />
      )}
    </Box>
  );
}

const Form = styled("form")`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 400px;
  margin: 16px auto;
`;

const Line = styled("div")`
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
`;

type PremiumTextProps = {
  $invalid: boolean;
};

const PremiumText = styled("span")<PremiumTextProps>`
  color: ${(props) => (props.$invalid ? "red" : "black")};
  text-align: right;
`;

type ProductFormProps = ProductFormData & {
  productCatalogs: ProductFormPage_ProductCatalogsQuery["productCatalogs"];
  premiumDescription: ProductFormPage_InitialDataQuery["group"]["PremiumDescription"];
  dealerships: ProductFormPage_InitialDataQuery["group"]["Dealerships"];
  kits: ProductFormPage_InitialDataQuery["kits"];
  onSubmit: (data: ProductFormData) => void;
  loading: boolean;
};

const ProductForm: React.FC<ProductFormProps> = ({
  active,
  productCatalogName,
  productCatalogCode,
  productCatalogId,
  newProductCatalog,
  productCode,
  serviceCode,
  serviceSalePrice,
  costPrice,
  salePrice,
  registrationPremium,
  premium1,
  premium2,
  premium3,
  premium4,
  premium5,
  premium6,
  productCatalogs,
  premiumDescription,
  dealerships,
  productDealerships,
  kits,
  Kit,
  manufacturer,
  premiumCalculationType,
  onSubmit,
  loading,
}) => {
  const defaultValues = {
    active,
    newProductCatalog,
    productCatalogName,
    productCatalogCode,
    productCatalogId,
    productCode,
    serviceCode,
    serviceSalePrice,
    costPrice,
    salePrice,
    registrationPremium,
    premium1,
    premium2,
    premium3,
    premium4,
    premium5,
    premium6,
    premiumCalculationType,
    productDealerships,
    Kit,
    manufacturer,
  };
  const { handleSubmit, control, setValue, watch } = useForm({
    defaultValues,
  });
  const { userRoles } = useAuth();

  const serviceSalePriceWatched = watch("serviceSalePrice");
  const salePriceWatched = watch("salePrice");
  const premium1Float = parseFieldToFloat(watch("premium1")) ?? 0;
  const premium2Float = parseFieldToFloat(watch("premium2")) ?? 0;
  const premium3Float = parseFieldToFloat(watch("premium3")) ?? 0;
  const premium4Float = parseFieldToFloat(watch("premium4")) ?? 0;
  const premium5Float = parseFieldToFloat(watch("premium5")) ?? 0;
  const premium6Float = parseFieldToFloat(watch("premium6")) ?? 0;
  const premiumTotal =
    premium1Float +
    premium2Float +
    premium3Float +
    premium4Float +
    premium5Float +
    premium6Float;

  const registrationPremiumWatched = watch("registrationPremium");
  const registrationPremiumFloat =
    parseFieldToFloat(registrationPremiumWatched) ?? 0;

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <GroupDropdown disabled />
      <Line />
      <FormControlLabel
        control={
          <Checkbox
            name="active"
            defaultChecked={
              defaultValues.active === undefined ? true : defaultValues.active
            }
            onChange={(e) => {
              setValue("active", e.target.checked);
            }}
          />
        }
        label="Ativo"
      />
      <Autocomplete
        options={[
          Manufacturers.Original,
          Manufacturers.Ravpro,
          Manufacturers.Other,
        ]}
        renderInput={(params) => <TextField {...params} label="Fabricante" />}
        onChange={(_e, value) => value && setValue("manufacturer", value)}
        isOptionEqualToValue={(option, value) => option === value}
        defaultValue={manufacturer || null}
        disabled
      />
      <FormControl>
        <InputLabel>Premiação calculada por</InputLabel>
        <Controller
          defaultValue={defaultValues.premiumCalculationType ?? ""}
          render={({ field }) => (
            <Select {...field} label="Premio gerente" disabled>
              <MenuItem value="SALE">Venda</MenuItem>
              <MenuItem value="ORDER_VHSYS">Pedido (VHSYS)</MenuItem>
            </Select>
          )}
          control={control}
          name="premiumCalculationType"
        />
      </FormControl>

      <Autocomplete
        options={orderBy(productCatalogs, "name")}
        renderInput={(params) => <TextField {...params} label="Nome" />}
        onChange={(_e, value) => setValue("productCatalogId", value?.uuid)}
        isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
        getOptionLabel={(i) => `${i.name} - ${i.Manufacturer} - ${i.code}`}
        defaultValue={
          productCatalogs.filter(
            (i) => i.uuid === defaultValues.productCatalogId
          )?.[0] || null
        }
      />

      <Line style={{ marginTop: -16 }} />
      <Autocomplete
        multiple
        options={orderBy(dealerships, "name")}
        renderInput={(params) => (
          <TextField {...params} label="Concessionárias" />
        )}
        onChange={(_e, value) => setValue("productDealerships", value)}
        isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
        getOptionLabel={(i) => i.fantasyName ?? ""}
        defaultValue={defaultValues.productDealerships}
        sx={{ marginBottom: 2, width: "100%" }}
      />
      <Controller
        name="productCode"
        control={control}
        defaultValue={defaultValues.productCode ?? ""}
        render={({ field }) => (
          <TextField inputProps={field} label="Codigo Produto" />
        )}
      />
      <Controller
        name="serviceCode"
        control={control}
        defaultValue={defaultValues.serviceCode ?? ""}
        render={({ field }) => (
          <TextField inputProps={field} label="Codigo Serviço" />
        )}
      />

      <Line />
      <Autocomplete
        options={orderBy(kits, "KitCatalog.hierarchy")}
        renderInput={(params) => <TextField {...params} label="Kit" />}
        onChange={(_e, value) => setValue("Kit", value)}
        isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
        getOptionLabel={(i) => i.KitCatalog.name}
        defaultValue={defaultValues.Kit}
      />

      <Line />
      <Typography>Preços: </Typography>

      <Controller
        name="costPrice"
        control={control}
        defaultValue={defaultValues.costPrice ?? ""}
        render={({ field: { onChange, name, value } }) => (
          <MoneyField
            label="Preço custo produto"
            name={name}
            onChange={onChange}
            value={value}
            disabled={!userRoles.includes(UserRole.RavproAdmin)}
          />
        )}
      />

      <Controller
        name="salePrice"
        control={control}
        defaultValue={defaultValues.salePrice ?? ""}
        render={({ field: { onChange, name, value } }) => (
          <MoneyField
            label="Preço venda produto"
            name={name}
            onChange={onChange}
            value={value}
          />
        )}
      />
      <Controller
        name="serviceSalePrice"
        control={control}
        defaultValue={defaultValues.serviceSalePrice ?? ""}
        render={({ field: { onChange, name, value } }) => (
          <MoneyField
            label="Preço venda serviço"
            name={name}
            onChange={onChange}
            value={value}
          />
        )}
      />
      <Typography>
        Preço de venda:{" "}
        {formatCurrency(
          parseFieldToDecimal(salePriceWatched) +
            parseFieldToDecimal(serviceSalePriceWatched)
        )}
      </Typography>
      <Line />
      <Typography>Prêmios: </Typography>

      <Controller
        name="registrationPremium"
        control={control}
        defaultValue={defaultValues.registrationPremium ?? ""}
        render={({ field: { onChange, name, value } }) => (
          <MoneyField
            label="Prêmio cadastro"
            name={name}
            onChange={onChange}
            value={value}
            disabled={!userRoles.includes(UserRole.RavproAdmin)}
            helperText={
              registrationPremiumFloat
                ? `Valor sugerido para premiações: ${formatCurrency(
                    registrationPremiumFloat * 0.85
                  )}`
                : ""
            }
          />
        )}
      />
      <Controller
        name="premium1"
        control={control}
        defaultValue={defaultValues.premium1 ?? ""}
        render={({ field: { onChange, name, value } }) => (
          <MoneyField
            label="Prêmio 1"
            name={name}
            onChange={onChange}
            value={value}
            InputProps={{
              endAdornment: <Info title={premiumDescription?.premium1} />,
            }}
          />
        )}
      />
      <Controller
        name="premium2"
        control={control}
        defaultValue={defaultValues.premium2 ?? ""}
        render={({ field: { onChange, name, value } }) => (
          <MoneyField
            label="Prêmio 2"
            name={name}
            onChange={onChange}
            value={value}
            InputProps={{
              endAdornment: <Info title={premiumDescription?.premium2} />,
            }}
          />
        )}
      />
      <Controller
        name="premium3"
        control={control}
        defaultValue={defaultValues.premium3 ?? ""}
        render={({ field: { onChange, name, value } }) => (
          <MoneyField
            label="Prêmio 3"
            name={name}
            onChange={onChange}
            value={value}
            InputProps={{
              endAdornment: <Info title={premiumDescription?.premium3} />,
            }}
          />
        )}
      />
      <Controller
        name="premium4"
        control={control}
        defaultValue={defaultValues.premium4 ?? ""}
        render={({ field: { onChange, name, value } }) => (
          <MoneyField
            label="Prêmio 4"
            name={name}
            onChange={onChange}
            value={value}
            InputProps={{
              endAdornment: <Info title={premiumDescription?.premium4} />,
            }}
          />
        )}
      />
      <Controller
        name="premium5"
        control={control}
        defaultValue={defaultValues.premium5 ?? ""}
        render={({ field: { onChange, name, value } }) => (
          <MoneyField
            label="Prêmio 5"
            name={name}
            onChange={onChange}
            value={value}
            InputProps={{
              endAdornment: <Info title={premiumDescription?.premium5} />,
            }}
          />
        )}
      />
      <Controller
        name="premium6"
        control={control}
        defaultValue={defaultValues.premium6 ?? ""}
        render={({ field: { onChange, name, value } }) => (
          <MoneyField
            label="Prêmio 6"
            name={name}
            onChange={onChange}
            value={value}
            InputProps={{
              endAdornment: <Info title={premiumDescription?.premium6} />,
            }}
          />
        )}
      />
      <PremiumText $invalid={premiumTotal > registrationPremiumFloat * 0.85}>
        Total Prêmios: {formatCurrency(premiumTotal)}
      </PremiumText>
      <Button type="submit" variant="contained" disabled={loading}>
        Cadastrar
      </Button>
    </Form>
  );
};

type InfoProps = {
  title?: string;
};

const Info: React.FC<InfoProps> = ({ title }) =>
  title && title.length > 0 ? (
    <InputAdornment position="end">
      <Tooltip title={title}>
        <Icon style={{ color: "rgba(0,0,0,0.4)" }}>info</Icon>
      </Tooltip>
    </InputAdornment>
  ) : (
    <></>
  );
