import Box from "@mui/material/Box";
import { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Avatar, Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useProfilePage_UpdateUserMutation } from "../../generated/graphql";

type FormData = {
  firstName: string;
  lastName: string;
};

export function ProfilePage() {
  const { userDetails, reloadUserDetails } = useAuth();
  const [fileData, setFileData] = useState("");
  const [updateUserMutation] = useProfilePage_UpdateUserMutation();

  const defaultValues = {
    firstName: userDetails?.FirstName ?? "",
    lastName: userDetails?.Lastname ?? "",
  };
  const { handleSubmit, register } = useForm({
    defaultValues,
  });
  const [loading, setLoading] = useState(false);
  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        setFileData((reader.result as string).replace(/^([^,]+),/, ""));
      };
    }
  };
  const updateUser = async (data: FormData) => {
    await toast.promise(
      updateUserMutation({
        variables: {
          firstName: data.firstName,
          lastName: data.lastName,
          avatarBase64: fileData,
        },
      }),
      {
        loading: "Cadastrando",
        success: "Sucesso!",
        error: "Erro, tente novamente.",
      }
    );
    reloadUserDetails();
  };

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    await updateUser(data);
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ padding: "16px 0" }}>
        <Box
          width={400}
          display="flex"
          alignItems="center"
          flexDirection="column"
          rowGap={3}
          margin="0 auto"
        >
          <Avatar
            sx={{ height: "200px", width: "200px", fontSize: 100 }}
            src={
              fileData && fileData.length > 0
                ? `data:image/png;base64,${fileData}`
                : userDetails?.Avatar
            }
          >
            {`${userDetails?.FirstName?.[0] ?? "A"}${
              userDetails?.Lastname?.[0] ?? "A"
            }`}
          </Avatar>
          <Button variant="contained" component="label">
            Mudar imagem
            <input type="file" onChange={handleFileInputChange} hidden />
          </Button>
          <TextField
            style={{ width: 400 }}
            name="firstName"
            label="Nome"
            InputProps={register("firstName")}
            defaultValue={defaultValues.firstName}
          />
          <TextField
            style={{ width: 400 }}
            name="lastName"
            label="Sobrenome"
            InputProps={register("lastName")}
            defaultValue={defaultValues.lastName}
          />

          <Button
            style={{ width: 400 }}
            type="submit"
            variant="contained"
            disabled={loading}
          >
            Cadastrar
          </Button>
        </Box>
      </Box>
    </form>
  );
}
