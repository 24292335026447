import { IconType } from "../assets/icons";

export type IconProps = {
  className?: string;
  type: IconType;
};

export const Icon: React.FC<IconProps> = ({ className, type }) => {
  return <i className={`ti ti-${type} ${className}`}></i>;
};
