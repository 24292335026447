import {
  createHttpLink,
  ApolloClient,
  InMemoryCache,
  from,
} from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { setContext } from "@apollo/client/link/context";
import ax from "axios";
import { getUserInLocalStorage, User } from "../contexts/AuthContext";
import { makeUseAxios } from "axios-hooks";

const contextLink = setContext(async () => {
  const session = getUserInLocalStorage();
  if (session) {
    return {
      headers: {
        Authorization: `Bearer ${session.access_token}`,
      },
    };
  }
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 0,
  },
});

const link = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
});

export const apolloClient = new ApolloClient({
  link: from([retryLink, contextLink, link]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
    },
  },
});

export const axios = ax.create({
  baseURL: process.env.REACT_APP_BI_API_URL,
});

axios.interceptors.request.use(function (config) {
  const userString = localStorage.getItem("user");
  if (config && config.headers && userString && userString.length > 0) {
    try {
      const user = JSON.parse(userString) as User;

      config.headers["Authorization"] = `Bearer ${user.access_token}`;
    } catch (err) {
      localStorage.clear();
    }
  }
  return config;
});

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      localStorage.removeItem("user");
    }
    return Promise.reject(error);
  }
);

export const useAxios = makeUseAxios({ axios, cache: false });
