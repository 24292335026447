import { Routes, Route, useLocation, Navigate, Outlet } from "react-router-dom";
import LoggedInLayout from "./components/LoggedInLayout";
import { LoginLayout } from "./components/LoginLayout";
import { AuthContext, AuthProvider, useAuth } from "./contexts/AuthContext";
import { DashboardPage } from "./pages/DashboardPage";
import { LoginPage } from "./pages/LoginPage";
import "@icon/themify-icons/themify-icons.css";
import { IconPage } from "./pages/IconPage";
import Favicon from "react-favicon";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ProfilePage } from "./pages/ProfilePage";
import { Toaster } from "react-hot-toast";
import { useEffect } from "react";
import { getGroupTheme } from "./groups";
import { ConsultantTablePage } from "./ravpro/pages/Consultant/ConsultantTable";
import { TenantProvider } from "./ravpro/context/TenantContext";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./api";
import { ConsultantFormPage } from "./ravpro/pages/Consultant/ConsultantForm";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DealershipTablePage } from "./ravpro/pages/Dealership/DealershipTable";
import { DealershipFormPage } from "./ravpro/pages/Dealership/DealershipForm";
import ptBR from "date-fns/locale/pt-BR";
import { PremiumDescriptionFormPage } from "./ravpro/pages/PremiumDescription/PremiumDescriptionForm";
import { ProductFormPage } from "./ravpro/pages/Products/ProductForm";
import { ProductTablePage } from "./ravpro/pages/Products/ProductTable";
import { ServiceTablePage } from "./ravpro/pages/Services/ServiceTable";
import { ServiceFormPage } from "./ravpro/pages/Services/ServiceForm";
import { EntryTablePage } from "./ravpro/pages/Entries/EntryTable";
import { EntryFormPage } from "./ravpro/pages/Entries/EntryForm";
import { UserPermissionsTablePage } from "./ravpro/pages/UserPermissions/UserPermissionsTable";
import { UserPermissionsFormPage } from "./ravpro/pages/UserPermissions/UserPermissionsForm";
import { GroupTablePage } from "./ravpro/pages/Group/GroupTable";
import { GroupFormPage } from "./ravpro/pages/Group/GroupForm";
import { KitTablePage } from "./ravpro/pages/Kits/KitTable";
import { KitFormPage } from "./ravpro/pages/Kits/KitForm";
import { PasswordChangePage } from "./pages/PasswordChangePage";
import { ProductCatalogFormPage } from "./ravpro/pages/ProductCatalog/ProductCatalogForm";
import { ProductCatalogTablePage } from "./ravpro/pages/ProductCatalog/ProductCatalogTable";
import { SellerTablePage } from "./ravpro/pages/Sellers/SellerTable";
import { SellerFormPage } from "./ravpro/pages/Sellers/SellerForm";

export default function App() {
  const muiTheme = createTheme({
    palette: {
      primary: {
        main: getGroupTheme().theme.primaryColor,
      },
      secondary: {
        main: getGroupTheme().theme.appBarColor,
        light: getGroupTheme().theme.loginRightBackgroundColor,
      },
    },
    typography: {
      fontFamily: [
        "Rubik",
        "-apple-system",
        "BlinkMacSystemFont",
        "Segoe UI",
        "Roboto",
        "Oxygen",
        "Ubuntu",
        "Cantarell",
        "Fira Sans",
        "Droid Sans",
        "Helvetica Neue",
        "sans-serif",
      ].join(","),
    },
  });
  useEffect(() => {
    document.title = getGroupTheme().theme.tabTitle;
  }, []);
  return (
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={muiTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
          <>
            <Favicon url={getGroupTheme().theme.faviconUrl} />
            <Toaster />
            <AuthProvider>
              <AuthContext.Consumer>
                {({ loading }) =>
                  !loading ? (
                    <Routes>
                      {/* <Route> */}
                      {/* <Route element={<Layout />}> */}
                      {/* <Route path="/" element={<PublicPage />} /> */}
                      {/* <Route path="/login" element={<LoginPage />} /> */}
                      {/* </Route> */}
                      <Route element={<LoginLayout />}>
                        <Route path="/*" element={<LoginPage />} />
                      </Route>
                      <Route
                        element={
                          <RequireAuth>
                            <LoggedInLayout />
                          </RequireAuth>
                        }
                      >
                        {/* <Route path="/dashboard" element={<DashboardPage />} /> */}
                        <Route path="/dashboard" element={<DashboardPage />} />
                        <Route
                          path="/ravpro"
                          element={
                            <TenantProvider>
                              <Outlet />
                            </TenantProvider>
                          }
                        >
                          <Route
                            path="/ravpro/consultants"
                            element={<ConsultantTablePage />}
                          />
                          <Route
                            path="/ravpro/consultants/create"
                            element={<ConsultantFormPage />}
                          />
                          <Route
                            path="/ravpro/consultants/update/:id"
                            element={<ConsultantFormPage />}
                          />
                          <Route
                            path="/ravpro/sellers"
                            element={<SellerTablePage />}
                          />
                          <Route
                            path="/ravpro/sellers/create"
                            element={<SellerFormPage />}
                          />
                          <Route
                            path="/ravpro/sellers/update/:id"
                            element={<SellerFormPage />}
                          />
                          <Route
                            path="/ravpro/dealerships"
                            element={<DealershipTablePage />}
                          />
                          <Route
                            path="/ravpro/dealerships/create"
                            element={<DealershipFormPage />}
                          />
                          <Route
                            path="/ravpro/dealerships/update/:id"
                            element={<DealershipFormPage />}
                          />
                          <Route
                            path="/ravpro/premium-description"
                            element={<PremiumDescriptionFormPage />}
                          />

                          <Route
                            path="/ravpro/products"
                            element={<ProductTablePage />}
                          />
                          <Route
                            path="/ravpro/products/create"
                            element={<ProductFormPage />}
                          />
                          <Route
                            path="/ravpro/products/update/:id"
                            element={<ProductFormPage />}
                          />
                          <Route
                            path="/ravpro/services"
                            element={<ServiceTablePage />}
                          />
                          <Route
                            path="/ravpro/services/create"
                            element={<ServiceFormPage />}
                          />
                          <Route
                            path="/ravpro/services/update/:id"
                            element={<ServiceFormPage />}
                          />
                          <Route
                            path="/ravpro/entries"
                            element={<EntryTablePage />}
                          />
                          <Route
                            path="/ravpro/entries/create"
                            element={<EntryFormPage />}
                          />
                          <Route
                            path="/ravpro/entries/update/:id"
                            element={<EntryFormPage />}
                          />

                          <Route
                            path="/ravpro/groups"
                            element={<GroupTablePage />}
                          />
                          <Route
                            path="/ravpro/groups/create"
                            element={<GroupFormPage />}
                          />
                          <Route
                            path="/ravpro/groups/update/:id"
                            element={<GroupFormPage />}
                          />

                          <Route
                            path="/ravpro/kits"
                            element={<KitTablePage />}
                          />
                          <Route
                            path="/ravpro/kits/create"
                            element={<KitFormPage />}
                          />
                          <Route
                            path="/ravpro/kits/update/:id"
                            element={<KitFormPage />}
                          />

                          <Route
                            path="/ravpro/user-permissions"
                            element={<UserPermissionsTablePage />}
                          />
                          <Route
                            path="/ravpro/user-permissions/update/:email"
                            element={<UserPermissionsFormPage />}
                          />
                          <Route
                            path="/ravpro/product-catalogs"
                            element={<ProductCatalogTablePage />}
                          />
                          <Route
                            path="/ravpro/product-catalogs/create"
                            element={<ProductCatalogFormPage />}
                          />
                          <Route
                            path="/ravpro/product-catalogs/update/:id"
                            element={<ProductCatalogFormPage />}
                          />
                        </Route>

                        <Route
                          path="/dashboard/:categoryId"
                          element={<DashboardPage />}
                        />
                        <Route
                          path="/dashboard/:categoryId/sub-category/:dashboardId"
                          element={<DashboardPage />}
                        />
                        <Route path="/profile" element={<ProfilePage />} />
                        <Route
                          path="/change-password"
                          element={<PasswordChangePage />}
                        />
                        <Route path="/icons" element={<IconPage />} />
                      </Route>
                    </Routes>
                  ) : (
                    <></>
                  )
                }
              </AuthContext.Consumer>
            </AuthProvider>
          </>
        </LocalizationProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

// function RedirectAuth

function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();
  if (!auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
}
