const theme = {
  appBarColor: "#000000",
  primaryColor: "#B00000",
  tabTitle: "Grupo Vip Car - Scalar BI",
  faviconUrl: "https://scalar.ws/favicon.ico",
  loginRightBackgroundColor: "#DEDEDE",
  hideLogoWhenMenuUncollapsed: true,
};

export default theme;
