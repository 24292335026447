import styled from "@emotion/styled";
import {
  Box,
  Icon,
  IconButton,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Manufacturers,
  useProductCatalogsPage_ProductCatalogsQuery,
} from "../../../../generated/graphql";
import { TabPanel } from "../../../components/TabPanel";

const Container = styled("div")`
  margin: 32px;
`;

const CToolbar = styled(Toolbar)`
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
`;

const Title = styled(Typography)`
  flex: 1;
`;

export const ProductCatalogTablePage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTab = searchParams.get("manufacturer") as Manufacturers;
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={selectedTab}
          onChange={(_, value) => {
            setSearchParams({ manufacturer: value });
          }}
        >
          <Tab label="Ravpro" value={Manufacturers.Ravpro} />
          <Tab label="Original" value={Manufacturers.Original} />
          <Tab label="Outros" value={Manufacturers.Other} />
        </Tabs>
      </Box>
      <TabPanel value={selectedTab} tab={Manufacturers.Ravpro}>
        <ProductCatalogTable tab={selectedTab} />
      </TabPanel>
      <TabPanel value={selectedTab} tab={Manufacturers.Original}>
        <ProductCatalogTable tab={selectedTab} />
      </TabPanel>
      <TabPanel value={selectedTab} tab={Manufacturers.Other}>
        <ProductCatalogTable tab={selectedTab} />
      </TabPanel>
    </>
  );
};
export const ProductCatalogTable = ({ tab }: { tab: Manufacturers }) => {
  const navigate = useNavigate();
  const { data } = useProductCatalogsPage_ProductCatalogsQuery({
    variables: { Manufacturer: tab },
  });
  const [searchParams] = useSearchParams();
  const manufacturer = searchParams.get("manufacturer") as Manufacturers;

  return (
    <Container>
      <Paper>
        <CToolbar>
          <Title>Catálogos</Title>
          <IconButton
            onClick={() =>
              navigate(
                `/ravpro/product-catalogs/create?manufacturer=${manufacturer}`
              )
            }
          >
            <Icon>add</Icon>
          </IconButton>
        </CToolbar>
        <Table>
          {data?.productCatalogs && data?.productCatalogs.length > 0 ? (
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Código</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {_.orderBy(data?.productCatalogs, "name").map((row) => (
              <TableRow key={row.uuid} hover>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.code}</TableCell>
                <TableCell align="right">
                  <IconButton
                    onClick={() =>
                      navigate(
                        `/ravpro/product-catalogs/update/${row.uuid}?manufacturer=${manufacturer}`
                      )
                    }
                  >
                    <Icon>edit</Icon>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {data?.productCatalogs && data.productCatalogs.length === 0 ? (
              <TableRow>
                <TableCell align="center">Nenhum registro encontrado</TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </Paper>
    </Container>
  );
};
