import styled from "@emotion/styled";
import {
  Icon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import _ from "lodash";
import {
  useConsultantsTablePage_ConsultantsQuery,
  useConsultantsTablePage_DeleteConsultantMutation,
} from "../../../../generated/graphql";
import { useTenant } from "../../../context/TenantContext";
import { TenantSelectorFrame } from "../../../components/TenantSelectorFrame";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../../../components/DeleteModal";
import { useState } from "react";

const Container = styled("div")`
  margin: 32px;
`;

const CToolbar = styled(Toolbar)`
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
`;

const Title = styled(Typography)`
  flex: 1;
`;

export const ConsultantTablePage = () => {
  const { dealershipId } = useTenant();
  const navigate = useNavigate();
  const [deleteId, setDeleteId] = useState<string | undefined>();
  const [deleteConsultant, { loading: deleteLoading }] =
    useConsultantsTablePage_DeleteConsultantMutation();
  const { data, refetch } = useConsultantsTablePage_ConsultantsQuery({
    variables: { dealershipId: dealershipId! },
    skip: !dealershipId,
  });

  return (
    <Container>
      <TenantSelectorFrame>
        <Paper>
          <CToolbar>
            <Title>Consultores</Title>
            <IconButton onClick={() => navigate("/ravpro/consultants/create")}>
              <Icon>add</Icon>
            </IconButton>
          </CToolbar>
          <Table>
            {/* <TableHead>
            <TableRow>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead> */}
            <TableBody>
              {_.orderBy(data?.consultants, "name").map((row) => (
                <TableRow key={row.uuid} hover>
                  <TableCell>{row.name}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() =>
                        navigate(`/ravpro/consultants/update/${row.uuid}`)
                      }
                    >
                      <Icon>edit</Icon>
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setDeleteId(row.uuid);
                      }}
                    >
                      <Icon>delete</Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {data?.consultants && data.consultants.length === 0 ? (
                <TableRow>
                  <TableCell align="center">
                    Nenhum registro encontrado
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </Paper>
        <DeleteModal
          open={!!deleteId}
          onClose={() => setDeleteId(undefined)}
          loading={deleteLoading}
          onConfirm={async () => {
            await deleteConsultant({ variables: { uuid: deleteId! } });
            setDeleteId(undefined);
            refetch();
          }}
        />
      </TenantSelectorFrame>
    </Container>
  );
};
