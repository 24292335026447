import {
  EntryFormPage_EntryQuery,
  EntryFormPage_InitialDataQuery,
} from "../../../../generated/graphql";
import { DataGrid, GridColumns, GridRowsProp } from "@mui/x-data-grid";
import { useState, forwardRef, useImperativeHandle } from "react";
import { TableContainer, TableTitleText } from "./components";

type ServiceTableProps = {
  rows: GridRowsProp;
  columns: GridColumns;
};

type ServiceRowMap = Record<
  string,
  {
    consultantId: string;
    serviceId: string;
    quantity: number;
  }
>;

export type ServicesTableHandler = {
  getValue: () => ServiceRowMap;
};

export const ServicesTable = forwardRef<
  ServicesTableHandler,
  ServiceTableProps
>(({ rows, columns }, ref) => {
  const [value, setValue] = useState<ServiceRowMap>({});
  useImperativeHandle(
    ref,
    () => ({
      getValue: () => value,
    }),
    [value]
  );
  return (
    <TableContainer>
      <TableTitleText>Serviços</TableTitleText>
      <DataGrid
        rows={rows}
        columns={columns}
        hideFooter
        disableColumnFilter
        disableColumnMenu
        onCellEditCommit={(params) => {
          setValue({
            ...value,
            [`${[params.field]}:${params.id}`]: {
              consultantId: params.id as string,
              serviceId: params.field,
              quantity: params.value as number,
            },
          });
        }}
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            textOverflow: "clip",
            whiteSpace: "break-spaces",
            lineHeight: 1,
          },
        }}
      />
    </TableContainer>
  );
});

type ParseServiceTablePropsParams = {
  services: EntryFormPage_InitialDataQuery["services"];
  consultants: EntryFormPage_InitialDataQuery["consultants"];
  entry: EntryFormPage_EntryQuery["entry"] | undefined;
};

export const parseServiceTableProps = ({
  services,
  consultants,
  entry,
}: ParseServiceTablePropsParams) => {
  const serviceColumns: GridColumns = [
    {
      field: "consultant",
      headerName: "Consultor",
      editable: false,
      width: 250,
    },

    ...(services.map((i) => ({
      field: i.uuid,
      headerName: `${i.ServiceCatalog?.name}`,
      editable: true,
      width: 400,
      type: "number",
    })) ?? []),
  ];

  const createdServiceRows: Record<
    string,
    EntryFormPage_EntryQuery["entry"]["ServiceEntries"][0]
  > =
    entry?.ServiceEntries.reduce(
      (acc, i) => ({
        ...acc,
        [`${i.Service.uuid}:${i.Consultant.uuid}`]: i,
      }),
      {}
    ) ?? {};

  const serviceRows: GridRowsProp =
    consultants.map((i) => ({
      id: i.uuid,
      consultant: i.name,
      ...services.reduce(
        (acc, j) => ({
          ...acc,
          [j.uuid]: createdServiceRows[`${j.uuid}:${i.uuid}`]?.quantity ?? 0,
          entry: createdServiceRows?.[`${j.uuid}:${i.uuid}`],
        }),
        {}
      ),
    })) ?? [];

  return {
    serviceColumns,
    serviceRows,
  };
};

type CreateServiceConsultantList = {
  services: EntryFormPage_InitialDataQuery["services"];
  consultants: EntryFormPage_InitialDataQuery["consultants"];
};

export const createServiceConsultantList = ({
  services,
  consultants,
}: CreateServiceConsultantList) => {
  const obj: Record<
    string,
    { consultantId: string; serviceId: string; quantity: 0 }
  > = {};
  for (var i of services) {
    for (var j of consultants) {
      obj[`${i.uuid}:${j.uuid}`] = {
        serviceId: i.uuid,
        consultantId: j.uuid,
        quantity: 0,
      };
    }
  }
  return obj;
};
