import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  DateTime: any;
};

export type Brand = {
  __typename?: 'Brand';
  uuid: Scalars['ID'];
  name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type Consultant = {
  __typename?: 'Consultant';
  uuid: Scalars['ID'];
  active: Scalars['Boolean'];
  name: Scalars['String'];
  premiumCardNumber: Scalars['String'];
  proxyNumber: Scalars['String'];
  cpf: Scalars['String'];
  Premium?: Maybe<Premium>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};


export type Dealership = {
  __typename?: 'Dealership';
  uuid: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  erpCode?: Maybe<Scalars['String']>;
  corporateName?: Maybe<Scalars['String']>;
  fantasyName?: Maybe<Scalars['String']>;
  contractorName?: Maybe<Scalars['String']>;
  contractorEmail?: Maybe<Scalars['String']>;
  contractorDateOfBirth?: Maybe<Scalars['DateTime']>;
  cnpj?: Maybe<Scalars['String']>;
  ie?: Maybe<Scalars['String']>;
  im?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  vhsysId?: Maybe<Scalars['String']>;
  branchesQty?: Maybe<Scalars['Int']>;
  ravmenu?: Maybe<Scalars['Boolean']>;
  scalar?: Maybe<Scalars['Boolean']>;
  ravpesquisa?: Maybe<Scalars['Boolean']>;
  equipmentType?: Maybe<Scalars['String']>;
  equipmentQty?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  neighbourhood?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  invoicingPhone?: Maybe<Scalars['String']>;
  invoicingEmail?: Maybe<Scalars['String']>;
  invoiceXMLEmail?: Maybe<Scalars['String']>;
  corporateName1?: Maybe<Scalars['String']>;
  phone1?: Maybe<Scalars['String']>;
  corporateName2?: Maybe<Scalars['String']>;
  phone2?: Maybe<Scalars['String']>;
  corporateName3?: Maybe<Scalars['String']>;
  phone3?: Maybe<Scalars['String']>;
  sellerName?: Maybe<Scalars['String']>;
  paymentPeriod?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  maxparts?: Maybe<Scalars['Boolean']>;
  vwcaminhoes?: Maybe<Scalars['Boolean']>;
  pix?: Maybe<Scalars['String']>;
  bankNumber?: Maybe<Scalars['String']>;
  bankAgency?: Maybe<Scalars['String']>;
  bankAccount?: Maybe<Scalars['String']>;
  PaymentMethod: PaymentMethods;
  Brands: Array<Brand>;
  Group: Group;
  Seller?: Maybe<Seller>;
  System?: Maybe<System>;
};

export type Entry = {
  __typename?: 'Entry';
  uuid: Scalars['ID'];
  ProductEntries: Array<ProductEntry>;
  ServiceEntries: Array<ServiceEntry>;
  KitEntries: Array<KitEntry>;
  RevisionEntries: Array<RevisionEntry>;
  ProductOrderEntries: Array<ProductOrderEntry>;
  Dealership: Dealership;
  date: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type Group = {
  __typename?: 'Group';
  uuid: Scalars['ID'];
  name: Scalars['String'];
  active: Scalars['Boolean'];
  integrated: Scalars['Boolean'];
  Dealerships: Array<Dealership>;
  PremiumDescription?: Maybe<PremiumDescription>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type Kit = {
  __typename?: 'Kit';
  uuid: Scalars['ID'];
  KitCatalog: KitCatalog;
  systemCode: Scalars['String'];
  registrationPremium: Scalars['Float'];
  premium1: Scalars['Float'];
  premium2: Scalars['Float'];
  premium3: Scalars['Float'];
  premium4: Scalars['Float'];
  premium5: Scalars['Float'];
  premium6: Scalars['Float'];
  Dealerships: Array<Dealership>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type KitCatalog = {
  __typename?: 'KitCatalog';
  uuid: Scalars['ID'];
  name: Scalars['String'];
  code: Scalars['String'];
  hierarchy: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type KitEntry = {
  __typename?: 'KitEntry';
  uuid: Scalars['ID'];
  Kit: Kit;
  Consultant: Consultant;
  quantity: Scalars['Int'];
};

export type KitEntryInput = {
  uuid?: Maybe<Scalars['ID']>;
  kitId: Scalars['ID'];
  consultantId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export enum Manufacturers {
  Original = 'ORIGINAL',
  Ravpro = 'RAVPRO',
  Other = 'OTHER'
}

export type Mutation = {
  __typename?: 'Mutation';
  upsertConsultant?: Maybe<Scalars['Boolean']>;
  deleteConsultant?: Maybe<Scalars['Boolean']>;
  upsertPremiumDescription?: Maybe<Scalars['Boolean']>;
  upsertDealership?: Maybe<Scalars['Boolean']>;
  deleteDealership?: Maybe<Scalars['Boolean']>;
  upsertGroup?: Maybe<Scalars['Boolean']>;
  deleteGroup?: Maybe<Scalars['Boolean']>;
  upsertProductCatalog?: Maybe<Scalars['Boolean']>;
  upsertProduct?: Maybe<Scalars['Boolean']>;
  deleteProduct?: Maybe<Scalars['Boolean']>;
  upsertServiceCatalog?: Maybe<Scalars['Boolean']>;
  upsertService?: Maybe<Scalars['Boolean']>;
  deleteService?: Maybe<Scalars['Boolean']>;
  upsertEntry?: Maybe<Scalars['Boolean']>;
  deleteEntry?: Maybe<Scalars['Boolean']>;
  setUserDealershipPermissions?: Maybe<Scalars['Boolean']>;
  upsertKit?: Maybe<Scalars['Boolean']>;
  deleteKit?: Maybe<Scalars['Boolean']>;
  updateUser?: Maybe<Scalars['Boolean']>;
  changePassword?: Maybe<Scalars['Boolean']>;
  upsertSeller?: Maybe<Scalars['Boolean']>;
  deleteSeller?: Maybe<Scalars['Boolean']>;
};


export type MutationUpsertConsultantArgs = {
  uuid?: Maybe<Scalars['ID']>;
  active?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  premiumCardNumber?: Maybe<Scalars['String']>;
  proxyNumber?: Maybe<Scalars['String']>;
  Premium?: Maybe<Scalars['String']>;
  dealershipId: Scalars['ID'];
};


export type MutationDeleteConsultantArgs = {
  uuid: Scalars['ID'];
};


export type MutationUpsertPremiumDescriptionArgs = {
  premium1?: Maybe<Scalars['String']>;
  premium2?: Maybe<Scalars['String']>;
  premium3?: Maybe<Scalars['String']>;
  premium4?: Maybe<Scalars['String']>;
  premium5?: Maybe<Scalars['String']>;
  premium6?: Maybe<Scalars['String']>;
  groupId: Scalars['ID'];
};


export type MutationUpsertDealershipArgs = {
  uuid?: Maybe<Scalars['ID']>;
  corporateName?: Maybe<Scalars['String']>;
  fantasyName?: Maybe<Scalars['String']>;
  contractorDateOfBirth?: Maybe<Scalars['DateTime']>;
  contractorName?: Maybe<Scalars['String']>;
  contractorEmail?: Maybe<Scalars['String']>;
  cnpj?: Maybe<Scalars['String']>;
  ie?: Maybe<Scalars['String']>;
  im?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  erpCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  equipmentType?: Maybe<Scalars['String']>;
  equipmentQty?: Maybe<Scalars['Int']>;
  address?: Maybe<Scalars['String']>;
  neighbourhood?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  invoicingPhone?: Maybe<Scalars['String']>;
  invoicingEmail?: Maybe<Scalars['String']>;
  invoiceXMLEmail?: Maybe<Scalars['String']>;
  pix?: Maybe<Scalars['String']>;
  bankNumber?: Maybe<Scalars['String']>;
  bankAgency?: Maybe<Scalars['String']>;
  bankAccount?: Maybe<Scalars['String']>;
  PaymentMethod?: Maybe<PaymentMethods>;
  corporateName1?: Maybe<Scalars['String']>;
  phone1?: Maybe<Scalars['String']>;
  corporateName2?: Maybe<Scalars['String']>;
  phone2?: Maybe<Scalars['String']>;
  corporateName3?: Maybe<Scalars['String']>;
  phone3?: Maybe<Scalars['String']>;
  sellerName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  branchesQty?: Maybe<Scalars['Int']>;
  paymentPeriod?: Maybe<Scalars['Int']>;
  ravmenu?: Maybe<Scalars['Boolean']>;
  scalar?: Maybe<Scalars['Boolean']>;
  ravpesquisa?: Maybe<Scalars['Boolean']>;
  maxparts?: Maybe<Scalars['Boolean']>;
  vwcaminhoes?: Maybe<Scalars['Boolean']>;
  groupId: Scalars['ID'];
  systemId?: Maybe<Scalars['ID']>;
  sellerId?: Maybe<Scalars['ID']>;
  vhsysId?: Maybe<Scalars['String']>;
  brandIds: Array<Scalars['ID']>;
};


export type MutationDeleteDealershipArgs = {
  uuid: Scalars['ID'];
};


export type MutationUpsertGroupArgs = {
  uuid?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  integrated?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteGroupArgs = {
  uuid?: Maybe<Scalars['ID']>;
};


export type MutationUpsertProductCatalogArgs = {
  uuid?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  Manufacturer?: Maybe<Manufacturers>;
};


export type MutationUpsertProductArgs = {
  uuid?: Maybe<Scalars['ID']>;
  active?: Maybe<Scalars['Boolean']>;
  productCatalogId?: Maybe<Scalars['ID']>;
  productCode?: Maybe<Scalars['String']>;
  serviceCode?: Maybe<Scalars['String']>;
  kitId?: Maybe<Scalars['ID']>;
  serviceSalePrice?: Maybe<Scalars['Float']>;
  salePrice?: Maybe<Scalars['Float']>;
  premium1?: Maybe<Scalars['Float']>;
  premium2?: Maybe<Scalars['Float']>;
  premium3?: Maybe<Scalars['Float']>;
  premium4?: Maybe<Scalars['Float']>;
  premium5?: Maybe<Scalars['Float']>;
  premium6?: Maybe<Scalars['Float']>;
  dealershipIds: Array<Scalars['ID']>;
  costPrice?: Maybe<Scalars['Float']>;
  registrationPremium?: Maybe<Scalars['Float']>;
  PremiumCalculationType?: Maybe<Scalars['String']>;
};


export type MutationDeleteProductArgs = {
  uuid: Scalars['ID'];
};


export type MutationUpsertServiceCatalogArgs = {
  uuid?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['ID']>;
};


export type MutationUpsertServiceArgs = {
  uuid?: Maybe<Scalars['ID']>;
  serviceCatalogId?: Maybe<Scalars['ID']>;
  ServiceCatalog?: Maybe<ServiceCatalogInput>;
  systemCode?: Maybe<Scalars['String']>;
  kitId?: Maybe<Scalars['ID']>;
  salePrice?: Maybe<Scalars['Float']>;
  registrationPremium?: Maybe<Scalars['Float']>;
  premium1?: Maybe<Scalars['Float']>;
  premium2?: Maybe<Scalars['Float']>;
  premium3?: Maybe<Scalars['Float']>;
  premium4?: Maybe<Scalars['Float']>;
  premium5?: Maybe<Scalars['Float']>;
  premium6?: Maybe<Scalars['Float']>;
  referenceId?: Maybe<Scalars['ID']>;
  dealershipIds: Array<Scalars['ID']>;
};


export type MutationDeleteServiceArgs = {
  uuid: Scalars['ID'];
};


export type MutationUpsertEntryArgs = {
  uuid?: Maybe<Scalars['ID']>;
  dealershipId: Scalars['ID'];
  PremiumCalculationType: PremiumCalculationType;
  ProductEntries: Array<ProductEntryInput>;
  ServiceEntries: Array<ServiceEntryInput>;
  RevisionEntries: Array<RevisionEntryInput>;
  KitEntries: Array<KitEntryInput>;
  ProductOrderEntries: Array<ProductOrderEntryInput>;
  date: Scalars['DateTime'];
};


export type MutationDeleteEntryArgs = {
  uuid: Scalars['ID'];
};


export type MutationSetUserDealershipPermissionsArgs = {
  email: Scalars['String'];
  dealershipIds: Array<Scalars['ID']>;
};


export type MutationUpsertKitArgs = {
  uuid?: Maybe<Scalars['ID']>;
  systemCode?: Maybe<Scalars['String']>;
  kitCatalogId: Scalars['ID'];
  registrationPremium?: Maybe<Scalars['Float']>;
  premium1?: Maybe<Scalars['Float']>;
  premium2?: Maybe<Scalars['Float']>;
  premium3?: Maybe<Scalars['Float']>;
  premium4?: Maybe<Scalars['Float']>;
  premium5?: Maybe<Scalars['Float']>;
  premium6?: Maybe<Scalars['Float']>;
  dealershipIds: Array<Scalars['ID']>;
};


export type MutationDeleteKitArgs = {
  uuid: Scalars['ID'];
};


export type MutationUpdateUserArgs = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  avatarBase64?: Maybe<Scalars['String']>;
};


export type MutationChangePasswordArgs = {
  newPassword?: Maybe<Scalars['String']>;
};


export type MutationUpsertSellerArgs = {
  uuid?: Maybe<Scalars['ID']>;
  active?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  erpCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  sellerId?: Maybe<Scalars['ID']>;
};


export type MutationDeleteSellerArgs = {
  uuid: Scalars['ID'];
};

export enum PaymentMethods {
  Pix = 'PIX',
  Card = 'CARD',
  Transfer = 'TRANSFER'
}

export enum Premium {
  Premium_1 = 'PREMIUM_1',
  Premium_2 = 'PREMIUM_2',
  Premium_3 = 'PREMIUM_3',
  Premium_4 = 'PREMIUM_4',
  Premium_5 = 'PREMIUM_5',
  Premium_6 = 'PREMIUM_6'
}

export enum PremiumCalculationType {
  Sale = 'SALE',
  OrderVhsys = 'ORDER_VHSYS'
}

export type PremiumDescription = {
  __typename?: 'PremiumDescription';
  uuid: Scalars['ID'];
  premium1?: Maybe<Scalars['String']>;
  premium2?: Maybe<Scalars['String']>;
  premium3?: Maybe<Scalars['String']>;
  premium4?: Maybe<Scalars['String']>;
  premium5?: Maybe<Scalars['String']>;
  premium6?: Maybe<Scalars['String']>;
  Group: Group;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type Product = {
  __typename?: 'Product';
  uuid: Scalars['ID'];
  active: Scalars['Boolean'];
  ProductCatalog?: Maybe<ProductCatalog>;
  productCode: Scalars['String'];
  PremiumCalculationType: PremiumCalculationType;
  serviceCode: Scalars['String'];
  Kit?: Maybe<Kit>;
  costPrice: Scalars['Float'];
  registrationPremium: Scalars['Float'];
  serviceSalePrice: Scalars['Float'];
  salePrice: Scalars['Float'];
  premium1: Scalars['Float'];
  premium2: Scalars['Float'];
  premium3: Scalars['Float'];
  premium4: Scalars['Float'];
  premium5: Scalars['Float'];
  premium6: Scalars['Float'];
  Dealerships: Array<Dealership>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ProductCatalog = {
  __typename?: 'ProductCatalog';
  uuid: Scalars['ID'];
  name: Scalars['String'];
  code: Scalars['String'];
  Manufacturer: Manufacturers;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ProductCatalogInput = {
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  defaultCostPrice?: Maybe<Scalars['Float']>;
  defaultRegistrationPremium?: Maybe<Scalars['Float']>;
  Manufacturer: Manufacturers;
};

export type ProductEntry = {
  __typename?: 'ProductEntry';
  uuid: Scalars['ID'];
  Product: Product;
  Consultant: Consultant;
  quantity: Scalars['Int'];
};

export type ProductEntryInput = {
  uuid?: Maybe<Scalars['ID']>;
  productId: Scalars['ID'];
  consultantId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type ProductOrderEntry = {
  __typename?: 'ProductOrderEntry';
  uuid: Scalars['ID'];
  Product: Product;
  quantity: Scalars['Int'];
};

export type ProductOrderEntryInput = {
  uuid?: Maybe<Scalars['ID']>;
  productId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  consultants: Array<Consultant>;
  consultant: Consultant;
  dealerships: Array<Dealership>;
  dealership: Dealership;
  group: Group;
  groups: Array<Group>;
  brands: Array<Brand>;
  systems: Array<System>;
  productCatalogs: Array<ProductCatalog>;
  productCatalog: ProductCatalog;
  products: Array<Product>;
  product: Product;
  serviceCatalogs: Array<ServiceCatalog>;
  services: Array<Service>;
  service: Service;
  entries: Array<Entry>;
  entry: Entry;
  lastEntryDate?: Maybe<Scalars['DateTime']>;
  userDealershipPermissions: Array<UserDealershipPermission>;
  userRoles: Array<UserRole>;
  kitCatalogs: Array<KitCatalog>;
  kits: Array<Kit>;
  kit: Kit;
  sellers: Array<Seller>;
  seller: Seller;
};


export type QueryConsultantsArgs = {
  dealershipId: Scalars['ID'];
  hasPremium?: Maybe<Scalars['Boolean']>;
};


export type QueryConsultantArgs = {
  uuid: Scalars['ID'];
};


export type QueryDealershipsArgs = {
  groupId: Scalars['ID'];
};


export type QueryDealershipArgs = {
  uuid: Scalars['ID'];
};


export type QueryGroupArgs = {
  uuid: Scalars['ID'];
};


export type QueryProductCatalogsArgs = {
  Manufacturer?: Maybe<Manufacturers>;
};


export type QueryProductCatalogArgs = {
  uuid?: Maybe<Scalars['ID']>;
};


export type QueryProductsArgs = {
  active?: Maybe<Scalars['Boolean']>;
  groupId?: Maybe<Scalars['ID']>;
  dealershipId?: Maybe<Scalars['ID']>;
  PremiumCalculationType?: Maybe<PremiumCalculationType>;
  Manufacturer?: Maybe<Manufacturers>;
};


export type QueryProductArgs = {
  uuid: Scalars['ID'];
};


export type QueryServicesArgs = {
  groupId?: Maybe<Scalars['ID']>;
  dealershipId?: Maybe<Scalars['ID']>;
};


export type QueryServiceArgs = {
  uuid: Scalars['ID'];
};


export type QueryEntriesArgs = {
  dealershipId: Scalars['ID'];
  PremiumCalculationType: PremiumCalculationType;
};


export type QueryEntryArgs = {
  uuid: Scalars['ID'];
};


export type QueryLastEntryDateArgs = {
  dealershipId: Scalars['ID'];
};


export type QueryUserDealershipPermissionsArgs = {
  email: Scalars['String'];
};


export type QueryUserRolesArgs = {
  email: Scalars['String'];
};


export type QueryKitsArgs = {
  groupId?: Maybe<Scalars['ID']>;
  dealershipId?: Maybe<Scalars['ID']>;
};


export type QueryKitArgs = {
  uuid: Scalars['ID'];
};


export type QuerySellerArgs = {
  uuid: Scalars['ID'];
};

export type RevisionEntry = {
  __typename?: 'RevisionEntry';
  uuid: Scalars['ID'];
  Consultant: Consultant;
  quantity: Scalars['Int'];
};

export type RevisionEntryInput = {
  uuid?: Maybe<Scalars['ID']>;
  consultantId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type Seller = {
  __typename?: 'Seller';
  uuid: Scalars['ID'];
  active: Scalars['Boolean'];
  name: Scalars['String'];
  cpf: Scalars['String'];
  email: Scalars['String'];
  erpCode: Scalars['String'];
  Manager?: Maybe<Seller>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type Service = {
  __typename?: 'Service';
  uuid: Scalars['ID'];
  ServiceCatalog?: Maybe<ServiceCatalog>;
  Kit?: Maybe<Kit>;
  systemCode: Scalars['String'];
  salePrice: Scalars['Float'];
  registrationPremium: Scalars['Float'];
  premium1: Scalars['Float'];
  premium2: Scalars['Float'];
  premium3: Scalars['Float'];
  premium4: Scalars['Float'];
  premium5: Scalars['Float'];
  premium6: Scalars['Float'];
  Dealerships: Array<Dealership>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ServiceCatalog = {
  __typename?: 'ServiceCatalog';
  uuid: Scalars['ID'];
  name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ServiceCatalogInput = {
  name: Scalars['String'];
};

export type ServiceEntry = {
  __typename?: 'ServiceEntry';
  uuid: Scalars['ID'];
  Service: Service;
  Consultant: Consultant;
  quantity: Scalars['Int'];
};

export type ServiceEntryInput = {
  uuid?: Maybe<Scalars['ID']>;
  serviceId: Scalars['ID'];
  consultantId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type System = {
  __typename?: 'System';
  uuid: Scalars['ID'];
  name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserDealershipPermission = {
  __typename?: 'UserDealershipPermission';
  uuid: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  email: Scalars['String'];
  Dealership: Dealership;
};

export enum UserRole {
  RavproAdmin = 'RAVPRO_ADMIN',
  RavproConsultant = 'RAVPRO_CONSULTANT'
}

export type UserRolePermission = {
  __typename?: 'UserRolePermission';
  uuid: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  email: Scalars['String'];
  Roles: Array<UserRole>;
};

export type AuthContext_UserRolesQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type AuthContext_UserRolesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'userRoles'>
);

export type PasswordChangePage_ChangePasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
}>;


export type PasswordChangePage_ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePassword'>
);

export type ProfilePage_UpdateUserMutationVariables = Exact<{
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  avatarBase64?: Maybe<Scalars['String']>;
}>;


export type ProfilePage_UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUser'>
);

export type TenantContext_GroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type TenantContext_GroupsQuery = (
  { __typename?: 'Query' }
  & { groups: Array<(
    { __typename?: 'Group' }
    & Pick<Group, 'uuid' | 'name' | 'integrated' | 'active'>
    & { Dealerships: Array<(
      { __typename?: 'Dealership' }
      & Pick<Dealership, 'uuid' | 'fantasyName'>
    )> }
  )> }
);

export type ConsultantsFormPage_UpsertConsultantMutationVariables = Exact<{
  uuid?: Maybe<Scalars['ID']>;
  active?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  premiumCardNumber?: Maybe<Scalars['String']>;
  proxyNumber?: Maybe<Scalars['String']>;
  Premium?: Maybe<Scalars['String']>;
  dealershipId: Scalars['ID'];
}>;


export type ConsultantsFormPage_UpsertConsultantMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertConsultant'>
);

export type ConsultantsFormPage_ConsultantQueryVariables = Exact<{
  uuid: Scalars['ID'];
}>;


export type ConsultantsFormPage_ConsultantQuery = (
  { __typename?: 'Query' }
  & { consultant: (
    { __typename?: 'Consultant' }
    & Pick<Consultant, 'active' | 'uuid' | 'name' | 'cpf' | 'premiumCardNumber' | 'proxyNumber' | 'Premium'>
  ) }
);

export type ConsultantsFormPage_GroupQueryVariables = Exact<{
  uuid: Scalars['ID'];
}>;


export type ConsultantsFormPage_GroupQuery = (
  { __typename?: 'Query' }
  & { group: (
    { __typename?: 'Group' }
    & Pick<Group, 'uuid'>
    & { PremiumDescription?: Maybe<(
      { __typename?: 'PremiumDescription' }
      & Pick<PremiumDescription, 'premium1' | 'premium2' | 'premium3' | 'premium4' | 'premium5' | 'premium6'>
    )> }
  ) }
);

export type ConsultantsTablePage_ConsultantsQueryVariables = Exact<{
  dealershipId: Scalars['ID'];
}>;


export type ConsultantsTablePage_ConsultantsQuery = (
  { __typename?: 'Query' }
  & { consultants: Array<(
    { __typename?: 'Consultant' }
    & Pick<Consultant, 'uuid' | 'name'>
  )> }
);

export type ConsultantsTablePage_DeleteConsultantMutationVariables = Exact<{
  uuid: Scalars['ID'];
}>;


export type ConsultantsTablePage_DeleteConsultantMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteConsultant'>
);

export type DealershipFormPage_UpsertDealershipMutationVariables = Exact<{
  uuid?: Maybe<Scalars['ID']>;
  corporateName?: Maybe<Scalars['String']>;
  fantasyName?: Maybe<Scalars['String']>;
  contractorDateOfBirth?: Maybe<Scalars['DateTime']>;
  contractorName?: Maybe<Scalars['String']>;
  contractorEmail?: Maybe<Scalars['String']>;
  cnpj?: Maybe<Scalars['String']>;
  erpCode?: Maybe<Scalars['String']>;
  ie?: Maybe<Scalars['String']>;
  im?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  equipmentType?: Maybe<Scalars['String']>;
  equipmentQty?: Maybe<Scalars['Int']>;
  address?: Maybe<Scalars['String']>;
  neighbourhood?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  invoicingPhone?: Maybe<Scalars['String']>;
  invoicingEmail?: Maybe<Scalars['String']>;
  invoiceXMLEmail?: Maybe<Scalars['String']>;
  corporateName1?: Maybe<Scalars['String']>;
  phone1?: Maybe<Scalars['String']>;
  corporateName2?: Maybe<Scalars['String']>;
  phone2?: Maybe<Scalars['String']>;
  corporateName3?: Maybe<Scalars['String']>;
  phone3?: Maybe<Scalars['String']>;
  sellerName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  branchesQty?: Maybe<Scalars['Int']>;
  paymentPeriod?: Maybe<Scalars['Int']>;
  ravmenu?: Maybe<Scalars['Boolean']>;
  scalar?: Maybe<Scalars['Boolean']>;
  ravpesquisa?: Maybe<Scalars['Boolean']>;
  maxparts?: Maybe<Scalars['Boolean']>;
  vwcaminhoes?: Maybe<Scalars['Boolean']>;
  groupId: Scalars['ID'];
  systemId?: Maybe<Scalars['ID']>;
  brandIds: Array<Scalars['ID']> | Scalars['ID'];
  pix?: Maybe<Scalars['String']>;
  bankNumber?: Maybe<Scalars['String']>;
  bankAgency?: Maybe<Scalars['String']>;
  bankAccount?: Maybe<Scalars['String']>;
  vhsysId?: Maybe<Scalars['String']>;
  PaymentMethod?: Maybe<PaymentMethods>;
  sellerId?: Maybe<Scalars['ID']>;
}>;


export type DealershipFormPage_UpsertDealershipMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertDealership'>
);

export type DealershipFormPage_DealershipQueryVariables = Exact<{
  uuid: Scalars['ID'];
}>;


export type DealershipFormPage_DealershipQuery = (
  { __typename?: 'Query' }
  & { dealership: (
    { __typename?: 'Dealership' }
    & Pick<Dealership, 'uuid' | 'corporateName' | 'fantasyName' | 'contractorDateOfBirth' | 'contractorName' | 'contractorEmail' | 'cnpj' | 'ie' | 'im' | 'website' | 'erpCode' | 'phone' | 'equipmentType' | 'equipmentQty' | 'address' | 'neighbourhood' | 'city' | 'state' | 'zipcode' | 'invoicingPhone' | 'invoicingEmail' | 'invoiceXMLEmail' | 'corporateName1' | 'phone1' | 'corporateName2' | 'phone2' | 'corporateName3' | 'phone3' | 'sellerName' | 'notes' | 'branchesQty' | 'paymentPeriod' | 'ravmenu' | 'scalar' | 'ravpesquisa' | 'maxparts' | 'vwcaminhoes' | 'vhsysId' | 'pix' | 'bankNumber' | 'bankAgency' | 'bankAccount' | 'PaymentMethod'>
    & { Group: (
      { __typename?: 'Group' }
      & Pick<Group, 'uuid'>
    ), System?: Maybe<(
      { __typename?: 'System' }
      & Pick<System, 'uuid'>
    )>, Brands: Array<(
      { __typename?: 'Brand' }
      & Pick<Brand, 'uuid'>
    )>, Seller?: Maybe<(
      { __typename?: 'Seller' }
      & Pick<Seller, 'uuid'>
    )> }
  ) }
);

export type DealershipFormPage_Initial_DataQueryVariables = Exact<{ [key: string]: never; }>;


export type DealershipFormPage_Initial_DataQuery = (
  { __typename?: 'Query' }
  & { systems: Array<(
    { __typename?: 'System' }
    & Pick<System, 'uuid' | 'name'>
  )>, brands: Array<(
    { __typename?: 'Brand' }
    & Pick<Brand, 'uuid' | 'name'>
  )>, sellers: Array<(
    { __typename?: 'Seller' }
    & Pick<Seller, 'uuid' | 'name'>
  )> }
);

export type DealershipTablePage_DealershipsQueryVariables = Exact<{
  groupId: Scalars['ID'];
}>;


export type DealershipTablePage_DealershipsQuery = (
  { __typename?: 'Query' }
  & { dealerships: Array<(
    { __typename?: 'Dealership' }
    & Pick<Dealership, 'uuid' | 'fantasyName'>
    & { Group: (
      { __typename?: 'Group' }
      & Pick<Group, 'name'>
    ) }
  )> }
);

export type DealershipTablePage_DeleteDealershipMutationVariables = Exact<{
  uuid: Scalars['ID'];
}>;


export type DealershipTablePage_DeleteDealershipMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDealership'>
);

export type EntryFormPage_UpsertEntryMutationVariables = Exact<{
  uuid?: Maybe<Scalars['ID']>;
  dealershipId: Scalars['ID'];
  ProductEntries: Array<ProductEntryInput> | ProductEntryInput;
  ServiceEntries: Array<ServiceEntryInput> | ServiceEntryInput;
  RevisionEntries: Array<RevisionEntryInput> | RevisionEntryInput;
  KitEntries: Array<KitEntryInput> | KitEntryInput;
  date: Scalars['DateTime'];
  PremiumCalculationType: PremiumCalculationType;
}>;


export type EntryFormPage_UpsertEntryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertEntry'>
);

export type EntryFormPage_EntryQueryVariables = Exact<{
  uuid: Scalars['ID'];
}>;


export type EntryFormPage_EntryQuery = (
  { __typename?: 'Query' }
  & { entry: (
    { __typename?: 'Entry' }
    & Pick<Entry, 'uuid' | 'date'>
    & { ProductEntries: Array<(
      { __typename?: 'ProductEntry' }
      & Pick<ProductEntry, 'uuid' | 'quantity'>
      & { Product: (
        { __typename?: 'Product' }
        & Pick<Product, 'uuid' | 'productCode'>
        & { ProductCatalog?: Maybe<(
          { __typename?: 'ProductCatalog' }
          & Pick<ProductCatalog, 'uuid' | 'name' | 'code'>
        )> }
      ), Consultant: (
        { __typename?: 'Consultant' }
        & Pick<Consultant, 'uuid'>
      ) }
    )>, ServiceEntries: Array<(
      { __typename?: 'ServiceEntry' }
      & Pick<ServiceEntry, 'uuid' | 'quantity'>
      & { Service: (
        { __typename?: 'Service' }
        & Pick<Service, 'uuid'>
        & { ServiceCatalog?: Maybe<(
          { __typename?: 'ServiceCatalog' }
          & Pick<ServiceCatalog, 'uuid' | 'name'>
        )> }
      ), Consultant: (
        { __typename?: 'Consultant' }
        & Pick<Consultant, 'uuid'>
      ) }
    )>, RevisionEntries: Array<(
      { __typename?: 'RevisionEntry' }
      & Pick<RevisionEntry, 'uuid' | 'quantity'>
      & { Consultant: (
        { __typename?: 'Consultant' }
        & Pick<Consultant, 'uuid'>
      ) }
    )>, KitEntries: Array<(
      { __typename?: 'KitEntry' }
      & Pick<KitEntry, 'uuid' | 'quantity'>
      & { Kit: (
        { __typename?: 'Kit' }
        & Pick<Kit, 'uuid'>
        & { KitCatalog: (
          { __typename?: 'KitCatalog' }
          & Pick<KitCatalog, 'uuid' | 'name'>
        ) }
      ), Consultant: (
        { __typename?: 'Consultant' }
        & Pick<Consultant, 'uuid'>
      ) }
    )> }
  ) }
);

export type EntryFormPage_InitialDataQueryVariables = Exact<{
  dealershipId: Scalars['ID'];
  PremiumCalculationType?: Maybe<PremiumCalculationType>;
}>;


export type EntryFormPage_InitialDataQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'lastEntryDate'>
  & { consultants: Array<(
    { __typename?: 'Consultant' }
    & Pick<Consultant, 'uuid' | 'name' | 'active'>
  )>, products: Array<(
    { __typename?: 'Product' }
    & Pick<Product, 'uuid' | 'productCode'>
    & { ProductCatalog?: Maybe<(
      { __typename?: 'ProductCatalog' }
      & Pick<ProductCatalog, 'uuid' | 'name' | 'code'>
    )> }
  )>, services: Array<(
    { __typename?: 'Service' }
    & Pick<Service, 'uuid'>
    & { ServiceCatalog?: Maybe<(
      { __typename?: 'ServiceCatalog' }
      & Pick<ServiceCatalog, 'uuid' | 'name'>
    )> }
  )>, kits: Array<(
    { __typename?: 'Kit' }
    & Pick<Kit, 'uuid'>
    & { KitCatalog: (
      { __typename?: 'KitCatalog' }
      & Pick<KitCatalog, 'uuid' | 'name'>
    ) }
  )> }
);

export type OrderEntryFormPage_UpsertEntryMutationVariables = Exact<{
  uuid?: Maybe<Scalars['ID']>;
  dealershipId: Scalars['ID'];
  ProductOrderEntries: Array<ProductOrderEntryInput> | ProductOrderEntryInput;
  date: Scalars['DateTime'];
}>;


export type OrderEntryFormPage_UpsertEntryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertEntry'>
);

export type OrderEntryFormPage_EntryQueryVariables = Exact<{
  uuid: Scalars['ID'];
}>;


export type OrderEntryFormPage_EntryQuery = (
  { __typename?: 'Query' }
  & { entry: (
    { __typename?: 'Entry' }
    & Pick<Entry, 'uuid' | 'date'>
    & { ProductOrderEntries: Array<(
      { __typename?: 'ProductOrderEntry' }
      & Pick<ProductOrderEntry, 'uuid' | 'quantity'>
      & { Product: (
        { __typename?: 'Product' }
        & Pick<Product, 'uuid' | 'productCode'>
        & { ProductCatalog?: Maybe<(
          { __typename?: 'ProductCatalog' }
          & Pick<ProductCatalog, 'uuid' | 'name' | 'code'>
        )> }
      ) }
    )> }
  ) }
);

export type OrderEntryFormPage_InitialDataQueryVariables = Exact<{
  dealershipId: Scalars['ID'];
}>;


export type OrderEntryFormPage_InitialDataQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'lastEntryDate'>
  & { products: Array<(
    { __typename?: 'Product' }
    & Pick<Product, 'uuid' | 'productCode'>
    & { ProductCatalog?: Maybe<(
      { __typename?: 'ProductCatalog' }
      & Pick<ProductCatalog, 'uuid' | 'name' | 'code'>
    )> }
  )> }
);

export type EntryTablePage_EntriesQueryVariables = Exact<{
  dealershipId: Scalars['ID'];
  PremiumCalculationType: PremiumCalculationType;
}>;


export type EntryTablePage_EntriesQuery = (
  { __typename?: 'Query' }
  & { entries: Array<(
    { __typename?: 'Entry' }
    & Pick<Entry, 'uuid' | 'date' | 'createdAt' | 'updatedAt'>
    & { Dealership: (
      { __typename?: 'Dealership' }
      & Pick<Dealership, 'fantasyName'>
    ) }
  )> }
);

export type EntryTablePage_DeleteEntryMutationVariables = Exact<{
  uuid: Scalars['ID'];
}>;


export type EntryTablePage_DeleteEntryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteEntry'>
);

export type GroupFormPage_UpsertGroupMutationVariables = Exact<{
  uuid?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  integrated: Scalars['Boolean'];
  active: Scalars['Boolean'];
}>;


export type GroupFormPage_UpsertGroupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertGroup'>
);

export type GroupFormPage_GroupQueryVariables = Exact<{
  uuid: Scalars['ID'];
}>;


export type GroupFormPage_GroupQuery = (
  { __typename?: 'Query' }
  & { group: (
    { __typename?: 'Group' }
    & Pick<Group, 'uuid' | 'name' | 'integrated' | 'active'>
  ) }
);

export type GroupTablePage_GroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GroupTablePage_GroupsQuery = (
  { __typename?: 'Query' }
  & { groups: Array<(
    { __typename?: 'Group' }
    & Pick<Group, 'uuid' | 'name'>
  )> }
);

export type GroupTablePage_DeleteGroupMutationVariables = Exact<{
  uuid: Scalars['ID'];
}>;


export type GroupTablePage_DeleteGroupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteService'>
);

export type KitFormPage_UpsertKitMutationVariables = Exact<{
  uuid?: Maybe<Scalars['ID']>;
  kitCatalogId: Scalars['ID'];
  systemCode?: Maybe<Scalars['String']>;
  registrationPremium?: Maybe<Scalars['Float']>;
  premium1?: Maybe<Scalars['Float']>;
  premium2?: Maybe<Scalars['Float']>;
  premium3?: Maybe<Scalars['Float']>;
  premium4?: Maybe<Scalars['Float']>;
  premium5?: Maybe<Scalars['Float']>;
  premium6?: Maybe<Scalars['Float']>;
  dealershipIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type KitFormPage_UpsertKitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertKit'>
);

export type KitFormPage_KitQueryVariables = Exact<{
  uuid: Scalars['ID'];
}>;


export type KitFormPage_KitQuery = (
  { __typename?: 'Query' }
  & { kit: (
    { __typename?: 'Kit' }
    & Pick<Kit, 'uuid' | 'systemCode' | 'registrationPremium' | 'premium1' | 'premium2' | 'premium3' | 'premium4' | 'premium5' | 'premium6'>
    & { KitCatalog: (
      { __typename?: 'KitCatalog' }
      & Pick<KitCatalog, 'uuid' | 'name' | 'hierarchy'>
    ), Dealerships: Array<(
      { __typename?: 'Dealership' }
      & Pick<Dealership, 'uuid' | 'fantasyName'>
    )> }
  ) }
);

export type KitFormPage_InitialDataQueryVariables = Exact<{
  groupId: Scalars['ID'];
}>;


export type KitFormPage_InitialDataQuery = (
  { __typename?: 'Query' }
  & { group: (
    { __typename?: 'Group' }
    & Pick<Group, 'uuid'>
    & { PremiumDescription?: Maybe<(
      { __typename?: 'PremiumDescription' }
      & Pick<PremiumDescription, 'premium1' | 'premium2' | 'premium3' | 'premium4' | 'premium5' | 'premium6'>
    )>, Dealerships: Array<(
      { __typename?: 'Dealership' }
      & Pick<Dealership, 'uuid' | 'fantasyName'>
    )> }
  ), kitCatalogs: Array<(
    { __typename?: 'KitCatalog' }
    & Pick<KitCatalog, 'uuid' | 'hierarchy' | 'name'>
  )> }
);

export type KitTablePage_KitsQueryVariables = Exact<{
  groupId?: Maybe<Scalars['ID']>;
  dealershipId?: Maybe<Scalars['ID']>;
}>;


export type KitTablePage_KitsQuery = (
  { __typename?: 'Query' }
  & { kits: Array<(
    { __typename?: 'Kit' }
    & Pick<Kit, 'uuid' | 'systemCode'>
    & { KitCatalog: (
      { __typename?: 'KitCatalog' }
      & Pick<KitCatalog, 'uuid' | 'name'>
    ) }
  )> }
);

export type KitTablePage_DeleteKitMutationVariables = Exact<{
  uuid: Scalars['ID'];
}>;


export type KitTablePage_DeleteKitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteKit'>
);

export type PremiumDescriptionFormPage_UpsertPremiumDescriptionMutationVariables = Exact<{
  groupId: Scalars['ID'];
  premium1?: Maybe<Scalars['String']>;
  premium2?: Maybe<Scalars['String']>;
  premium3?: Maybe<Scalars['String']>;
  premium4?: Maybe<Scalars['String']>;
  premium5?: Maybe<Scalars['String']>;
  premium6?: Maybe<Scalars['String']>;
}>;


export type PremiumDescriptionFormPage_UpsertPremiumDescriptionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertPremiumDescription'>
);

export type PremiumDescriptionFormPage_GroupQueryVariables = Exact<{
  uuid: Scalars['ID'];
}>;


export type PremiumDescriptionFormPage_GroupQuery = (
  { __typename?: 'Query' }
  & { group: (
    { __typename?: 'Group' }
    & Pick<Group, 'uuid'>
    & { PremiumDescription?: Maybe<(
      { __typename?: 'PremiumDescription' }
      & Pick<PremiumDescription, 'premium1' | 'premium2' | 'premium3' | 'premium4' | 'premium5' | 'premium6'>
    )> }
  ) }
);

export type ProductCatalogFormPage_UpsertProductCatalogMutationVariables = Exact<{
  uuid?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  Manufacturer?: Maybe<Manufacturers>;
}>;


export type ProductCatalogFormPage_UpsertProductCatalogMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertProductCatalog'>
);

export type ProductCatalogFormPage_ProductCatalogQueryVariables = Exact<{
  uuid: Scalars['ID'];
}>;


export type ProductCatalogFormPage_ProductCatalogQuery = (
  { __typename?: 'Query' }
  & { productCatalog: (
    { __typename?: 'ProductCatalog' }
    & Pick<ProductCatalog, 'uuid' | 'name' | 'Manufacturer' | 'code'>
  ) }
);

export type ProductCatalogsPage_ProductCatalogsQueryVariables = Exact<{
  Manufacturer?: Maybe<Manufacturers>;
}>;


export type ProductCatalogsPage_ProductCatalogsQuery = (
  { __typename?: 'Query' }
  & { productCatalogs: Array<(
    { __typename?: 'ProductCatalog' }
    & Pick<ProductCatalog, 'uuid' | 'name' | 'Manufacturer' | 'code'>
  )> }
);

export type ProductFormPage_UpsertProductMutationVariables = Exact<{
  uuid?: Maybe<Scalars['ID']>;
  active?: Maybe<Scalars['Boolean']>;
  productCatalogId?: Maybe<Scalars['ID']>;
  productCode?: Maybe<Scalars['String']>;
  serviceCode?: Maybe<Scalars['String']>;
  kitId?: Maybe<Scalars['ID']>;
  salePrice?: Maybe<Scalars['Float']>;
  serviceSalePrice?: Maybe<Scalars['Float']>;
  premium1?: Maybe<Scalars['Float']>;
  premium2?: Maybe<Scalars['Float']>;
  premium3?: Maybe<Scalars['Float']>;
  premium4?: Maybe<Scalars['Float']>;
  premium5?: Maybe<Scalars['Float']>;
  premium6?: Maybe<Scalars['Float']>;
  costPrice?: Maybe<Scalars['Float']>;
  registrationPremium?: Maybe<Scalars['Float']>;
  dealershipIds: Array<Scalars['ID']> | Scalars['ID'];
  PremiumCalculationType: Scalars['String'];
}>;


export type ProductFormPage_UpsertProductMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertProduct'>
);

export type ProductFormPage_ProductQueryVariables = Exact<{
  uuid: Scalars['ID'];
}>;


export type ProductFormPage_ProductQuery = (
  { __typename?: 'Query' }
  & { product: (
    { __typename?: 'Product' }
    & Pick<Product, 'uuid' | 'active' | 'costPrice' | 'registrationPremium' | 'productCode' | 'serviceCode' | 'serviceSalePrice' | 'salePrice' | 'premium1' | 'premium2' | 'premium3' | 'premium4' | 'premium5' | 'premium6' | 'PremiumCalculationType'>
    & { ProductCatalog?: Maybe<(
      { __typename?: 'ProductCatalog' }
      & Pick<ProductCatalog, 'uuid' | 'name' | 'code' | 'Manufacturer'>
    )>, Kit?: Maybe<(
      { __typename?: 'Kit' }
      & Pick<Kit, 'uuid'>
      & { KitCatalog: (
        { __typename?: 'KitCatalog' }
        & Pick<KitCatalog, 'uuid' | 'name' | 'hierarchy'>
      ) }
    )>, Dealerships: Array<(
      { __typename?: 'Dealership' }
      & Pick<Dealership, 'uuid' | 'fantasyName'>
    )> }
  ) }
);

export type ProductFormPage_ProductCatalogsQueryVariables = Exact<{
  Manufacturer?: Maybe<Manufacturers>;
}>;


export type ProductFormPage_ProductCatalogsQuery = (
  { __typename?: 'Query' }
  & { productCatalogs: Array<(
    { __typename?: 'ProductCatalog' }
    & Pick<ProductCatalog, 'uuid' | 'name' | 'Manufacturer' | 'code'>
  )> }
);

export type ProductFormPage_InitialDataQueryVariables = Exact<{
  groupId: Scalars['ID'];
}>;


export type ProductFormPage_InitialDataQuery = (
  { __typename?: 'Query' }
  & { group: (
    { __typename?: 'Group' }
    & Pick<Group, 'uuid'>
    & { PremiumDescription?: Maybe<(
      { __typename?: 'PremiumDescription' }
      & Pick<PremiumDescription, 'premium1' | 'premium2' | 'premium3' | 'premium4' | 'premium5' | 'premium6'>
    )>, Dealerships: Array<(
      { __typename?: 'Dealership' }
      & Pick<Dealership, 'uuid' | 'fantasyName'>
    )> }
  ), kits: Array<(
    { __typename?: 'Kit' }
    & Pick<Kit, 'uuid'>
    & { KitCatalog: (
      { __typename?: 'KitCatalog' }
      & Pick<KitCatalog, 'uuid' | 'name' | 'hierarchy'>
    ) }
  )> }
);

export type ProductTablePage_ProductsQueryVariables = Exact<{
  groupId?: Maybe<Scalars['ID']>;
  dealershipId?: Maybe<Scalars['ID']>;
  Manufacturer?: Maybe<Manufacturers>;
  PremiumCalculationType?: Maybe<PremiumCalculationType>;
}>;


export type ProductTablePage_ProductsQuery = (
  { __typename?: 'Query' }
  & { products: Array<(
    { __typename?: 'Product' }
    & Pick<Product, 'uuid' | 'productCode'>
    & { ProductCatalog?: Maybe<(
      { __typename?: 'ProductCatalog' }
      & Pick<ProductCatalog, 'uuid' | 'name'>
    )> }
  )> }
);

export type ProductTablePage_DeleteProductMutationVariables = Exact<{
  uuid: Scalars['ID'];
}>;


export type ProductTablePage_DeleteProductMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteProduct'>
);

export type SellersFormPage_UpsertSellerMutationVariables = Exact<{
  uuid?: Maybe<Scalars['ID']>;
  active?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  erpCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  sellerId?: Maybe<Scalars['ID']>;
}>;


export type SellersFormPage_UpsertSellerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertSeller'>
);

export type SellersFormPage_SellersQueryVariables = Exact<{ [key: string]: never; }>;


export type SellersFormPage_SellersQuery = (
  { __typename?: 'Query' }
  & { sellers: Array<(
    { __typename?: 'Seller' }
    & Pick<Seller, 'uuid' | 'name'>
  )> }
);

export type SellersFormPage_SellerQueryVariables = Exact<{
  uuid: Scalars['ID'];
}>;


export type SellersFormPage_SellerQuery = (
  { __typename?: 'Query' }
  & { seller: (
    { __typename?: 'Seller' }
    & Pick<Seller, 'uuid' | 'active' | 'name' | 'cpf' | 'email' | 'erpCode' | 'createdAt' | 'updatedAt'>
    & { Manager?: Maybe<(
      { __typename?: 'Seller' }
      & Pick<Seller, 'uuid'>
    )> }
  ) }
);

export type SellersTablePage_SellersQueryVariables = Exact<{ [key: string]: never; }>;


export type SellersTablePage_SellersQuery = (
  { __typename?: 'Query' }
  & { sellers: Array<(
    { __typename?: 'Seller' }
    & Pick<Seller, 'uuid' | 'name'>
  )> }
);

export type SellersTablePage_DeleteSellerMutationVariables = Exact<{
  uuid: Scalars['ID'];
}>;


export type SellersTablePage_DeleteSellerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSeller'>
);

export type ServiceFormPage_UpsertServiceMutationVariables = Exact<{
  uuid?: Maybe<Scalars['ID']>;
  kitId?: Maybe<Scalars['ID']>;
  serviceCatalogId?: Maybe<Scalars['ID']>;
  ServiceCatalog?: Maybe<ServiceCatalogInput>;
  systemCode?: Maybe<Scalars['String']>;
  salePrice?: Maybe<Scalars['Float']>;
  registrationPremium?: Maybe<Scalars['Float']>;
  premium1?: Maybe<Scalars['Float']>;
  premium2?: Maybe<Scalars['Float']>;
  premium3?: Maybe<Scalars['Float']>;
  premium4?: Maybe<Scalars['Float']>;
  premium5?: Maybe<Scalars['Float']>;
  premium6?: Maybe<Scalars['Float']>;
  dealershipIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type ServiceFormPage_UpsertServiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertService'>
);

export type ServiceFormPage_ServiceQueryVariables = Exact<{
  uuid: Scalars['ID'];
}>;


export type ServiceFormPage_ServiceQuery = (
  { __typename?: 'Query' }
  & { service: (
    { __typename?: 'Service' }
    & Pick<Service, 'uuid' | 'salePrice' | 'systemCode' | 'registrationPremium' | 'premium1' | 'premium2' | 'premium3' | 'premium4' | 'premium5' | 'premium6'>
    & { ServiceCatalog?: Maybe<(
      { __typename?: 'ServiceCatalog' }
      & Pick<ServiceCatalog, 'uuid' | 'name'>
    )>, Kit?: Maybe<(
      { __typename?: 'Kit' }
      & Pick<Kit, 'uuid'>
      & { KitCatalog: (
        { __typename?: 'KitCatalog' }
        & Pick<KitCatalog, 'uuid' | 'name' | 'hierarchy'>
      ) }
    )>, Dealerships: Array<(
      { __typename?: 'Dealership' }
      & Pick<Dealership, 'uuid' | 'fantasyName'>
    )> }
  ) }
);

export type ServiceFormPage_InitialDataQueryVariables = Exact<{
  groupId: Scalars['ID'];
}>;


export type ServiceFormPage_InitialDataQuery = (
  { __typename?: 'Query' }
  & { serviceCatalogs: Array<(
    { __typename?: 'ServiceCatalog' }
    & Pick<ServiceCatalog, 'uuid' | 'name'>
  )>, group: (
    { __typename?: 'Group' }
    & Pick<Group, 'uuid'>
    & { PremiumDescription?: Maybe<(
      { __typename?: 'PremiumDescription' }
      & Pick<PremiumDescription, 'premium1' | 'premium2' | 'premium3' | 'premium4' | 'premium5' | 'premium6'>
    )>, Dealerships: Array<(
      { __typename?: 'Dealership' }
      & Pick<Dealership, 'uuid' | 'fantasyName'>
    )> }
  ), kits: Array<(
    { __typename?: 'Kit' }
    & Pick<Kit, 'uuid'>
    & { KitCatalog: (
      { __typename?: 'KitCatalog' }
      & Pick<KitCatalog, 'uuid' | 'name' | 'hierarchy'>
    ) }
  )> }
);

export type ServiceTablePage_ServicesQueryVariables = Exact<{
  groupId?: Maybe<Scalars['ID']>;
  dealershipId?: Maybe<Scalars['ID']>;
}>;


export type ServiceTablePage_ServicesQuery = (
  { __typename?: 'Query' }
  & { services: Array<(
    { __typename?: 'Service' }
    & Pick<Service, 'uuid' | 'systemCode'>
    & { ServiceCatalog?: Maybe<(
      { __typename?: 'ServiceCatalog' }
      & Pick<ServiceCatalog, 'uuid' | 'name'>
    )> }
  )> }
);

export type ServiceTablePage_DeleteServiceMutationVariables = Exact<{
  uuid: Scalars['ID'];
}>;


export type ServiceTablePage_DeleteServiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteService'>
);

export type UserPermissionsFormPage_UserPermissionsQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type UserPermissionsFormPage_UserPermissionsQuery = (
  { __typename?: 'Query' }
  & { userDealershipPermissions: Array<(
    { __typename?: 'UserDealershipPermission' }
    & Pick<UserDealershipPermission, 'uuid' | 'email'>
    & { Dealership: (
      { __typename?: 'Dealership' }
      & Pick<Dealership, 'uuid' | 'fantasyName'>
      & { Group: (
        { __typename?: 'Group' }
        & Pick<Group, 'uuid'>
      ) }
    ) }
  )> }
);

export type UserPermissionsFormPage_SetUserDealershipPermissionsMutationVariables = Exact<{
  dealershipIds: Array<Scalars['ID']> | Scalars['ID'];
  email: Scalars['String'];
}>;


export type UserPermissionsFormPage_SetUserDealershipPermissionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setUserDealershipPermissions'>
);


export const AuthContext_UserRolesDocument = gql`
    query AuthContext_userRoles($email: String!) {
  userRoles(email: $email)
}
    `;

/**
 * __useAuthContext_UserRolesQuery__
 *
 * To run a query within a React component, call `useAuthContext_UserRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthContext_UserRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthContext_UserRolesQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAuthContext_UserRolesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AuthContext_UserRolesQuery, AuthContext_UserRolesQueryVariables>) {
        return ApolloReactHooks.useQuery<AuthContext_UserRolesQuery, AuthContext_UserRolesQueryVariables>(AuthContext_UserRolesDocument, baseOptions);
      }
export function useAuthContext_UserRolesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AuthContext_UserRolesQuery, AuthContext_UserRolesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AuthContext_UserRolesQuery, AuthContext_UserRolesQueryVariables>(AuthContext_UserRolesDocument, baseOptions);
        }
export type AuthContext_UserRolesQueryHookResult = ReturnType<typeof useAuthContext_UserRolesQuery>;
export type AuthContext_UserRolesLazyQueryHookResult = ReturnType<typeof useAuthContext_UserRolesLazyQuery>;
export type AuthContext_UserRolesQueryResult = ApolloReactCommon.QueryResult<AuthContext_UserRolesQuery, AuthContext_UserRolesQueryVariables>;
export const PasswordChangePage_ChangePasswordDocument = gql`
    mutation PasswordChangePage_changePassword($newPassword: String!) {
  changePassword(newPassword: $newPassword)
}
    `;
export type PasswordChangePage_ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<PasswordChangePage_ChangePasswordMutation, PasswordChangePage_ChangePasswordMutationVariables>;

/**
 * __usePasswordChangePage_ChangePasswordMutation__
 *
 * To run a mutation, you first call `usePasswordChangePage_ChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordChangePage_ChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordChangePageChangePasswordMutation, { data, loading, error }] = usePasswordChangePage_ChangePasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function usePasswordChangePage_ChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PasswordChangePage_ChangePasswordMutation, PasswordChangePage_ChangePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<PasswordChangePage_ChangePasswordMutation, PasswordChangePage_ChangePasswordMutationVariables>(PasswordChangePage_ChangePasswordDocument, baseOptions);
      }
export type PasswordChangePage_ChangePasswordMutationHookResult = ReturnType<typeof usePasswordChangePage_ChangePasswordMutation>;
export type PasswordChangePage_ChangePasswordMutationResult = ApolloReactCommon.MutationResult<PasswordChangePage_ChangePasswordMutation>;
export type PasswordChangePage_ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<PasswordChangePage_ChangePasswordMutation, PasswordChangePage_ChangePasswordMutationVariables>;
export const ProfilePage_UpdateUserDocument = gql`
    mutation ProfilePage_updateUser($firstName: String, $lastName: String, $avatarBase64: String) {
  updateUser(
    firstName: $firstName
    lastName: $lastName
    avatarBase64: $avatarBase64
  )
}
    `;
export type ProfilePage_UpdateUserMutationFn = ApolloReactCommon.MutationFunction<ProfilePage_UpdateUserMutation, ProfilePage_UpdateUserMutationVariables>;

/**
 * __useProfilePage_UpdateUserMutation__
 *
 * To run a mutation, you first call `useProfilePage_UpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfilePage_UpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profilePageUpdateUserMutation, { data, loading, error }] = useProfilePage_UpdateUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      avatarBase64: // value for 'avatarBase64'
 *   },
 * });
 */
export function useProfilePage_UpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ProfilePage_UpdateUserMutation, ProfilePage_UpdateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<ProfilePage_UpdateUserMutation, ProfilePage_UpdateUserMutationVariables>(ProfilePage_UpdateUserDocument, baseOptions);
      }
export type ProfilePage_UpdateUserMutationHookResult = ReturnType<typeof useProfilePage_UpdateUserMutation>;
export type ProfilePage_UpdateUserMutationResult = ApolloReactCommon.MutationResult<ProfilePage_UpdateUserMutation>;
export type ProfilePage_UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<ProfilePage_UpdateUserMutation, ProfilePage_UpdateUserMutationVariables>;
export const TenantContext_GroupsDocument = gql`
    query TenantContext_groups {
  groups {
    uuid
    name
    integrated
    active
    Dealerships {
      uuid
      fantasyName
    }
  }
}
    `;

/**
 * __useTenantContext_GroupsQuery__
 *
 * To run a query within a React component, call `useTenantContext_GroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantContext_GroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantContext_GroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTenantContext_GroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TenantContext_GroupsQuery, TenantContext_GroupsQueryVariables>) {
        return ApolloReactHooks.useQuery<TenantContext_GroupsQuery, TenantContext_GroupsQueryVariables>(TenantContext_GroupsDocument, baseOptions);
      }
export function useTenantContext_GroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TenantContext_GroupsQuery, TenantContext_GroupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TenantContext_GroupsQuery, TenantContext_GroupsQueryVariables>(TenantContext_GroupsDocument, baseOptions);
        }
export type TenantContext_GroupsQueryHookResult = ReturnType<typeof useTenantContext_GroupsQuery>;
export type TenantContext_GroupsLazyQueryHookResult = ReturnType<typeof useTenantContext_GroupsLazyQuery>;
export type TenantContext_GroupsQueryResult = ApolloReactCommon.QueryResult<TenantContext_GroupsQuery, TenantContext_GroupsQueryVariables>;
export const ConsultantsFormPage_UpsertConsultantDocument = gql`
    mutation ConsultantsFormPage_upsertConsultant($uuid: ID, $active: Boolean, $name: String, $cpf: String, $premiumCardNumber: String, $proxyNumber: String, $Premium: String, $dealershipId: ID!) {
  upsertConsultant(
    uuid: $uuid
    name: $name
    cpf: $cpf
    premiumCardNumber: $premiumCardNumber
    proxyNumber: $proxyNumber
    Premium: $Premium
    dealershipId: $dealershipId
    active: $active
  )
}
    `;
export type ConsultantsFormPage_UpsertConsultantMutationFn = ApolloReactCommon.MutationFunction<ConsultantsFormPage_UpsertConsultantMutation, ConsultantsFormPage_UpsertConsultantMutationVariables>;

/**
 * __useConsultantsFormPage_UpsertConsultantMutation__
 *
 * To run a mutation, you first call `useConsultantsFormPage_UpsertConsultantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConsultantsFormPage_UpsertConsultantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [consultantsFormPageUpsertConsultantMutation, { data, loading, error }] = useConsultantsFormPage_UpsertConsultantMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      active: // value for 'active'
 *      name: // value for 'name'
 *      cpf: // value for 'cpf'
 *      premiumCardNumber: // value for 'premiumCardNumber'
 *      proxyNumber: // value for 'proxyNumber'
 *      Premium: // value for 'Premium'
 *      dealershipId: // value for 'dealershipId'
 *   },
 * });
 */
export function useConsultantsFormPage_UpsertConsultantMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConsultantsFormPage_UpsertConsultantMutation, ConsultantsFormPage_UpsertConsultantMutationVariables>) {
        return ApolloReactHooks.useMutation<ConsultantsFormPage_UpsertConsultantMutation, ConsultantsFormPage_UpsertConsultantMutationVariables>(ConsultantsFormPage_UpsertConsultantDocument, baseOptions);
      }
export type ConsultantsFormPage_UpsertConsultantMutationHookResult = ReturnType<typeof useConsultantsFormPage_UpsertConsultantMutation>;
export type ConsultantsFormPage_UpsertConsultantMutationResult = ApolloReactCommon.MutationResult<ConsultantsFormPage_UpsertConsultantMutation>;
export type ConsultantsFormPage_UpsertConsultantMutationOptions = ApolloReactCommon.BaseMutationOptions<ConsultantsFormPage_UpsertConsultantMutation, ConsultantsFormPage_UpsertConsultantMutationVariables>;
export const ConsultantsFormPage_ConsultantDocument = gql`
    query ConsultantsFormPage_consultant($uuid: ID!) {
  consultant(uuid: $uuid) {
    active
    uuid
    name
    cpf
    premiumCardNumber
    proxyNumber
    Premium
  }
}
    `;

/**
 * __useConsultantsFormPage_ConsultantQuery__
 *
 * To run a query within a React component, call `useConsultantsFormPage_ConsultantQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsultantsFormPage_ConsultantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultantsFormPage_ConsultantQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useConsultantsFormPage_ConsultantQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ConsultantsFormPage_ConsultantQuery, ConsultantsFormPage_ConsultantQueryVariables>) {
        return ApolloReactHooks.useQuery<ConsultantsFormPage_ConsultantQuery, ConsultantsFormPage_ConsultantQueryVariables>(ConsultantsFormPage_ConsultantDocument, baseOptions);
      }
export function useConsultantsFormPage_ConsultantLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ConsultantsFormPage_ConsultantQuery, ConsultantsFormPage_ConsultantQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ConsultantsFormPage_ConsultantQuery, ConsultantsFormPage_ConsultantQueryVariables>(ConsultantsFormPage_ConsultantDocument, baseOptions);
        }
export type ConsultantsFormPage_ConsultantQueryHookResult = ReturnType<typeof useConsultantsFormPage_ConsultantQuery>;
export type ConsultantsFormPage_ConsultantLazyQueryHookResult = ReturnType<typeof useConsultantsFormPage_ConsultantLazyQuery>;
export type ConsultantsFormPage_ConsultantQueryResult = ApolloReactCommon.QueryResult<ConsultantsFormPage_ConsultantQuery, ConsultantsFormPage_ConsultantQueryVariables>;
export const ConsultantsFormPage_GroupDocument = gql`
    query ConsultantsFormPage_group($uuid: ID!) {
  group(uuid: $uuid) {
    uuid
    PremiumDescription {
      premium1
      premium2
      premium3
      premium4
      premium5
      premium6
    }
  }
}
    `;

/**
 * __useConsultantsFormPage_GroupQuery__
 *
 * To run a query within a React component, call `useConsultantsFormPage_GroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsultantsFormPage_GroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultantsFormPage_GroupQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useConsultantsFormPage_GroupQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ConsultantsFormPage_GroupQuery, ConsultantsFormPage_GroupQueryVariables>) {
        return ApolloReactHooks.useQuery<ConsultantsFormPage_GroupQuery, ConsultantsFormPage_GroupQueryVariables>(ConsultantsFormPage_GroupDocument, baseOptions);
      }
export function useConsultantsFormPage_GroupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ConsultantsFormPage_GroupQuery, ConsultantsFormPage_GroupQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ConsultantsFormPage_GroupQuery, ConsultantsFormPage_GroupQueryVariables>(ConsultantsFormPage_GroupDocument, baseOptions);
        }
export type ConsultantsFormPage_GroupQueryHookResult = ReturnType<typeof useConsultantsFormPage_GroupQuery>;
export type ConsultantsFormPage_GroupLazyQueryHookResult = ReturnType<typeof useConsultantsFormPage_GroupLazyQuery>;
export type ConsultantsFormPage_GroupQueryResult = ApolloReactCommon.QueryResult<ConsultantsFormPage_GroupQuery, ConsultantsFormPage_GroupQueryVariables>;
export const ConsultantsTablePage_ConsultantsDocument = gql`
    query ConsultantsTablePage_consultants($dealershipId: ID!) {
  consultants(dealershipId: $dealershipId) {
    uuid
    name
  }
}
    `;

/**
 * __useConsultantsTablePage_ConsultantsQuery__
 *
 * To run a query within a React component, call `useConsultantsTablePage_ConsultantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsultantsTablePage_ConsultantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultantsTablePage_ConsultantsQuery({
 *   variables: {
 *      dealershipId: // value for 'dealershipId'
 *   },
 * });
 */
export function useConsultantsTablePage_ConsultantsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ConsultantsTablePage_ConsultantsQuery, ConsultantsTablePage_ConsultantsQueryVariables>) {
        return ApolloReactHooks.useQuery<ConsultantsTablePage_ConsultantsQuery, ConsultantsTablePage_ConsultantsQueryVariables>(ConsultantsTablePage_ConsultantsDocument, baseOptions);
      }
export function useConsultantsTablePage_ConsultantsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ConsultantsTablePage_ConsultantsQuery, ConsultantsTablePage_ConsultantsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ConsultantsTablePage_ConsultantsQuery, ConsultantsTablePage_ConsultantsQueryVariables>(ConsultantsTablePage_ConsultantsDocument, baseOptions);
        }
export type ConsultantsTablePage_ConsultantsQueryHookResult = ReturnType<typeof useConsultantsTablePage_ConsultantsQuery>;
export type ConsultantsTablePage_ConsultantsLazyQueryHookResult = ReturnType<typeof useConsultantsTablePage_ConsultantsLazyQuery>;
export type ConsultantsTablePage_ConsultantsQueryResult = ApolloReactCommon.QueryResult<ConsultantsTablePage_ConsultantsQuery, ConsultantsTablePage_ConsultantsQueryVariables>;
export const ConsultantsTablePage_DeleteConsultantDocument = gql`
    mutation ConsultantsTablePage_deleteConsultant($uuid: ID!) {
  deleteConsultant(uuid: $uuid)
}
    `;
export type ConsultantsTablePage_DeleteConsultantMutationFn = ApolloReactCommon.MutationFunction<ConsultantsTablePage_DeleteConsultantMutation, ConsultantsTablePage_DeleteConsultantMutationVariables>;

/**
 * __useConsultantsTablePage_DeleteConsultantMutation__
 *
 * To run a mutation, you first call `useConsultantsTablePage_DeleteConsultantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConsultantsTablePage_DeleteConsultantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [consultantsTablePageDeleteConsultantMutation, { data, loading, error }] = useConsultantsTablePage_DeleteConsultantMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useConsultantsTablePage_DeleteConsultantMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConsultantsTablePage_DeleteConsultantMutation, ConsultantsTablePage_DeleteConsultantMutationVariables>) {
        return ApolloReactHooks.useMutation<ConsultantsTablePage_DeleteConsultantMutation, ConsultantsTablePage_DeleteConsultantMutationVariables>(ConsultantsTablePage_DeleteConsultantDocument, baseOptions);
      }
export type ConsultantsTablePage_DeleteConsultantMutationHookResult = ReturnType<typeof useConsultantsTablePage_DeleteConsultantMutation>;
export type ConsultantsTablePage_DeleteConsultantMutationResult = ApolloReactCommon.MutationResult<ConsultantsTablePage_DeleteConsultantMutation>;
export type ConsultantsTablePage_DeleteConsultantMutationOptions = ApolloReactCommon.BaseMutationOptions<ConsultantsTablePage_DeleteConsultantMutation, ConsultantsTablePage_DeleteConsultantMutationVariables>;
export const DealershipFormPage_UpsertDealershipDocument = gql`
    mutation DealershipFormPage_upsertDealership($uuid: ID, $corporateName: String, $fantasyName: String, $contractorDateOfBirth: DateTime, $contractorName: String, $contractorEmail: String, $cnpj: String, $erpCode: String, $ie: String, $im: String, $website: String, $phone: String, $equipmentType: String, $equipmentQty: Int, $address: String, $neighbourhood: String, $city: String, $state: String, $zipcode: String, $invoicingPhone: String, $invoicingEmail: String, $invoiceXMLEmail: String, $corporateName1: String, $phone1: String, $corporateName2: String, $phone2: String, $corporateName3: String, $phone3: String, $sellerName: String, $notes: String, $branchesQty: Int, $paymentPeriod: Int, $ravmenu: Boolean, $scalar: Boolean, $ravpesquisa: Boolean, $maxparts: Boolean, $vwcaminhoes: Boolean, $groupId: ID!, $systemId: ID, $brandIds: [ID!]!, $pix: String, $bankNumber: String, $bankAgency: String, $bankAccount: String, $vhsysId: String, $PaymentMethod: PaymentMethods, $sellerId: ID) {
  upsertDealership(
    uuid: $uuid
    corporateName: $corporateName
    fantasyName: $fantasyName
    contractorDateOfBirth: $contractorDateOfBirth
    contractorName: $contractorName
    contractorEmail: $contractorEmail
    cnpj: $cnpj
    erpCode: $erpCode
    ie: $ie
    im: $im
    website: $website
    phone: $phone
    equipmentType: $equipmentType
    equipmentQty: $equipmentQty
    address: $address
    neighbourhood: $neighbourhood
    city: $city
    state: $state
    zipcode: $zipcode
    invoicingPhone: $invoicingPhone
    invoicingEmail: $invoicingEmail
    invoiceXMLEmail: $invoiceXMLEmail
    corporateName1: $corporateName1
    phone1: $phone1
    corporateName2: $corporateName2
    phone2: $phone2
    corporateName3: $corporateName3
    phone3: $phone3
    sellerName: $sellerName
    notes: $notes
    branchesQty: $branchesQty
    paymentPeriod: $paymentPeriod
    ravmenu: $ravmenu
    scalar: $scalar
    ravpesquisa: $ravpesquisa
    maxparts: $maxparts
    vwcaminhoes: $vwcaminhoes
    groupId: $groupId
    systemId: $systemId
    brandIds: $brandIds
    pix: $pix
    bankNumber: $bankNumber
    bankAgency: $bankAgency
    bankAccount: $bankAccount
    PaymentMethod: $PaymentMethod
    sellerId: $sellerId
    vhsysId: $vhsysId
  )
}
    `;
export type DealershipFormPage_UpsertDealershipMutationFn = ApolloReactCommon.MutationFunction<DealershipFormPage_UpsertDealershipMutation, DealershipFormPage_UpsertDealershipMutationVariables>;

/**
 * __useDealershipFormPage_UpsertDealershipMutation__
 *
 * To run a mutation, you first call `useDealershipFormPage_UpsertDealershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDealershipFormPage_UpsertDealershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dealershipFormPageUpsertDealershipMutation, { data, loading, error }] = useDealershipFormPage_UpsertDealershipMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      corporateName: // value for 'corporateName'
 *      fantasyName: // value for 'fantasyName'
 *      contractorDateOfBirth: // value for 'contractorDateOfBirth'
 *      contractorName: // value for 'contractorName'
 *      contractorEmail: // value for 'contractorEmail'
 *      cnpj: // value for 'cnpj'
 *      erpCode: // value for 'erpCode'
 *      ie: // value for 'ie'
 *      im: // value for 'im'
 *      website: // value for 'website'
 *      phone: // value for 'phone'
 *      equipmentType: // value for 'equipmentType'
 *      equipmentQty: // value for 'equipmentQty'
 *      address: // value for 'address'
 *      neighbourhood: // value for 'neighbourhood'
 *      city: // value for 'city'
 *      state: // value for 'state'
 *      zipcode: // value for 'zipcode'
 *      invoicingPhone: // value for 'invoicingPhone'
 *      invoicingEmail: // value for 'invoicingEmail'
 *      invoiceXMLEmail: // value for 'invoiceXMLEmail'
 *      corporateName1: // value for 'corporateName1'
 *      phone1: // value for 'phone1'
 *      corporateName2: // value for 'corporateName2'
 *      phone2: // value for 'phone2'
 *      corporateName3: // value for 'corporateName3'
 *      phone3: // value for 'phone3'
 *      sellerName: // value for 'sellerName'
 *      notes: // value for 'notes'
 *      branchesQty: // value for 'branchesQty'
 *      paymentPeriod: // value for 'paymentPeriod'
 *      ravmenu: // value for 'ravmenu'
 *      scalar: // value for 'scalar'
 *      ravpesquisa: // value for 'ravpesquisa'
 *      maxparts: // value for 'maxparts'
 *      vwcaminhoes: // value for 'vwcaminhoes'
 *      groupId: // value for 'groupId'
 *      systemId: // value for 'systemId'
 *      brandIds: // value for 'brandIds'
 *      pix: // value for 'pix'
 *      bankNumber: // value for 'bankNumber'
 *      bankAgency: // value for 'bankAgency'
 *      bankAccount: // value for 'bankAccount'
 *      vhsysId: // value for 'vhsysId'
 *      PaymentMethod: // value for 'PaymentMethod'
 *      sellerId: // value for 'sellerId'
 *   },
 * });
 */
export function useDealershipFormPage_UpsertDealershipMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DealershipFormPage_UpsertDealershipMutation, DealershipFormPage_UpsertDealershipMutationVariables>) {
        return ApolloReactHooks.useMutation<DealershipFormPage_UpsertDealershipMutation, DealershipFormPage_UpsertDealershipMutationVariables>(DealershipFormPage_UpsertDealershipDocument, baseOptions);
      }
export type DealershipFormPage_UpsertDealershipMutationHookResult = ReturnType<typeof useDealershipFormPage_UpsertDealershipMutation>;
export type DealershipFormPage_UpsertDealershipMutationResult = ApolloReactCommon.MutationResult<DealershipFormPage_UpsertDealershipMutation>;
export type DealershipFormPage_UpsertDealershipMutationOptions = ApolloReactCommon.BaseMutationOptions<DealershipFormPage_UpsertDealershipMutation, DealershipFormPage_UpsertDealershipMutationVariables>;
export const DealershipFormPage_DealershipDocument = gql`
    query DealershipFormPage_dealership($uuid: ID!) {
  dealership(uuid: $uuid) {
    uuid
    corporateName
    fantasyName
    contractorDateOfBirth
    contractorName
    contractorEmail
    cnpj
    ie
    im
    website
    erpCode
    phone
    equipmentType
    equipmentQty
    address
    neighbourhood
    city
    state
    zipcode
    invoicingPhone
    invoicingEmail
    invoiceXMLEmail
    corporateName1
    phone1
    corporateName2
    phone2
    corporateName3
    phone3
    sellerName
    notes
    branchesQty
    paymentPeriod
    ravmenu
    scalar
    ravpesquisa
    maxparts
    vwcaminhoes
    vhsysId
    Group {
      uuid
    }
    System {
      uuid
    }
    Brands {
      uuid
    }
    pix
    bankNumber
    bankAgency
    bankAccount
    PaymentMethod
    Seller {
      uuid
    }
  }
}
    `;

/**
 * __useDealershipFormPage_DealershipQuery__
 *
 * To run a query within a React component, call `useDealershipFormPage_DealershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealershipFormPage_DealershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealershipFormPage_DealershipQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDealershipFormPage_DealershipQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DealershipFormPage_DealershipQuery, DealershipFormPage_DealershipQueryVariables>) {
        return ApolloReactHooks.useQuery<DealershipFormPage_DealershipQuery, DealershipFormPage_DealershipQueryVariables>(DealershipFormPage_DealershipDocument, baseOptions);
      }
export function useDealershipFormPage_DealershipLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DealershipFormPage_DealershipQuery, DealershipFormPage_DealershipQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DealershipFormPage_DealershipQuery, DealershipFormPage_DealershipQueryVariables>(DealershipFormPage_DealershipDocument, baseOptions);
        }
export type DealershipFormPage_DealershipQueryHookResult = ReturnType<typeof useDealershipFormPage_DealershipQuery>;
export type DealershipFormPage_DealershipLazyQueryHookResult = ReturnType<typeof useDealershipFormPage_DealershipLazyQuery>;
export type DealershipFormPage_DealershipQueryResult = ApolloReactCommon.QueryResult<DealershipFormPage_DealershipQuery, DealershipFormPage_DealershipQueryVariables>;
export const DealershipFormPage_Initial_DataDocument = gql`
    query DealershipFormPage_initial_data {
  systems {
    uuid
    name
  }
  brands {
    uuid
    name
  }
  sellers {
    uuid
    name
  }
}
    `;

/**
 * __useDealershipFormPage_Initial_DataQuery__
 *
 * To run a query within a React component, call `useDealershipFormPage_Initial_DataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealershipFormPage_Initial_DataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealershipFormPage_Initial_DataQuery({
 *   variables: {
 *   },
 * });
 */
export function useDealershipFormPage_Initial_DataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DealershipFormPage_Initial_DataQuery, DealershipFormPage_Initial_DataQueryVariables>) {
        return ApolloReactHooks.useQuery<DealershipFormPage_Initial_DataQuery, DealershipFormPage_Initial_DataQueryVariables>(DealershipFormPage_Initial_DataDocument, baseOptions);
      }
export function useDealershipFormPage_Initial_DataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DealershipFormPage_Initial_DataQuery, DealershipFormPage_Initial_DataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DealershipFormPage_Initial_DataQuery, DealershipFormPage_Initial_DataQueryVariables>(DealershipFormPage_Initial_DataDocument, baseOptions);
        }
export type DealershipFormPage_Initial_DataQueryHookResult = ReturnType<typeof useDealershipFormPage_Initial_DataQuery>;
export type DealershipFormPage_Initial_DataLazyQueryHookResult = ReturnType<typeof useDealershipFormPage_Initial_DataLazyQuery>;
export type DealershipFormPage_Initial_DataQueryResult = ApolloReactCommon.QueryResult<DealershipFormPage_Initial_DataQuery, DealershipFormPage_Initial_DataQueryVariables>;
export const DealershipTablePage_DealershipsDocument = gql`
    query DealershipTablePage_dealerships($groupId: ID!) {
  dealerships(groupId: $groupId) {
    uuid
    fantasyName
    Group {
      name
    }
  }
}
    `;

/**
 * __useDealershipTablePage_DealershipsQuery__
 *
 * To run a query within a React component, call `useDealershipTablePage_DealershipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealershipTablePage_DealershipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealershipTablePage_DealershipsQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useDealershipTablePage_DealershipsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DealershipTablePage_DealershipsQuery, DealershipTablePage_DealershipsQueryVariables>) {
        return ApolloReactHooks.useQuery<DealershipTablePage_DealershipsQuery, DealershipTablePage_DealershipsQueryVariables>(DealershipTablePage_DealershipsDocument, baseOptions);
      }
export function useDealershipTablePage_DealershipsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DealershipTablePage_DealershipsQuery, DealershipTablePage_DealershipsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DealershipTablePage_DealershipsQuery, DealershipTablePage_DealershipsQueryVariables>(DealershipTablePage_DealershipsDocument, baseOptions);
        }
export type DealershipTablePage_DealershipsQueryHookResult = ReturnType<typeof useDealershipTablePage_DealershipsQuery>;
export type DealershipTablePage_DealershipsLazyQueryHookResult = ReturnType<typeof useDealershipTablePage_DealershipsLazyQuery>;
export type DealershipTablePage_DealershipsQueryResult = ApolloReactCommon.QueryResult<DealershipTablePage_DealershipsQuery, DealershipTablePage_DealershipsQueryVariables>;
export const DealershipTablePage_DeleteDealershipDocument = gql`
    mutation DealershipTablePage_deleteDealership($uuid: ID!) {
  deleteDealership(uuid: $uuid)
}
    `;
export type DealershipTablePage_DeleteDealershipMutationFn = ApolloReactCommon.MutationFunction<DealershipTablePage_DeleteDealershipMutation, DealershipTablePage_DeleteDealershipMutationVariables>;

/**
 * __useDealershipTablePage_DeleteDealershipMutation__
 *
 * To run a mutation, you first call `useDealershipTablePage_DeleteDealershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDealershipTablePage_DeleteDealershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dealershipTablePageDeleteDealershipMutation, { data, loading, error }] = useDealershipTablePage_DeleteDealershipMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDealershipTablePage_DeleteDealershipMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DealershipTablePage_DeleteDealershipMutation, DealershipTablePage_DeleteDealershipMutationVariables>) {
        return ApolloReactHooks.useMutation<DealershipTablePage_DeleteDealershipMutation, DealershipTablePage_DeleteDealershipMutationVariables>(DealershipTablePage_DeleteDealershipDocument, baseOptions);
      }
export type DealershipTablePage_DeleteDealershipMutationHookResult = ReturnType<typeof useDealershipTablePage_DeleteDealershipMutation>;
export type DealershipTablePage_DeleteDealershipMutationResult = ApolloReactCommon.MutationResult<DealershipTablePage_DeleteDealershipMutation>;
export type DealershipTablePage_DeleteDealershipMutationOptions = ApolloReactCommon.BaseMutationOptions<DealershipTablePage_DeleteDealershipMutation, DealershipTablePage_DeleteDealershipMutationVariables>;
export const EntryFormPage_UpsertEntryDocument = gql`
    mutation EntryFormPage_upsertEntry($uuid: ID, $dealershipId: ID!, $ProductEntries: [ProductEntryInput!]!, $ServiceEntries: [ServiceEntryInput!]!, $RevisionEntries: [RevisionEntryInput!]!, $KitEntries: [KitEntryInput!]!, $date: DateTime!, $PremiumCalculationType: PremiumCalculationType!) {
  upsertEntry(
    uuid: $uuid
    dealershipId: $dealershipId
    ProductEntries: $ProductEntries
    ServiceEntries: $ServiceEntries
    RevisionEntries: $RevisionEntries
    KitEntries: $KitEntries
    date: $date
    PremiumCalculationType: $PremiumCalculationType
    ProductOrderEntries: []
  )
}
    `;
export type EntryFormPage_UpsertEntryMutationFn = ApolloReactCommon.MutationFunction<EntryFormPage_UpsertEntryMutation, EntryFormPage_UpsertEntryMutationVariables>;

/**
 * __useEntryFormPage_UpsertEntryMutation__
 *
 * To run a mutation, you first call `useEntryFormPage_UpsertEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEntryFormPage_UpsertEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [entryFormPageUpsertEntryMutation, { data, loading, error }] = useEntryFormPage_UpsertEntryMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      dealershipId: // value for 'dealershipId'
 *      ProductEntries: // value for 'ProductEntries'
 *      ServiceEntries: // value for 'ServiceEntries'
 *      RevisionEntries: // value for 'RevisionEntries'
 *      KitEntries: // value for 'KitEntries'
 *      date: // value for 'date'
 *      PremiumCalculationType: // value for 'PremiumCalculationType'
 *   },
 * });
 */
export function useEntryFormPage_UpsertEntryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EntryFormPage_UpsertEntryMutation, EntryFormPage_UpsertEntryMutationVariables>) {
        return ApolloReactHooks.useMutation<EntryFormPage_UpsertEntryMutation, EntryFormPage_UpsertEntryMutationVariables>(EntryFormPage_UpsertEntryDocument, baseOptions);
      }
export type EntryFormPage_UpsertEntryMutationHookResult = ReturnType<typeof useEntryFormPage_UpsertEntryMutation>;
export type EntryFormPage_UpsertEntryMutationResult = ApolloReactCommon.MutationResult<EntryFormPage_UpsertEntryMutation>;
export type EntryFormPage_UpsertEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<EntryFormPage_UpsertEntryMutation, EntryFormPage_UpsertEntryMutationVariables>;
export const EntryFormPage_EntryDocument = gql`
    query EntryFormPage_entry($uuid: ID!) {
  entry(uuid: $uuid) {
    uuid
    date
    ProductEntries {
      uuid
      Product {
        uuid
        productCode
        ProductCatalog {
          uuid
          name
          code
        }
      }
      Consultant {
        uuid
      }
      quantity
    }
    ServiceEntries {
      uuid
      Service {
        uuid
        ServiceCatalog {
          uuid
          name
        }
      }
      Consultant {
        uuid
      }
      quantity
    }
    RevisionEntries {
      uuid
      Consultant {
        uuid
      }
      quantity
    }
    KitEntries {
      uuid
      Kit {
        uuid
        KitCatalog {
          uuid
          name
        }
      }
      Consultant {
        uuid
      }
      quantity
    }
  }
}
    `;

/**
 * __useEntryFormPage_EntryQuery__
 *
 * To run a query within a React component, call `useEntryFormPage_EntryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntryFormPage_EntryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntryFormPage_EntryQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useEntryFormPage_EntryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntryFormPage_EntryQuery, EntryFormPage_EntryQueryVariables>) {
        return ApolloReactHooks.useQuery<EntryFormPage_EntryQuery, EntryFormPage_EntryQueryVariables>(EntryFormPage_EntryDocument, baseOptions);
      }
export function useEntryFormPage_EntryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntryFormPage_EntryQuery, EntryFormPage_EntryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntryFormPage_EntryQuery, EntryFormPage_EntryQueryVariables>(EntryFormPage_EntryDocument, baseOptions);
        }
export type EntryFormPage_EntryQueryHookResult = ReturnType<typeof useEntryFormPage_EntryQuery>;
export type EntryFormPage_EntryLazyQueryHookResult = ReturnType<typeof useEntryFormPage_EntryLazyQuery>;
export type EntryFormPage_EntryQueryResult = ApolloReactCommon.QueryResult<EntryFormPage_EntryQuery, EntryFormPage_EntryQueryVariables>;
export const EntryFormPage_InitialDataDocument = gql`
    query EntryFormPage_initialData($dealershipId: ID!, $PremiumCalculationType: PremiumCalculationType) {
  consultants(dealershipId: $dealershipId, hasPremium: false) {
    uuid
    name
    active
  }
  products(
    dealershipId: $dealershipId
    PremiumCalculationType: $PremiumCalculationType
    active: true
  ) {
    uuid
    productCode
    ProductCatalog {
      uuid
      name
      code
    }
  }
  services(dealershipId: $dealershipId) {
    uuid
    ServiceCatalog {
      uuid
      name
    }
  }
  kits(dealershipId: $dealershipId) {
    uuid
    KitCatalog {
      uuid
      name
    }
  }
  lastEntryDate(dealershipId: $dealershipId)
}
    `;

/**
 * __useEntryFormPage_InitialDataQuery__
 *
 * To run a query within a React component, call `useEntryFormPage_InitialDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntryFormPage_InitialDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntryFormPage_InitialDataQuery({
 *   variables: {
 *      dealershipId: // value for 'dealershipId'
 *      PremiumCalculationType: // value for 'PremiumCalculationType'
 *   },
 * });
 */
export function useEntryFormPage_InitialDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntryFormPage_InitialDataQuery, EntryFormPage_InitialDataQueryVariables>) {
        return ApolloReactHooks.useQuery<EntryFormPage_InitialDataQuery, EntryFormPage_InitialDataQueryVariables>(EntryFormPage_InitialDataDocument, baseOptions);
      }
export function useEntryFormPage_InitialDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntryFormPage_InitialDataQuery, EntryFormPage_InitialDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntryFormPage_InitialDataQuery, EntryFormPage_InitialDataQueryVariables>(EntryFormPage_InitialDataDocument, baseOptions);
        }
export type EntryFormPage_InitialDataQueryHookResult = ReturnType<typeof useEntryFormPage_InitialDataQuery>;
export type EntryFormPage_InitialDataLazyQueryHookResult = ReturnType<typeof useEntryFormPage_InitialDataLazyQuery>;
export type EntryFormPage_InitialDataQueryResult = ApolloReactCommon.QueryResult<EntryFormPage_InitialDataQuery, EntryFormPage_InitialDataQueryVariables>;
export const OrderEntryFormPage_UpsertEntryDocument = gql`
    mutation OrderEntryFormPage_upsertEntry($uuid: ID, $dealershipId: ID!, $ProductOrderEntries: [ProductOrderEntryInput!]!, $date: DateTime!) {
  upsertEntry(
    uuid: $uuid
    dealershipId: $dealershipId
    ProductOrderEntries: $ProductOrderEntries
    date: $date
    PremiumCalculationType: ORDER_VHSYS
    ProductEntries: []
    ServiceEntries: []
    RevisionEntries: []
    KitEntries: []
  )
}
    `;
export type OrderEntryFormPage_UpsertEntryMutationFn = ApolloReactCommon.MutationFunction<OrderEntryFormPage_UpsertEntryMutation, OrderEntryFormPage_UpsertEntryMutationVariables>;

/**
 * __useOrderEntryFormPage_UpsertEntryMutation__
 *
 * To run a mutation, you first call `useOrderEntryFormPage_UpsertEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderEntryFormPage_UpsertEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderEntryFormPageUpsertEntryMutation, { data, loading, error }] = useOrderEntryFormPage_UpsertEntryMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      dealershipId: // value for 'dealershipId'
 *      ProductOrderEntries: // value for 'ProductOrderEntries'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useOrderEntryFormPage_UpsertEntryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OrderEntryFormPage_UpsertEntryMutation, OrderEntryFormPage_UpsertEntryMutationVariables>) {
        return ApolloReactHooks.useMutation<OrderEntryFormPage_UpsertEntryMutation, OrderEntryFormPage_UpsertEntryMutationVariables>(OrderEntryFormPage_UpsertEntryDocument, baseOptions);
      }
export type OrderEntryFormPage_UpsertEntryMutationHookResult = ReturnType<typeof useOrderEntryFormPage_UpsertEntryMutation>;
export type OrderEntryFormPage_UpsertEntryMutationResult = ApolloReactCommon.MutationResult<OrderEntryFormPage_UpsertEntryMutation>;
export type OrderEntryFormPage_UpsertEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<OrderEntryFormPage_UpsertEntryMutation, OrderEntryFormPage_UpsertEntryMutationVariables>;
export const OrderEntryFormPage_EntryDocument = gql`
    query OrderEntryFormPage_entry($uuid: ID!) {
  entry(uuid: $uuid) {
    uuid
    date
    ProductOrderEntries {
      uuid
      Product {
        uuid
        productCode
        ProductCatalog {
          uuid
          name
          code
        }
      }
      quantity
    }
  }
}
    `;

/**
 * __useOrderEntryFormPage_EntryQuery__
 *
 * To run a query within a React component, call `useOrderEntryFormPage_EntryQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderEntryFormPage_EntryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderEntryFormPage_EntryQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useOrderEntryFormPage_EntryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrderEntryFormPage_EntryQuery, OrderEntryFormPage_EntryQueryVariables>) {
        return ApolloReactHooks.useQuery<OrderEntryFormPage_EntryQuery, OrderEntryFormPage_EntryQueryVariables>(OrderEntryFormPage_EntryDocument, baseOptions);
      }
export function useOrderEntryFormPage_EntryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrderEntryFormPage_EntryQuery, OrderEntryFormPage_EntryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrderEntryFormPage_EntryQuery, OrderEntryFormPage_EntryQueryVariables>(OrderEntryFormPage_EntryDocument, baseOptions);
        }
export type OrderEntryFormPage_EntryQueryHookResult = ReturnType<typeof useOrderEntryFormPage_EntryQuery>;
export type OrderEntryFormPage_EntryLazyQueryHookResult = ReturnType<typeof useOrderEntryFormPage_EntryLazyQuery>;
export type OrderEntryFormPage_EntryQueryResult = ApolloReactCommon.QueryResult<OrderEntryFormPage_EntryQuery, OrderEntryFormPage_EntryQueryVariables>;
export const OrderEntryFormPage_InitialDataDocument = gql`
    query OrderEntryFormPage_initialData($dealershipId: ID!) {
  products(
    dealershipId: $dealershipId
    PremiumCalculationType: ORDER_VHSYS
    active: true
  ) {
    uuid
    productCode
    ProductCatalog {
      uuid
      name
      code
    }
  }
  lastEntryDate(dealershipId: $dealershipId)
}
    `;

/**
 * __useOrderEntryFormPage_InitialDataQuery__
 *
 * To run a query within a React component, call `useOrderEntryFormPage_InitialDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderEntryFormPage_InitialDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderEntryFormPage_InitialDataQuery({
 *   variables: {
 *      dealershipId: // value for 'dealershipId'
 *   },
 * });
 */
export function useOrderEntryFormPage_InitialDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrderEntryFormPage_InitialDataQuery, OrderEntryFormPage_InitialDataQueryVariables>) {
        return ApolloReactHooks.useQuery<OrderEntryFormPage_InitialDataQuery, OrderEntryFormPage_InitialDataQueryVariables>(OrderEntryFormPage_InitialDataDocument, baseOptions);
      }
export function useOrderEntryFormPage_InitialDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrderEntryFormPage_InitialDataQuery, OrderEntryFormPage_InitialDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrderEntryFormPage_InitialDataQuery, OrderEntryFormPage_InitialDataQueryVariables>(OrderEntryFormPage_InitialDataDocument, baseOptions);
        }
export type OrderEntryFormPage_InitialDataQueryHookResult = ReturnType<typeof useOrderEntryFormPage_InitialDataQuery>;
export type OrderEntryFormPage_InitialDataLazyQueryHookResult = ReturnType<typeof useOrderEntryFormPage_InitialDataLazyQuery>;
export type OrderEntryFormPage_InitialDataQueryResult = ApolloReactCommon.QueryResult<OrderEntryFormPage_InitialDataQuery, OrderEntryFormPage_InitialDataQueryVariables>;
export const EntryTablePage_EntriesDocument = gql`
    query EntryTablePage_entries($dealershipId: ID!, $PremiumCalculationType: PremiumCalculationType!) {
  entries(
    dealershipId: $dealershipId
    PremiumCalculationType: $PremiumCalculationType
  ) {
    uuid
    Dealership {
      fantasyName
    }
    date
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useEntryTablePage_EntriesQuery__
 *
 * To run a query within a React component, call `useEntryTablePage_EntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntryTablePage_EntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntryTablePage_EntriesQuery({
 *   variables: {
 *      dealershipId: // value for 'dealershipId'
 *      PremiumCalculationType: // value for 'PremiumCalculationType'
 *   },
 * });
 */
export function useEntryTablePage_EntriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntryTablePage_EntriesQuery, EntryTablePage_EntriesQueryVariables>) {
        return ApolloReactHooks.useQuery<EntryTablePage_EntriesQuery, EntryTablePage_EntriesQueryVariables>(EntryTablePage_EntriesDocument, baseOptions);
      }
export function useEntryTablePage_EntriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntryTablePage_EntriesQuery, EntryTablePage_EntriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntryTablePage_EntriesQuery, EntryTablePage_EntriesQueryVariables>(EntryTablePage_EntriesDocument, baseOptions);
        }
export type EntryTablePage_EntriesQueryHookResult = ReturnType<typeof useEntryTablePage_EntriesQuery>;
export type EntryTablePage_EntriesLazyQueryHookResult = ReturnType<typeof useEntryTablePage_EntriesLazyQuery>;
export type EntryTablePage_EntriesQueryResult = ApolloReactCommon.QueryResult<EntryTablePage_EntriesQuery, EntryTablePage_EntriesQueryVariables>;
export const EntryTablePage_DeleteEntryDocument = gql`
    mutation EntryTablePage_deleteEntry($uuid: ID!) {
  deleteEntry(uuid: $uuid)
}
    `;
export type EntryTablePage_DeleteEntryMutationFn = ApolloReactCommon.MutationFunction<EntryTablePage_DeleteEntryMutation, EntryTablePage_DeleteEntryMutationVariables>;

/**
 * __useEntryTablePage_DeleteEntryMutation__
 *
 * To run a mutation, you first call `useEntryTablePage_DeleteEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEntryTablePage_DeleteEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [entryTablePageDeleteEntryMutation, { data, loading, error }] = useEntryTablePage_DeleteEntryMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useEntryTablePage_DeleteEntryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EntryTablePage_DeleteEntryMutation, EntryTablePage_DeleteEntryMutationVariables>) {
        return ApolloReactHooks.useMutation<EntryTablePage_DeleteEntryMutation, EntryTablePage_DeleteEntryMutationVariables>(EntryTablePage_DeleteEntryDocument, baseOptions);
      }
export type EntryTablePage_DeleteEntryMutationHookResult = ReturnType<typeof useEntryTablePage_DeleteEntryMutation>;
export type EntryTablePage_DeleteEntryMutationResult = ApolloReactCommon.MutationResult<EntryTablePage_DeleteEntryMutation>;
export type EntryTablePage_DeleteEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<EntryTablePage_DeleteEntryMutation, EntryTablePage_DeleteEntryMutationVariables>;
export const GroupFormPage_UpsertGroupDocument = gql`
    mutation GroupFormPage_upsertGroup($uuid: ID, $name: String, $integrated: Boolean!, $active: Boolean!) {
  upsertGroup(uuid: $uuid, name: $name, integrated: $integrated, active: $active)
}
    `;
export type GroupFormPage_UpsertGroupMutationFn = ApolloReactCommon.MutationFunction<GroupFormPage_UpsertGroupMutation, GroupFormPage_UpsertGroupMutationVariables>;

/**
 * __useGroupFormPage_UpsertGroupMutation__
 *
 * To run a mutation, you first call `useGroupFormPage_UpsertGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupFormPage_UpsertGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupFormPageUpsertGroupMutation, { data, loading, error }] = useGroupFormPage_UpsertGroupMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      name: // value for 'name'
 *      integrated: // value for 'integrated'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useGroupFormPage_UpsertGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GroupFormPage_UpsertGroupMutation, GroupFormPage_UpsertGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<GroupFormPage_UpsertGroupMutation, GroupFormPage_UpsertGroupMutationVariables>(GroupFormPage_UpsertGroupDocument, baseOptions);
      }
export type GroupFormPage_UpsertGroupMutationHookResult = ReturnType<typeof useGroupFormPage_UpsertGroupMutation>;
export type GroupFormPage_UpsertGroupMutationResult = ApolloReactCommon.MutationResult<GroupFormPage_UpsertGroupMutation>;
export type GroupFormPage_UpsertGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<GroupFormPage_UpsertGroupMutation, GroupFormPage_UpsertGroupMutationVariables>;
export const GroupFormPage_GroupDocument = gql`
    query GroupFormPage_group($uuid: ID!) {
  group(uuid: $uuid) {
    uuid
    name
    integrated
    active
  }
}
    `;

/**
 * __useGroupFormPage_GroupQuery__
 *
 * To run a query within a React component, call `useGroupFormPage_GroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupFormPage_GroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupFormPage_GroupQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGroupFormPage_GroupQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GroupFormPage_GroupQuery, GroupFormPage_GroupQueryVariables>) {
        return ApolloReactHooks.useQuery<GroupFormPage_GroupQuery, GroupFormPage_GroupQueryVariables>(GroupFormPage_GroupDocument, baseOptions);
      }
export function useGroupFormPage_GroupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GroupFormPage_GroupQuery, GroupFormPage_GroupQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GroupFormPage_GroupQuery, GroupFormPage_GroupQueryVariables>(GroupFormPage_GroupDocument, baseOptions);
        }
export type GroupFormPage_GroupQueryHookResult = ReturnType<typeof useGroupFormPage_GroupQuery>;
export type GroupFormPage_GroupLazyQueryHookResult = ReturnType<typeof useGroupFormPage_GroupLazyQuery>;
export type GroupFormPage_GroupQueryResult = ApolloReactCommon.QueryResult<GroupFormPage_GroupQuery, GroupFormPage_GroupQueryVariables>;
export const GroupTablePage_GroupsDocument = gql`
    query GroupTablePage_groups {
  groups {
    uuid
    name
  }
}
    `;

/**
 * __useGroupTablePage_GroupsQuery__
 *
 * To run a query within a React component, call `useGroupTablePage_GroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupTablePage_GroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupTablePage_GroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGroupTablePage_GroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GroupTablePage_GroupsQuery, GroupTablePage_GroupsQueryVariables>) {
        return ApolloReactHooks.useQuery<GroupTablePage_GroupsQuery, GroupTablePage_GroupsQueryVariables>(GroupTablePage_GroupsDocument, baseOptions);
      }
export function useGroupTablePage_GroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GroupTablePage_GroupsQuery, GroupTablePage_GroupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GroupTablePage_GroupsQuery, GroupTablePage_GroupsQueryVariables>(GroupTablePage_GroupsDocument, baseOptions);
        }
export type GroupTablePage_GroupsQueryHookResult = ReturnType<typeof useGroupTablePage_GroupsQuery>;
export type GroupTablePage_GroupsLazyQueryHookResult = ReturnType<typeof useGroupTablePage_GroupsLazyQuery>;
export type GroupTablePage_GroupsQueryResult = ApolloReactCommon.QueryResult<GroupTablePage_GroupsQuery, GroupTablePage_GroupsQueryVariables>;
export const GroupTablePage_DeleteGroupDocument = gql`
    mutation GroupTablePage_deleteGroup($uuid: ID!) {
  deleteService(uuid: $uuid)
}
    `;
export type GroupTablePage_DeleteGroupMutationFn = ApolloReactCommon.MutationFunction<GroupTablePage_DeleteGroupMutation, GroupTablePage_DeleteGroupMutationVariables>;

/**
 * __useGroupTablePage_DeleteGroupMutation__
 *
 * To run a mutation, you first call `useGroupTablePage_DeleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupTablePage_DeleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupTablePageDeleteGroupMutation, { data, loading, error }] = useGroupTablePage_DeleteGroupMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGroupTablePage_DeleteGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GroupTablePage_DeleteGroupMutation, GroupTablePage_DeleteGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<GroupTablePage_DeleteGroupMutation, GroupTablePage_DeleteGroupMutationVariables>(GroupTablePage_DeleteGroupDocument, baseOptions);
      }
export type GroupTablePage_DeleteGroupMutationHookResult = ReturnType<typeof useGroupTablePage_DeleteGroupMutation>;
export type GroupTablePage_DeleteGroupMutationResult = ApolloReactCommon.MutationResult<GroupTablePage_DeleteGroupMutation>;
export type GroupTablePage_DeleteGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<GroupTablePage_DeleteGroupMutation, GroupTablePage_DeleteGroupMutationVariables>;
export const KitFormPage_UpsertKitDocument = gql`
    mutation KitFormPage_upsertKit($uuid: ID, $kitCatalogId: ID!, $systemCode: String, $registrationPremium: Float, $premium1: Float, $premium2: Float, $premium3: Float, $premium4: Float, $premium5: Float, $premium6: Float, $dealershipIds: [ID!]!) {
  upsertKit(
    uuid: $uuid
    kitCatalogId: $kitCatalogId
    systemCode: $systemCode
    registrationPremium: $registrationPremium
    premium1: $premium1
    premium2: $premium2
    premium3: $premium3
    premium4: $premium4
    premium5: $premium5
    premium6: $premium6
    dealershipIds: $dealershipIds
  )
}
    `;
export type KitFormPage_UpsertKitMutationFn = ApolloReactCommon.MutationFunction<KitFormPage_UpsertKitMutation, KitFormPage_UpsertKitMutationVariables>;

/**
 * __useKitFormPage_UpsertKitMutation__
 *
 * To run a mutation, you first call `useKitFormPage_UpsertKitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKitFormPage_UpsertKitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kitFormPageUpsertKitMutation, { data, loading, error }] = useKitFormPage_UpsertKitMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      kitCatalogId: // value for 'kitCatalogId'
 *      systemCode: // value for 'systemCode'
 *      registrationPremium: // value for 'registrationPremium'
 *      premium1: // value for 'premium1'
 *      premium2: // value for 'premium2'
 *      premium3: // value for 'premium3'
 *      premium4: // value for 'premium4'
 *      premium5: // value for 'premium5'
 *      premium6: // value for 'premium6'
 *      dealershipIds: // value for 'dealershipIds'
 *   },
 * });
 */
export function useKitFormPage_UpsertKitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<KitFormPage_UpsertKitMutation, KitFormPage_UpsertKitMutationVariables>) {
        return ApolloReactHooks.useMutation<KitFormPage_UpsertKitMutation, KitFormPage_UpsertKitMutationVariables>(KitFormPage_UpsertKitDocument, baseOptions);
      }
export type KitFormPage_UpsertKitMutationHookResult = ReturnType<typeof useKitFormPage_UpsertKitMutation>;
export type KitFormPage_UpsertKitMutationResult = ApolloReactCommon.MutationResult<KitFormPage_UpsertKitMutation>;
export type KitFormPage_UpsertKitMutationOptions = ApolloReactCommon.BaseMutationOptions<KitFormPage_UpsertKitMutation, KitFormPage_UpsertKitMutationVariables>;
export const KitFormPage_KitDocument = gql`
    query KitFormPage_kit($uuid: ID!) {
  kit(uuid: $uuid) {
    uuid
    KitCatalog {
      uuid
      name
      hierarchy
    }
    systemCode
    registrationPremium
    premium1
    premium2
    premium3
    premium4
    premium5
    premium6
    Dealerships {
      uuid
      fantasyName
    }
  }
}
    `;

/**
 * __useKitFormPage_KitQuery__
 *
 * To run a query within a React component, call `useKitFormPage_KitQuery` and pass it any options that fit your needs.
 * When your component renders, `useKitFormPage_KitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKitFormPage_KitQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useKitFormPage_KitQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KitFormPage_KitQuery, KitFormPage_KitQueryVariables>) {
        return ApolloReactHooks.useQuery<KitFormPage_KitQuery, KitFormPage_KitQueryVariables>(KitFormPage_KitDocument, baseOptions);
      }
export function useKitFormPage_KitLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KitFormPage_KitQuery, KitFormPage_KitQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KitFormPage_KitQuery, KitFormPage_KitQueryVariables>(KitFormPage_KitDocument, baseOptions);
        }
export type KitFormPage_KitQueryHookResult = ReturnType<typeof useKitFormPage_KitQuery>;
export type KitFormPage_KitLazyQueryHookResult = ReturnType<typeof useKitFormPage_KitLazyQuery>;
export type KitFormPage_KitQueryResult = ApolloReactCommon.QueryResult<KitFormPage_KitQuery, KitFormPage_KitQueryVariables>;
export const KitFormPage_InitialDataDocument = gql`
    query KitFormPage_initialData($groupId: ID!) {
  group(uuid: $groupId) {
    uuid
    PremiumDescription {
      premium1
      premium2
      premium3
      premium4
      premium5
      premium6
    }
    Dealerships {
      uuid
      fantasyName
    }
  }
  kitCatalogs {
    uuid
    hierarchy
    name
  }
}
    `;

/**
 * __useKitFormPage_InitialDataQuery__
 *
 * To run a query within a React component, call `useKitFormPage_InitialDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useKitFormPage_InitialDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKitFormPage_InitialDataQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useKitFormPage_InitialDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KitFormPage_InitialDataQuery, KitFormPage_InitialDataQueryVariables>) {
        return ApolloReactHooks.useQuery<KitFormPage_InitialDataQuery, KitFormPage_InitialDataQueryVariables>(KitFormPage_InitialDataDocument, baseOptions);
      }
export function useKitFormPage_InitialDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KitFormPage_InitialDataQuery, KitFormPage_InitialDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KitFormPage_InitialDataQuery, KitFormPage_InitialDataQueryVariables>(KitFormPage_InitialDataDocument, baseOptions);
        }
export type KitFormPage_InitialDataQueryHookResult = ReturnType<typeof useKitFormPage_InitialDataQuery>;
export type KitFormPage_InitialDataLazyQueryHookResult = ReturnType<typeof useKitFormPage_InitialDataLazyQuery>;
export type KitFormPage_InitialDataQueryResult = ApolloReactCommon.QueryResult<KitFormPage_InitialDataQuery, KitFormPage_InitialDataQueryVariables>;
export const KitTablePage_KitsDocument = gql`
    query KitTablePage_kits($groupId: ID, $dealershipId: ID) {
  kits(groupId: $groupId, dealershipId: $dealershipId) {
    uuid
    KitCatalog {
      uuid
      name
    }
    systemCode
  }
}
    `;

/**
 * __useKitTablePage_KitsQuery__
 *
 * To run a query within a React component, call `useKitTablePage_KitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKitTablePage_KitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKitTablePage_KitsQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      dealershipId: // value for 'dealershipId'
 *   },
 * });
 */
export function useKitTablePage_KitsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KitTablePage_KitsQuery, KitTablePage_KitsQueryVariables>) {
        return ApolloReactHooks.useQuery<KitTablePage_KitsQuery, KitTablePage_KitsQueryVariables>(KitTablePage_KitsDocument, baseOptions);
      }
export function useKitTablePage_KitsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KitTablePage_KitsQuery, KitTablePage_KitsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KitTablePage_KitsQuery, KitTablePage_KitsQueryVariables>(KitTablePage_KitsDocument, baseOptions);
        }
export type KitTablePage_KitsQueryHookResult = ReturnType<typeof useKitTablePage_KitsQuery>;
export type KitTablePage_KitsLazyQueryHookResult = ReturnType<typeof useKitTablePage_KitsLazyQuery>;
export type KitTablePage_KitsQueryResult = ApolloReactCommon.QueryResult<KitTablePage_KitsQuery, KitTablePage_KitsQueryVariables>;
export const KitTablePage_DeleteKitDocument = gql`
    mutation KitTablePage_deleteKit($uuid: ID!) {
  deleteKit(uuid: $uuid)
}
    `;
export type KitTablePage_DeleteKitMutationFn = ApolloReactCommon.MutationFunction<KitTablePage_DeleteKitMutation, KitTablePage_DeleteKitMutationVariables>;

/**
 * __useKitTablePage_DeleteKitMutation__
 *
 * To run a mutation, you first call `useKitTablePage_DeleteKitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKitTablePage_DeleteKitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kitTablePageDeleteKitMutation, { data, loading, error }] = useKitTablePage_DeleteKitMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useKitTablePage_DeleteKitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<KitTablePage_DeleteKitMutation, KitTablePage_DeleteKitMutationVariables>) {
        return ApolloReactHooks.useMutation<KitTablePage_DeleteKitMutation, KitTablePage_DeleteKitMutationVariables>(KitTablePage_DeleteKitDocument, baseOptions);
      }
export type KitTablePage_DeleteKitMutationHookResult = ReturnType<typeof useKitTablePage_DeleteKitMutation>;
export type KitTablePage_DeleteKitMutationResult = ApolloReactCommon.MutationResult<KitTablePage_DeleteKitMutation>;
export type KitTablePage_DeleteKitMutationOptions = ApolloReactCommon.BaseMutationOptions<KitTablePage_DeleteKitMutation, KitTablePage_DeleteKitMutationVariables>;
export const PremiumDescriptionFormPage_UpsertPremiumDescriptionDocument = gql`
    mutation PremiumDescriptionFormPage_upsertPremiumDescription($groupId: ID!, $premium1: String, $premium2: String, $premium3: String, $premium4: String, $premium5: String, $premium6: String) {
  upsertPremiumDescription(
    groupId: $groupId
    premium1: $premium1
    premium2: $premium2
    premium3: $premium3
    premium4: $premium4
    premium5: $premium5
    premium6: $premium6
  )
}
    `;
export type PremiumDescriptionFormPage_UpsertPremiumDescriptionMutationFn = ApolloReactCommon.MutationFunction<PremiumDescriptionFormPage_UpsertPremiumDescriptionMutation, PremiumDescriptionFormPage_UpsertPremiumDescriptionMutationVariables>;

/**
 * __usePremiumDescriptionFormPage_UpsertPremiumDescriptionMutation__
 *
 * To run a mutation, you first call `usePremiumDescriptionFormPage_UpsertPremiumDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePremiumDescriptionFormPage_UpsertPremiumDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [premiumDescriptionFormPageUpsertPremiumDescriptionMutation, { data, loading, error }] = usePremiumDescriptionFormPage_UpsertPremiumDescriptionMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      premium1: // value for 'premium1'
 *      premium2: // value for 'premium2'
 *      premium3: // value for 'premium3'
 *      premium4: // value for 'premium4'
 *      premium5: // value for 'premium5'
 *      premium6: // value for 'premium6'
 *   },
 * });
 */
export function usePremiumDescriptionFormPage_UpsertPremiumDescriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PremiumDescriptionFormPage_UpsertPremiumDescriptionMutation, PremiumDescriptionFormPage_UpsertPremiumDescriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<PremiumDescriptionFormPage_UpsertPremiumDescriptionMutation, PremiumDescriptionFormPage_UpsertPremiumDescriptionMutationVariables>(PremiumDescriptionFormPage_UpsertPremiumDescriptionDocument, baseOptions);
      }
export type PremiumDescriptionFormPage_UpsertPremiumDescriptionMutationHookResult = ReturnType<typeof usePremiumDescriptionFormPage_UpsertPremiumDescriptionMutation>;
export type PremiumDescriptionFormPage_UpsertPremiumDescriptionMutationResult = ApolloReactCommon.MutationResult<PremiumDescriptionFormPage_UpsertPremiumDescriptionMutation>;
export type PremiumDescriptionFormPage_UpsertPremiumDescriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<PremiumDescriptionFormPage_UpsertPremiumDescriptionMutation, PremiumDescriptionFormPage_UpsertPremiumDescriptionMutationVariables>;
export const PremiumDescriptionFormPage_GroupDocument = gql`
    query PremiumDescriptionFormPage_group($uuid: ID!) {
  group(uuid: $uuid) {
    uuid
    PremiumDescription {
      premium1
      premium2
      premium3
      premium4
      premium5
      premium6
    }
  }
}
    `;

/**
 * __usePremiumDescriptionFormPage_GroupQuery__
 *
 * To run a query within a React component, call `usePremiumDescriptionFormPage_GroupQuery` and pass it any options that fit your needs.
 * When your component renders, `usePremiumDescriptionFormPage_GroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePremiumDescriptionFormPage_GroupQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function usePremiumDescriptionFormPage_GroupQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PremiumDescriptionFormPage_GroupQuery, PremiumDescriptionFormPage_GroupQueryVariables>) {
        return ApolloReactHooks.useQuery<PremiumDescriptionFormPage_GroupQuery, PremiumDescriptionFormPage_GroupQueryVariables>(PremiumDescriptionFormPage_GroupDocument, baseOptions);
      }
export function usePremiumDescriptionFormPage_GroupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PremiumDescriptionFormPage_GroupQuery, PremiumDescriptionFormPage_GroupQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PremiumDescriptionFormPage_GroupQuery, PremiumDescriptionFormPage_GroupQueryVariables>(PremiumDescriptionFormPage_GroupDocument, baseOptions);
        }
export type PremiumDescriptionFormPage_GroupQueryHookResult = ReturnType<typeof usePremiumDescriptionFormPage_GroupQuery>;
export type PremiumDescriptionFormPage_GroupLazyQueryHookResult = ReturnType<typeof usePremiumDescriptionFormPage_GroupLazyQuery>;
export type PremiumDescriptionFormPage_GroupQueryResult = ApolloReactCommon.QueryResult<PremiumDescriptionFormPage_GroupQuery, PremiumDescriptionFormPage_GroupQueryVariables>;
export const ProductCatalogFormPage_UpsertProductCatalogDocument = gql`
    mutation ProductCatalogFormPage_upsertProductCatalog($uuid: ID, $name: String, $code: String, $Manufacturer: Manufacturers) {
  upsertProductCatalog(
    uuid: $uuid
    name: $name
    code: $code
    Manufacturer: $Manufacturer
  )
}
    `;
export type ProductCatalogFormPage_UpsertProductCatalogMutationFn = ApolloReactCommon.MutationFunction<ProductCatalogFormPage_UpsertProductCatalogMutation, ProductCatalogFormPage_UpsertProductCatalogMutationVariables>;

/**
 * __useProductCatalogFormPage_UpsertProductCatalogMutation__
 *
 * To run a mutation, you first call `useProductCatalogFormPage_UpsertProductCatalogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductCatalogFormPage_UpsertProductCatalogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productCatalogFormPageUpsertProductCatalogMutation, { data, loading, error }] = useProductCatalogFormPage_UpsertProductCatalogMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      name: // value for 'name'
 *      code: // value for 'code'
 *      Manufacturer: // value for 'Manufacturer'
 *   },
 * });
 */
export function useProductCatalogFormPage_UpsertProductCatalogMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ProductCatalogFormPage_UpsertProductCatalogMutation, ProductCatalogFormPage_UpsertProductCatalogMutationVariables>) {
        return ApolloReactHooks.useMutation<ProductCatalogFormPage_UpsertProductCatalogMutation, ProductCatalogFormPage_UpsertProductCatalogMutationVariables>(ProductCatalogFormPage_UpsertProductCatalogDocument, baseOptions);
      }
export type ProductCatalogFormPage_UpsertProductCatalogMutationHookResult = ReturnType<typeof useProductCatalogFormPage_UpsertProductCatalogMutation>;
export type ProductCatalogFormPage_UpsertProductCatalogMutationResult = ApolloReactCommon.MutationResult<ProductCatalogFormPage_UpsertProductCatalogMutation>;
export type ProductCatalogFormPage_UpsertProductCatalogMutationOptions = ApolloReactCommon.BaseMutationOptions<ProductCatalogFormPage_UpsertProductCatalogMutation, ProductCatalogFormPage_UpsertProductCatalogMutationVariables>;
export const ProductCatalogFormPage_ProductCatalogDocument = gql`
    query ProductCatalogFormPage_productCatalog($uuid: ID!) {
  productCatalog(uuid: $uuid) {
    uuid
    name
    Manufacturer
    code
  }
}
    `;

/**
 * __useProductCatalogFormPage_ProductCatalogQuery__
 *
 * To run a query within a React component, call `useProductCatalogFormPage_ProductCatalogQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCatalogFormPage_ProductCatalogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCatalogFormPage_ProductCatalogQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useProductCatalogFormPage_ProductCatalogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProductCatalogFormPage_ProductCatalogQuery, ProductCatalogFormPage_ProductCatalogQueryVariables>) {
        return ApolloReactHooks.useQuery<ProductCatalogFormPage_ProductCatalogQuery, ProductCatalogFormPage_ProductCatalogQueryVariables>(ProductCatalogFormPage_ProductCatalogDocument, baseOptions);
      }
export function useProductCatalogFormPage_ProductCatalogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductCatalogFormPage_ProductCatalogQuery, ProductCatalogFormPage_ProductCatalogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProductCatalogFormPage_ProductCatalogQuery, ProductCatalogFormPage_ProductCatalogQueryVariables>(ProductCatalogFormPage_ProductCatalogDocument, baseOptions);
        }
export type ProductCatalogFormPage_ProductCatalogQueryHookResult = ReturnType<typeof useProductCatalogFormPage_ProductCatalogQuery>;
export type ProductCatalogFormPage_ProductCatalogLazyQueryHookResult = ReturnType<typeof useProductCatalogFormPage_ProductCatalogLazyQuery>;
export type ProductCatalogFormPage_ProductCatalogQueryResult = ApolloReactCommon.QueryResult<ProductCatalogFormPage_ProductCatalogQuery, ProductCatalogFormPage_ProductCatalogQueryVariables>;
export const ProductCatalogsPage_ProductCatalogsDocument = gql`
    query ProductCatalogsPage_productCatalogs($Manufacturer: Manufacturers) {
  productCatalogs(Manufacturer: $Manufacturer) {
    uuid
    name
    Manufacturer
    code
  }
}
    `;

/**
 * __useProductCatalogsPage_ProductCatalogsQuery__
 *
 * To run a query within a React component, call `useProductCatalogsPage_ProductCatalogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCatalogsPage_ProductCatalogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCatalogsPage_ProductCatalogsQuery({
 *   variables: {
 *      Manufacturer: // value for 'Manufacturer'
 *   },
 * });
 */
export function useProductCatalogsPage_ProductCatalogsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProductCatalogsPage_ProductCatalogsQuery, ProductCatalogsPage_ProductCatalogsQueryVariables>) {
        return ApolloReactHooks.useQuery<ProductCatalogsPage_ProductCatalogsQuery, ProductCatalogsPage_ProductCatalogsQueryVariables>(ProductCatalogsPage_ProductCatalogsDocument, baseOptions);
      }
export function useProductCatalogsPage_ProductCatalogsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductCatalogsPage_ProductCatalogsQuery, ProductCatalogsPage_ProductCatalogsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProductCatalogsPage_ProductCatalogsQuery, ProductCatalogsPage_ProductCatalogsQueryVariables>(ProductCatalogsPage_ProductCatalogsDocument, baseOptions);
        }
export type ProductCatalogsPage_ProductCatalogsQueryHookResult = ReturnType<typeof useProductCatalogsPage_ProductCatalogsQuery>;
export type ProductCatalogsPage_ProductCatalogsLazyQueryHookResult = ReturnType<typeof useProductCatalogsPage_ProductCatalogsLazyQuery>;
export type ProductCatalogsPage_ProductCatalogsQueryResult = ApolloReactCommon.QueryResult<ProductCatalogsPage_ProductCatalogsQuery, ProductCatalogsPage_ProductCatalogsQueryVariables>;
export const ProductFormPage_UpsertProductDocument = gql`
    mutation ProductFormPage_upsertProduct($uuid: ID, $active: Boolean, $productCatalogId: ID, $productCode: String, $serviceCode: String, $kitId: ID, $salePrice: Float, $serviceSalePrice: Float, $premium1: Float, $premium2: Float, $premium3: Float, $premium4: Float, $premium5: Float, $premium6: Float, $costPrice: Float, $registrationPremium: Float, $dealershipIds: [ID!]!, $PremiumCalculationType: String!) {
  upsertProduct(
    uuid: $uuid
    active: $active
    productCatalogId: $productCatalogId
    productCode: $productCode
    serviceCode: $serviceCode
    kitId: $kitId
    serviceSalePrice: $serviceSalePrice
    salePrice: $salePrice
    premium1: $premium1
    premium2: $premium2
    premium3: $premium3
    premium4: $premium4
    premium5: $premium5
    premium6: $premium6
    costPrice: $costPrice
    registrationPremium: $registrationPremium
    dealershipIds: $dealershipIds
    PremiumCalculationType: $PremiumCalculationType
  )
}
    `;
export type ProductFormPage_UpsertProductMutationFn = ApolloReactCommon.MutationFunction<ProductFormPage_UpsertProductMutation, ProductFormPage_UpsertProductMutationVariables>;

/**
 * __useProductFormPage_UpsertProductMutation__
 *
 * To run a mutation, you first call `useProductFormPage_UpsertProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductFormPage_UpsertProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productFormPageUpsertProductMutation, { data, loading, error }] = useProductFormPage_UpsertProductMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      active: // value for 'active'
 *      productCatalogId: // value for 'productCatalogId'
 *      productCode: // value for 'productCode'
 *      serviceCode: // value for 'serviceCode'
 *      kitId: // value for 'kitId'
 *      salePrice: // value for 'salePrice'
 *      serviceSalePrice: // value for 'serviceSalePrice'
 *      premium1: // value for 'premium1'
 *      premium2: // value for 'premium2'
 *      premium3: // value for 'premium3'
 *      premium4: // value for 'premium4'
 *      premium5: // value for 'premium5'
 *      premium6: // value for 'premium6'
 *      costPrice: // value for 'costPrice'
 *      registrationPremium: // value for 'registrationPremium'
 *      dealershipIds: // value for 'dealershipIds'
 *      PremiumCalculationType: // value for 'PremiumCalculationType'
 *   },
 * });
 */
export function useProductFormPage_UpsertProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ProductFormPage_UpsertProductMutation, ProductFormPage_UpsertProductMutationVariables>) {
        return ApolloReactHooks.useMutation<ProductFormPage_UpsertProductMutation, ProductFormPage_UpsertProductMutationVariables>(ProductFormPage_UpsertProductDocument, baseOptions);
      }
export type ProductFormPage_UpsertProductMutationHookResult = ReturnType<typeof useProductFormPage_UpsertProductMutation>;
export type ProductFormPage_UpsertProductMutationResult = ApolloReactCommon.MutationResult<ProductFormPage_UpsertProductMutation>;
export type ProductFormPage_UpsertProductMutationOptions = ApolloReactCommon.BaseMutationOptions<ProductFormPage_UpsertProductMutation, ProductFormPage_UpsertProductMutationVariables>;
export const ProductFormPage_ProductDocument = gql`
    query ProductFormPage_product($uuid: ID!) {
  product(uuid: $uuid) {
    uuid
    active
    ProductCatalog {
      uuid
      name
      code
      Manufacturer
    }
    costPrice
    registrationPremium
    productCode
    serviceCode
    serviceSalePrice
    salePrice
    premium1
    premium2
    premium3
    premium4
    premium5
    premium6
    PremiumCalculationType
    Kit {
      uuid
      KitCatalog {
        uuid
        name
        hierarchy
      }
    }
    Dealerships {
      uuid
      fantasyName
    }
  }
}
    `;

/**
 * __useProductFormPage_ProductQuery__
 *
 * To run a query within a React component, call `useProductFormPage_ProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductFormPage_ProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductFormPage_ProductQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useProductFormPage_ProductQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProductFormPage_ProductQuery, ProductFormPage_ProductQueryVariables>) {
        return ApolloReactHooks.useQuery<ProductFormPage_ProductQuery, ProductFormPage_ProductQueryVariables>(ProductFormPage_ProductDocument, baseOptions);
      }
export function useProductFormPage_ProductLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductFormPage_ProductQuery, ProductFormPage_ProductQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProductFormPage_ProductQuery, ProductFormPage_ProductQueryVariables>(ProductFormPage_ProductDocument, baseOptions);
        }
export type ProductFormPage_ProductQueryHookResult = ReturnType<typeof useProductFormPage_ProductQuery>;
export type ProductFormPage_ProductLazyQueryHookResult = ReturnType<typeof useProductFormPage_ProductLazyQuery>;
export type ProductFormPage_ProductQueryResult = ApolloReactCommon.QueryResult<ProductFormPage_ProductQuery, ProductFormPage_ProductQueryVariables>;
export const ProductFormPage_ProductCatalogsDocument = gql`
    query ProductFormPage_productCatalogs($Manufacturer: Manufacturers) {
  productCatalogs(Manufacturer: $Manufacturer) {
    uuid
    name
    Manufacturer
    code
  }
}
    `;

/**
 * __useProductFormPage_ProductCatalogsQuery__
 *
 * To run a query within a React component, call `useProductFormPage_ProductCatalogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductFormPage_ProductCatalogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductFormPage_ProductCatalogsQuery({
 *   variables: {
 *      Manufacturer: // value for 'Manufacturer'
 *   },
 * });
 */
export function useProductFormPage_ProductCatalogsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProductFormPage_ProductCatalogsQuery, ProductFormPage_ProductCatalogsQueryVariables>) {
        return ApolloReactHooks.useQuery<ProductFormPage_ProductCatalogsQuery, ProductFormPage_ProductCatalogsQueryVariables>(ProductFormPage_ProductCatalogsDocument, baseOptions);
      }
export function useProductFormPage_ProductCatalogsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductFormPage_ProductCatalogsQuery, ProductFormPage_ProductCatalogsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProductFormPage_ProductCatalogsQuery, ProductFormPage_ProductCatalogsQueryVariables>(ProductFormPage_ProductCatalogsDocument, baseOptions);
        }
export type ProductFormPage_ProductCatalogsQueryHookResult = ReturnType<typeof useProductFormPage_ProductCatalogsQuery>;
export type ProductFormPage_ProductCatalogsLazyQueryHookResult = ReturnType<typeof useProductFormPage_ProductCatalogsLazyQuery>;
export type ProductFormPage_ProductCatalogsQueryResult = ApolloReactCommon.QueryResult<ProductFormPage_ProductCatalogsQuery, ProductFormPage_ProductCatalogsQueryVariables>;
export const ProductFormPage_InitialDataDocument = gql`
    query ProductFormPage_initialData($groupId: ID!) {
  group(uuid: $groupId) {
    uuid
    PremiumDescription {
      premium1
      premium2
      premium3
      premium4
      premium5
      premium6
    }
    Dealerships {
      uuid
      fantasyName
    }
  }
  kits(groupId: $groupId) {
    uuid
    KitCatalog {
      uuid
      name
      hierarchy
    }
  }
}
    `;

/**
 * __useProductFormPage_InitialDataQuery__
 *
 * To run a query within a React component, call `useProductFormPage_InitialDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductFormPage_InitialDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductFormPage_InitialDataQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useProductFormPage_InitialDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProductFormPage_InitialDataQuery, ProductFormPage_InitialDataQueryVariables>) {
        return ApolloReactHooks.useQuery<ProductFormPage_InitialDataQuery, ProductFormPage_InitialDataQueryVariables>(ProductFormPage_InitialDataDocument, baseOptions);
      }
export function useProductFormPage_InitialDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductFormPage_InitialDataQuery, ProductFormPage_InitialDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProductFormPage_InitialDataQuery, ProductFormPage_InitialDataQueryVariables>(ProductFormPage_InitialDataDocument, baseOptions);
        }
export type ProductFormPage_InitialDataQueryHookResult = ReturnType<typeof useProductFormPage_InitialDataQuery>;
export type ProductFormPage_InitialDataLazyQueryHookResult = ReturnType<typeof useProductFormPage_InitialDataLazyQuery>;
export type ProductFormPage_InitialDataQueryResult = ApolloReactCommon.QueryResult<ProductFormPage_InitialDataQuery, ProductFormPage_InitialDataQueryVariables>;
export const ProductTablePage_ProductsDocument = gql`
    query ProductTablePage_products($groupId: ID, $dealershipId: ID, $Manufacturer: Manufacturers, $PremiumCalculationType: PremiumCalculationType) {
  products(
    groupId: $groupId
    dealershipId: $dealershipId
    Manufacturer: $Manufacturer
    PremiumCalculationType: $PremiumCalculationType
  ) {
    uuid
    ProductCatalog {
      uuid
      name
    }
    productCode
  }
}
    `;

/**
 * __useProductTablePage_ProductsQuery__
 *
 * To run a query within a React component, call `useProductTablePage_ProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductTablePage_ProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductTablePage_ProductsQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      dealershipId: // value for 'dealershipId'
 *      Manufacturer: // value for 'Manufacturer'
 *      PremiumCalculationType: // value for 'PremiumCalculationType'
 *   },
 * });
 */
export function useProductTablePage_ProductsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProductTablePage_ProductsQuery, ProductTablePage_ProductsQueryVariables>) {
        return ApolloReactHooks.useQuery<ProductTablePage_ProductsQuery, ProductTablePage_ProductsQueryVariables>(ProductTablePage_ProductsDocument, baseOptions);
      }
export function useProductTablePage_ProductsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductTablePage_ProductsQuery, ProductTablePage_ProductsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProductTablePage_ProductsQuery, ProductTablePage_ProductsQueryVariables>(ProductTablePage_ProductsDocument, baseOptions);
        }
export type ProductTablePage_ProductsQueryHookResult = ReturnType<typeof useProductTablePage_ProductsQuery>;
export type ProductTablePage_ProductsLazyQueryHookResult = ReturnType<typeof useProductTablePage_ProductsLazyQuery>;
export type ProductTablePage_ProductsQueryResult = ApolloReactCommon.QueryResult<ProductTablePage_ProductsQuery, ProductTablePage_ProductsQueryVariables>;
export const ProductTablePage_DeleteProductDocument = gql`
    mutation ProductTablePage_deleteProduct($uuid: ID!) {
  deleteProduct(uuid: $uuid)
}
    `;
export type ProductTablePage_DeleteProductMutationFn = ApolloReactCommon.MutationFunction<ProductTablePage_DeleteProductMutation, ProductTablePage_DeleteProductMutationVariables>;

/**
 * __useProductTablePage_DeleteProductMutation__
 *
 * To run a mutation, you first call `useProductTablePage_DeleteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductTablePage_DeleteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productTablePageDeleteProductMutation, { data, loading, error }] = useProductTablePage_DeleteProductMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useProductTablePage_DeleteProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ProductTablePage_DeleteProductMutation, ProductTablePage_DeleteProductMutationVariables>) {
        return ApolloReactHooks.useMutation<ProductTablePage_DeleteProductMutation, ProductTablePage_DeleteProductMutationVariables>(ProductTablePage_DeleteProductDocument, baseOptions);
      }
export type ProductTablePage_DeleteProductMutationHookResult = ReturnType<typeof useProductTablePage_DeleteProductMutation>;
export type ProductTablePage_DeleteProductMutationResult = ApolloReactCommon.MutationResult<ProductTablePage_DeleteProductMutation>;
export type ProductTablePage_DeleteProductMutationOptions = ApolloReactCommon.BaseMutationOptions<ProductTablePage_DeleteProductMutation, ProductTablePage_DeleteProductMutationVariables>;
export const SellersFormPage_UpsertSellerDocument = gql`
    mutation SellersFormPage_upsertSeller($uuid: ID, $active: Boolean, $name: String, $cpf: String, $erpCode: String, $email: String, $sellerId: ID) {
  upsertSeller(
    uuid: $uuid
    active: $active
    name: $name
    cpf: $cpf
    erpCode: $erpCode
    email: $email
    sellerId: $sellerId
  )
}
    `;
export type SellersFormPage_UpsertSellerMutationFn = ApolloReactCommon.MutationFunction<SellersFormPage_UpsertSellerMutation, SellersFormPage_UpsertSellerMutationVariables>;

/**
 * __useSellersFormPage_UpsertSellerMutation__
 *
 * To run a mutation, you first call `useSellersFormPage_UpsertSellerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSellersFormPage_UpsertSellerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sellersFormPageUpsertSellerMutation, { data, loading, error }] = useSellersFormPage_UpsertSellerMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      active: // value for 'active'
 *      name: // value for 'name'
 *      cpf: // value for 'cpf'
 *      erpCode: // value for 'erpCode'
 *      email: // value for 'email'
 *      sellerId: // value for 'sellerId'
 *   },
 * });
 */
export function useSellersFormPage_UpsertSellerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SellersFormPage_UpsertSellerMutation, SellersFormPage_UpsertSellerMutationVariables>) {
        return ApolloReactHooks.useMutation<SellersFormPage_UpsertSellerMutation, SellersFormPage_UpsertSellerMutationVariables>(SellersFormPage_UpsertSellerDocument, baseOptions);
      }
export type SellersFormPage_UpsertSellerMutationHookResult = ReturnType<typeof useSellersFormPage_UpsertSellerMutation>;
export type SellersFormPage_UpsertSellerMutationResult = ApolloReactCommon.MutationResult<SellersFormPage_UpsertSellerMutation>;
export type SellersFormPage_UpsertSellerMutationOptions = ApolloReactCommon.BaseMutationOptions<SellersFormPage_UpsertSellerMutation, SellersFormPage_UpsertSellerMutationVariables>;
export const SellersFormPage_SellersDocument = gql`
    query SellersFormPage_sellers {
  sellers {
    uuid
    name
  }
}
    `;

/**
 * __useSellersFormPage_SellersQuery__
 *
 * To run a query within a React component, call `useSellersFormPage_SellersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellersFormPage_SellersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellersFormPage_SellersQuery({
 *   variables: {
 *   },
 * });
 */
export function useSellersFormPage_SellersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SellersFormPage_SellersQuery, SellersFormPage_SellersQueryVariables>) {
        return ApolloReactHooks.useQuery<SellersFormPage_SellersQuery, SellersFormPage_SellersQueryVariables>(SellersFormPage_SellersDocument, baseOptions);
      }
export function useSellersFormPage_SellersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SellersFormPage_SellersQuery, SellersFormPage_SellersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SellersFormPage_SellersQuery, SellersFormPage_SellersQueryVariables>(SellersFormPage_SellersDocument, baseOptions);
        }
export type SellersFormPage_SellersQueryHookResult = ReturnType<typeof useSellersFormPage_SellersQuery>;
export type SellersFormPage_SellersLazyQueryHookResult = ReturnType<typeof useSellersFormPage_SellersLazyQuery>;
export type SellersFormPage_SellersQueryResult = ApolloReactCommon.QueryResult<SellersFormPage_SellersQuery, SellersFormPage_SellersQueryVariables>;
export const SellersFormPage_SellerDocument = gql`
    query SellersFormPage_seller($uuid: ID!) {
  seller(uuid: $uuid) {
    uuid
    active
    name
    cpf
    email
    erpCode
    Manager {
      uuid
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useSellersFormPage_SellerQuery__
 *
 * To run a query within a React component, call `useSellersFormPage_SellerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellersFormPage_SellerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellersFormPage_SellerQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSellersFormPage_SellerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SellersFormPage_SellerQuery, SellersFormPage_SellerQueryVariables>) {
        return ApolloReactHooks.useQuery<SellersFormPage_SellerQuery, SellersFormPage_SellerQueryVariables>(SellersFormPage_SellerDocument, baseOptions);
      }
export function useSellersFormPage_SellerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SellersFormPage_SellerQuery, SellersFormPage_SellerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SellersFormPage_SellerQuery, SellersFormPage_SellerQueryVariables>(SellersFormPage_SellerDocument, baseOptions);
        }
export type SellersFormPage_SellerQueryHookResult = ReturnType<typeof useSellersFormPage_SellerQuery>;
export type SellersFormPage_SellerLazyQueryHookResult = ReturnType<typeof useSellersFormPage_SellerLazyQuery>;
export type SellersFormPage_SellerQueryResult = ApolloReactCommon.QueryResult<SellersFormPage_SellerQuery, SellersFormPage_SellerQueryVariables>;
export const SellersTablePage_SellersDocument = gql`
    query SellersTablePage_sellers {
  sellers {
    uuid
    name
  }
}
    `;

/**
 * __useSellersTablePage_SellersQuery__
 *
 * To run a query within a React component, call `useSellersTablePage_SellersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellersTablePage_SellersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellersTablePage_SellersQuery({
 *   variables: {
 *   },
 * });
 */
export function useSellersTablePage_SellersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SellersTablePage_SellersQuery, SellersTablePage_SellersQueryVariables>) {
        return ApolloReactHooks.useQuery<SellersTablePage_SellersQuery, SellersTablePage_SellersQueryVariables>(SellersTablePage_SellersDocument, baseOptions);
      }
export function useSellersTablePage_SellersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SellersTablePage_SellersQuery, SellersTablePage_SellersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SellersTablePage_SellersQuery, SellersTablePage_SellersQueryVariables>(SellersTablePage_SellersDocument, baseOptions);
        }
export type SellersTablePage_SellersQueryHookResult = ReturnType<typeof useSellersTablePage_SellersQuery>;
export type SellersTablePage_SellersLazyQueryHookResult = ReturnType<typeof useSellersTablePage_SellersLazyQuery>;
export type SellersTablePage_SellersQueryResult = ApolloReactCommon.QueryResult<SellersTablePage_SellersQuery, SellersTablePage_SellersQueryVariables>;
export const SellersTablePage_DeleteSellerDocument = gql`
    mutation SellersTablePage_deleteSeller($uuid: ID!) {
  deleteSeller(uuid: $uuid)
}
    `;
export type SellersTablePage_DeleteSellerMutationFn = ApolloReactCommon.MutationFunction<SellersTablePage_DeleteSellerMutation, SellersTablePage_DeleteSellerMutationVariables>;

/**
 * __useSellersTablePage_DeleteSellerMutation__
 *
 * To run a mutation, you first call `useSellersTablePage_DeleteSellerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSellersTablePage_DeleteSellerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sellersTablePageDeleteSellerMutation, { data, loading, error }] = useSellersTablePage_DeleteSellerMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSellersTablePage_DeleteSellerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SellersTablePage_DeleteSellerMutation, SellersTablePage_DeleteSellerMutationVariables>) {
        return ApolloReactHooks.useMutation<SellersTablePage_DeleteSellerMutation, SellersTablePage_DeleteSellerMutationVariables>(SellersTablePage_DeleteSellerDocument, baseOptions);
      }
export type SellersTablePage_DeleteSellerMutationHookResult = ReturnType<typeof useSellersTablePage_DeleteSellerMutation>;
export type SellersTablePage_DeleteSellerMutationResult = ApolloReactCommon.MutationResult<SellersTablePage_DeleteSellerMutation>;
export type SellersTablePage_DeleteSellerMutationOptions = ApolloReactCommon.BaseMutationOptions<SellersTablePage_DeleteSellerMutation, SellersTablePage_DeleteSellerMutationVariables>;
export const ServiceFormPage_UpsertServiceDocument = gql`
    mutation ServiceFormPage_upsertService($uuid: ID, $kitId: ID, $serviceCatalogId: ID, $ServiceCatalog: ServiceCatalogInput, $systemCode: String, $salePrice: Float, $registrationPremium: Float, $premium1: Float, $premium2: Float, $premium3: Float, $premium4: Float, $premium5: Float, $premium6: Float, $dealershipIds: [ID!]!) {
  upsertService(
    uuid: $uuid
    ServiceCatalog: $ServiceCatalog
    serviceCatalogId: $serviceCatalogId
    kitId: $kitId
    systemCode: $systemCode
    salePrice: $salePrice
    registrationPremium: $registrationPremium
    premium1: $premium1
    premium2: $premium2
    premium3: $premium3
    premium4: $premium4
    premium5: $premium5
    premium6: $premium6
    dealershipIds: $dealershipIds
  )
}
    `;
export type ServiceFormPage_UpsertServiceMutationFn = ApolloReactCommon.MutationFunction<ServiceFormPage_UpsertServiceMutation, ServiceFormPage_UpsertServiceMutationVariables>;

/**
 * __useServiceFormPage_UpsertServiceMutation__
 *
 * To run a mutation, you first call `useServiceFormPage_UpsertServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useServiceFormPage_UpsertServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [serviceFormPageUpsertServiceMutation, { data, loading, error }] = useServiceFormPage_UpsertServiceMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      kitId: // value for 'kitId'
 *      serviceCatalogId: // value for 'serviceCatalogId'
 *      ServiceCatalog: // value for 'ServiceCatalog'
 *      systemCode: // value for 'systemCode'
 *      salePrice: // value for 'salePrice'
 *      registrationPremium: // value for 'registrationPremium'
 *      premium1: // value for 'premium1'
 *      premium2: // value for 'premium2'
 *      premium3: // value for 'premium3'
 *      premium4: // value for 'premium4'
 *      premium5: // value for 'premium5'
 *      premium6: // value for 'premium6'
 *      dealershipIds: // value for 'dealershipIds'
 *   },
 * });
 */
export function useServiceFormPage_UpsertServiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ServiceFormPage_UpsertServiceMutation, ServiceFormPage_UpsertServiceMutationVariables>) {
        return ApolloReactHooks.useMutation<ServiceFormPage_UpsertServiceMutation, ServiceFormPage_UpsertServiceMutationVariables>(ServiceFormPage_UpsertServiceDocument, baseOptions);
      }
export type ServiceFormPage_UpsertServiceMutationHookResult = ReturnType<typeof useServiceFormPage_UpsertServiceMutation>;
export type ServiceFormPage_UpsertServiceMutationResult = ApolloReactCommon.MutationResult<ServiceFormPage_UpsertServiceMutation>;
export type ServiceFormPage_UpsertServiceMutationOptions = ApolloReactCommon.BaseMutationOptions<ServiceFormPage_UpsertServiceMutation, ServiceFormPage_UpsertServiceMutationVariables>;
export const ServiceFormPage_ServiceDocument = gql`
    query ServiceFormPage_service($uuid: ID!) {
  service(uuid: $uuid) {
    uuid
    ServiceCatalog {
      uuid
      name
    }
    salePrice
    systemCode
    registrationPremium
    premium1
    premium2
    premium3
    premium4
    premium5
    premium6
    Kit {
      uuid
      KitCatalog {
        uuid
        name
        hierarchy
      }
    }
    Dealerships {
      uuid
      fantasyName
    }
  }
}
    `;

/**
 * __useServiceFormPage_ServiceQuery__
 *
 * To run a query within a React component, call `useServiceFormPage_ServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceFormPage_ServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceFormPage_ServiceQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useServiceFormPage_ServiceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ServiceFormPage_ServiceQuery, ServiceFormPage_ServiceQueryVariables>) {
        return ApolloReactHooks.useQuery<ServiceFormPage_ServiceQuery, ServiceFormPage_ServiceQueryVariables>(ServiceFormPage_ServiceDocument, baseOptions);
      }
export function useServiceFormPage_ServiceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ServiceFormPage_ServiceQuery, ServiceFormPage_ServiceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ServiceFormPage_ServiceQuery, ServiceFormPage_ServiceQueryVariables>(ServiceFormPage_ServiceDocument, baseOptions);
        }
export type ServiceFormPage_ServiceQueryHookResult = ReturnType<typeof useServiceFormPage_ServiceQuery>;
export type ServiceFormPage_ServiceLazyQueryHookResult = ReturnType<typeof useServiceFormPage_ServiceLazyQuery>;
export type ServiceFormPage_ServiceQueryResult = ApolloReactCommon.QueryResult<ServiceFormPage_ServiceQuery, ServiceFormPage_ServiceQueryVariables>;
export const ServiceFormPage_InitialDataDocument = gql`
    query ServiceFormPage_initialData($groupId: ID!) {
  serviceCatalogs {
    uuid
    name
  }
  group(uuid: $groupId) {
    uuid
    PremiumDescription {
      premium1
      premium2
      premium3
      premium4
      premium5
      premium6
    }
    Dealerships {
      uuid
      fantasyName
    }
  }
  kits(groupId: $groupId) {
    uuid
    KitCatalog {
      uuid
      name
      hierarchy
    }
  }
}
    `;

/**
 * __useServiceFormPage_InitialDataQuery__
 *
 * To run a query within a React component, call `useServiceFormPage_InitialDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceFormPage_InitialDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceFormPage_InitialDataQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useServiceFormPage_InitialDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ServiceFormPage_InitialDataQuery, ServiceFormPage_InitialDataQueryVariables>) {
        return ApolloReactHooks.useQuery<ServiceFormPage_InitialDataQuery, ServiceFormPage_InitialDataQueryVariables>(ServiceFormPage_InitialDataDocument, baseOptions);
      }
export function useServiceFormPage_InitialDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ServiceFormPage_InitialDataQuery, ServiceFormPage_InitialDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ServiceFormPage_InitialDataQuery, ServiceFormPage_InitialDataQueryVariables>(ServiceFormPage_InitialDataDocument, baseOptions);
        }
export type ServiceFormPage_InitialDataQueryHookResult = ReturnType<typeof useServiceFormPage_InitialDataQuery>;
export type ServiceFormPage_InitialDataLazyQueryHookResult = ReturnType<typeof useServiceFormPage_InitialDataLazyQuery>;
export type ServiceFormPage_InitialDataQueryResult = ApolloReactCommon.QueryResult<ServiceFormPage_InitialDataQuery, ServiceFormPage_InitialDataQueryVariables>;
export const ServiceTablePage_ServicesDocument = gql`
    query ServiceTablePage_services($groupId: ID, $dealershipId: ID) {
  services(groupId: $groupId, dealershipId: $dealershipId) {
    uuid
    ServiceCatalog {
      uuid
      name
    }
    systemCode
  }
}
    `;

/**
 * __useServiceTablePage_ServicesQuery__
 *
 * To run a query within a React component, call `useServiceTablePage_ServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceTablePage_ServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceTablePage_ServicesQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      dealershipId: // value for 'dealershipId'
 *   },
 * });
 */
export function useServiceTablePage_ServicesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ServiceTablePage_ServicesQuery, ServiceTablePage_ServicesQueryVariables>) {
        return ApolloReactHooks.useQuery<ServiceTablePage_ServicesQuery, ServiceTablePage_ServicesQueryVariables>(ServiceTablePage_ServicesDocument, baseOptions);
      }
export function useServiceTablePage_ServicesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ServiceTablePage_ServicesQuery, ServiceTablePage_ServicesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ServiceTablePage_ServicesQuery, ServiceTablePage_ServicesQueryVariables>(ServiceTablePage_ServicesDocument, baseOptions);
        }
export type ServiceTablePage_ServicesQueryHookResult = ReturnType<typeof useServiceTablePage_ServicesQuery>;
export type ServiceTablePage_ServicesLazyQueryHookResult = ReturnType<typeof useServiceTablePage_ServicesLazyQuery>;
export type ServiceTablePage_ServicesQueryResult = ApolloReactCommon.QueryResult<ServiceTablePage_ServicesQuery, ServiceTablePage_ServicesQueryVariables>;
export const ServiceTablePage_DeleteServiceDocument = gql`
    mutation ServiceTablePage_deleteService($uuid: ID!) {
  deleteService(uuid: $uuid)
}
    `;
export type ServiceTablePage_DeleteServiceMutationFn = ApolloReactCommon.MutationFunction<ServiceTablePage_DeleteServiceMutation, ServiceTablePage_DeleteServiceMutationVariables>;

/**
 * __useServiceTablePage_DeleteServiceMutation__
 *
 * To run a mutation, you first call `useServiceTablePage_DeleteServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useServiceTablePage_DeleteServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [serviceTablePageDeleteServiceMutation, { data, loading, error }] = useServiceTablePage_DeleteServiceMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useServiceTablePage_DeleteServiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ServiceTablePage_DeleteServiceMutation, ServiceTablePage_DeleteServiceMutationVariables>) {
        return ApolloReactHooks.useMutation<ServiceTablePage_DeleteServiceMutation, ServiceTablePage_DeleteServiceMutationVariables>(ServiceTablePage_DeleteServiceDocument, baseOptions);
      }
export type ServiceTablePage_DeleteServiceMutationHookResult = ReturnType<typeof useServiceTablePage_DeleteServiceMutation>;
export type ServiceTablePage_DeleteServiceMutationResult = ApolloReactCommon.MutationResult<ServiceTablePage_DeleteServiceMutation>;
export type ServiceTablePage_DeleteServiceMutationOptions = ApolloReactCommon.BaseMutationOptions<ServiceTablePage_DeleteServiceMutation, ServiceTablePage_DeleteServiceMutationVariables>;
export const UserPermissionsFormPage_UserPermissionsDocument = gql`
    query UserPermissionsFormPage_userPermissions($email: String!) {
  userDealershipPermissions(email: $email) {
    uuid
    email
    Dealership {
      uuid
      fantasyName
      Group {
        uuid
      }
    }
  }
}
    `;

/**
 * __useUserPermissionsFormPage_UserPermissionsQuery__
 *
 * To run a query within a React component, call `useUserPermissionsFormPage_UserPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPermissionsFormPage_UserPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPermissionsFormPage_UserPermissionsQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUserPermissionsFormPage_UserPermissionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserPermissionsFormPage_UserPermissionsQuery, UserPermissionsFormPage_UserPermissionsQueryVariables>) {
        return ApolloReactHooks.useQuery<UserPermissionsFormPage_UserPermissionsQuery, UserPermissionsFormPage_UserPermissionsQueryVariables>(UserPermissionsFormPage_UserPermissionsDocument, baseOptions);
      }
export function useUserPermissionsFormPage_UserPermissionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserPermissionsFormPage_UserPermissionsQuery, UserPermissionsFormPage_UserPermissionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserPermissionsFormPage_UserPermissionsQuery, UserPermissionsFormPage_UserPermissionsQueryVariables>(UserPermissionsFormPage_UserPermissionsDocument, baseOptions);
        }
export type UserPermissionsFormPage_UserPermissionsQueryHookResult = ReturnType<typeof useUserPermissionsFormPage_UserPermissionsQuery>;
export type UserPermissionsFormPage_UserPermissionsLazyQueryHookResult = ReturnType<typeof useUserPermissionsFormPage_UserPermissionsLazyQuery>;
export type UserPermissionsFormPage_UserPermissionsQueryResult = ApolloReactCommon.QueryResult<UserPermissionsFormPage_UserPermissionsQuery, UserPermissionsFormPage_UserPermissionsQueryVariables>;
export const UserPermissionsFormPage_SetUserDealershipPermissionsDocument = gql`
    mutation UserPermissionsFormPage_setUserDealershipPermissions($dealershipIds: [ID!]!, $email: String!) {
  setUserDealershipPermissions(dealershipIds: $dealershipIds, email: $email)
}
    `;
export type UserPermissionsFormPage_SetUserDealershipPermissionsMutationFn = ApolloReactCommon.MutationFunction<UserPermissionsFormPage_SetUserDealershipPermissionsMutation, UserPermissionsFormPage_SetUserDealershipPermissionsMutationVariables>;

/**
 * __useUserPermissionsFormPage_SetUserDealershipPermissionsMutation__
 *
 * To run a mutation, you first call `useUserPermissionsFormPage_SetUserDealershipPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserPermissionsFormPage_SetUserDealershipPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userPermissionsFormPageSetUserDealershipPermissionsMutation, { data, loading, error }] = useUserPermissionsFormPage_SetUserDealershipPermissionsMutation({
 *   variables: {
 *      dealershipIds: // value for 'dealershipIds'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUserPermissionsFormPage_SetUserDealershipPermissionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserPermissionsFormPage_SetUserDealershipPermissionsMutation, UserPermissionsFormPage_SetUserDealershipPermissionsMutationVariables>) {
        return ApolloReactHooks.useMutation<UserPermissionsFormPage_SetUserDealershipPermissionsMutation, UserPermissionsFormPage_SetUserDealershipPermissionsMutationVariables>(UserPermissionsFormPage_SetUserDealershipPermissionsDocument, baseOptions);
      }
export type UserPermissionsFormPage_SetUserDealershipPermissionsMutationHookResult = ReturnType<typeof useUserPermissionsFormPage_SetUserDealershipPermissionsMutation>;
export type UserPermissionsFormPage_SetUserDealershipPermissionsMutationResult = ApolloReactCommon.MutationResult<UserPermissionsFormPage_SetUserDealershipPermissionsMutation>;
export type UserPermissionsFormPage_SetUserDealershipPermissionsMutationOptions = ApolloReactCommon.BaseMutationOptions<UserPermissionsFormPage_SetUserDealershipPermissionsMutation, UserPermissionsFormPage_SetUserDealershipPermissionsMutationVariables>;