const theme = {
  appBarColor: "#009688",
  primaryColor: "#009688",
  tabTitle: "Scalar BI",
  faviconUrl: "https://scalar.ws/favicon.ico",
  loginRightBackgroundColor: "#009688",
  hideLogoWhenMenuUncollapsed: false,
};

export default theme;
