const theme = {
  appBarColor: "#4E4E50",
  primaryColor: "#F06E32",
  tabTitle: "RAV PRO Tecnologia e Produtos Automotivos - SCALAR/",
  faviconUrl: "https://ravpro.com.br/wp-content/uploads/2019/03/favicon.png",
  loginRightBackgroundColor: "#D2D2D2",
  hideLogoWhenMenuUncollapsed: true,
};

export default theme;
