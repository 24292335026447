import React, { useState, useEffect } from "react";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  TextField,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router";
import { useAuth } from "../contexts/AuthContext";
import { styled } from "@mui/material/styles";
import { getGroupTheme } from "../groups";

const Form = styled("form")`
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0 32px;
  @media (max-width: 900px) {
    padding: 0 8px;
  }
`;

const ErrorText = styled(Typography)`
  color: red;
  width: 100%;
  text-align: center;
`;

const StyledTextField = styled(TextField)`
  margin: 12px;
  cursor: pointer;
`;

const RememberMe = styled(FormControlLabel)`
  margin-left: 4px;
`;

const LoginButton = styled(Button)`
  margin: 12px;
  cursor: pointer;
`;

const MobileLogo = styled("img")`
  display: none;
  margin-bottom: 32px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 900px) {
    width: 350px;
    display: block;
  }
`;

const UsoftLabel = styled("div")`
  display: none;
  @media (max-width: 900px) {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 10px;
    color: rgba(0, 0, 0, 0.3);
    margin-bottom: 16px;
  }
`;

const Spacer = styled("div")`
  flex: 2;
`;

export function LoginPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  // let location = useLocation();
  let auth = useAuth();

  // let from = location.state?.from?.pathname || "/";
  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);

    let formData = new FormData(event.currentTarget);
    let username = formData.get("username") as string;
    let password = formData.get("password") as string;

    if (remember) {
      localStorage.setItem("email", username);
    }
    try {
      await auth.signin(username, password);
      // if (from === "/") {
      navigate("/dashboard", { replace: true });
      // } else {
      // navigate(from, { replace: true });
      // }
    } catch (err: any) {
      if (err?.message === "authorization_failed") {
        setError("Usuário ou senha incorreto");
      } else {
        setError("Servidor temporariamente fora do ar");
      }
      setLoading(false);
    }
  }

  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    if (storedEmail) {
      setEmail(storedEmail);
      setRemember(true);
    }
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <Spacer />
      <MobileLogo src={getGroupTheme().group} />
      <ErrorText>{error}</ErrorText>
      <StyledTextField
        name="username"
        label="Email"
        type="text"
        variant="outlined"
        inputProps={{
          autoCapitalize: "none",
        }}
        onChange={(e) => setEmail(e.target.value)}
        value={email}
      />
      <StyledTextField
        label="Senha"
        name="password"
        type={showPassword ? "text" : "password"}
        variant="outlined"
        onChange={(e) => setPassword(e.target.value)}
        value={password}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <RememberMe
        checked={remember}
        onClick={() => setRemember(!remember)}
        control={<Checkbox value="remember" color="primary" />}
        label="Lembrar"
      />
      <LoginButton
        type="submit"
        variant="contained"
        color="primary"
        disabled={loading}
      >
        {!loading ? "Entrar" : <CircularProgress size={24} color="primary" />}
      </LoginButton>
      <Spacer />
      <UsoftLabel>Powered by Usoft Tecnologia</UsoftLabel>
    </Form>
  );
}
