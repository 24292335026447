import Box from "@mui/material/Box";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  Icon,
  InputAdornment,
  LinearProgress,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import {
  ServiceFormPage_InitialDataQuery,
  ServiceFormPage_ServiceQuery,
  useServiceFormPage_InitialDataQuery,
  useServiceFormPage_ServiceQuery,
  useServiceFormPage_UpsertServiceMutation,
} from "../../../../generated/graphql";
import { useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { toast } from "react-hot-toast";
import { useTenant } from "../../../context/TenantContext";
import { BackToolbar } from "../../../components/BackToolbar";
import {
  formatCurrency,
  MoneyField,
  parseFieldToFloat,
  parseFieldToDecimal,
  parseIntToField,
} from "../../../../components/MoneyField";
import orderBy from "lodash.orderby";
import { useEffect } from "react";
import { GroupDropdown } from "../../../components/GroupDropdown";

type ServiceFormData = {
  uuid?: string;
  serviceCatalogId?: string;
  serviceCatalogName?: string;
  newServiceCatalog?: boolean;
  systemCode?: string;
  registrationPremium?: string;
  salePrice?: string;
  premium1?: string;
  premium2?: string;
  premium3?: string;
  premium4?: string;
  premium5?: string;
  premium6?: string;
  serviceDealerships: ServiceFormPage_ServiceQuery["service"]["Dealerships"];
  Kit?: ServiceFormPage_ServiceQuery["service"]["Kit"] | null;
};

export function ServiceFormPage() {
  const { id } = useParams();
  const { groupId } = useTenant();
  const { data, loading } = useServiceFormPage_ServiceQuery({
    variables: { uuid: id! },
    skip: !id,
  });
  const [upsertService, { loading: upsertLoading }] =
    useServiceFormPage_UpsertServiceMutation();
  const { data: initialData, loading: initialDataLoading } =
    useServiceFormPage_InitialDataQuery({
      variables: {
        groupId: groupId!,
      },
      skip: !groupId,
    });
  const navigate = useNavigate();

  const onSubmit = async (data: ServiceFormData) => {
    if (data.serviceDealerships.length === 0) {
      toast.error("Pelo menos uma concessionária precisa ser selecionada.");
      return;
    }
    const parsedPremiums = {
      registrationPremium: parseFieldToDecimal(data.registrationPremium),
      premium1: parseFieldToDecimal(data.premium1),
      premium2: parseFieldToDecimal(data.premium2),
      premium3: parseFieldToDecimal(data.premium3),
      premium4: parseFieldToDecimal(data.premium4),
      premium5: parseFieldToDecimal(data.premium5),
      premium6: parseFieldToDecimal(data.premium6),
    };
    if (
      parsedPremiums.premium1 +
        parsedPremiums.premium2 +
        parsedPremiums.premium3 +
        parsedPremiums.premium4 +
        parsedPremiums.premium5 +
        parsedPremiums.premium6 >
      parsedPremiums.registrationPremium
    ) {
      toast.error("Soma dos prêmios não pode ultrapassar prêmio de cadastro.");
    } else {
      await toast.promise(
        upsertService({
          variables: {
            uuid: id,
            serviceCatalogId: data.serviceCatalogId,
            kitId: data.Kit?.uuid,
            ServiceCatalog: !data.serviceCatalogId
              ? {
                  name: data.serviceCatalogName ?? "",
                }
              : undefined,
            systemCode: data.systemCode,
            salePrice: parseFieldToDecimal(data.salePrice),
            dealershipIds: data.serviceDealerships.map((i) => i.uuid) ?? [],
            ...parsedPremiums,
          },
        }),
        {
          loading: "Cadastrando",
          success: "Sucesso!",
          error: "Erro, tente novamente.",
        }
      );
      navigate("/ravpro/services");
    }
  };
  return (
    <Box>
      <BackToolbar />
      {!loading && !initialDataLoading ? (
        <ServiceForm
          {...data?.service}
          registrationPremium={parseIntToField(
            data?.service.registrationPremium
          )}
          salePrice={parseIntToField(data?.service.salePrice)}
          premium1={parseIntToField(data?.service.premium1)}
          premium2={parseIntToField(data?.service.premium2)}
          premium3={parseIntToField(data?.service.premium3)}
          premium4={parseIntToField(data?.service.premium4)}
          premium5={parseIntToField(data?.service.premium5)}
          premium6={parseIntToField(data?.service.premium6)}
          serviceCatalogId={data?.service.ServiceCatalog?.uuid}
          serviceCatalogs={initialData?.serviceCatalogs ?? []}
          premiumDescription={initialData?.group.PremiumDescription}
          onSubmit={onSubmit}
          serviceDealerships={data?.service.Dealerships ?? []}
          dealerships={initialData?.group.Dealerships ?? []}
          kits={initialData?.kits ?? []}
          loading={upsertLoading}
        />
      ) : (
        <LinearProgress />
      )}
    </Box>
  );
}

const Form = styled("form")`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 400px;
  margin: 16px auto;
`;

const Line = styled("div")`
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
`;

type PremiumTextProps = {
  $invalid: boolean;
};

const PremiumText = styled("span")<PremiumTextProps>`
  color: ${(props) => (props.$invalid ? "red" : "black")};
  text-align: right;
`;

type ServiceFormProps = ServiceFormData & {
  serviceCatalogs: ServiceFormPage_InitialDataQuery["serviceCatalogs"];
  premiumDescription: ServiceFormPage_InitialDataQuery["group"]["PremiumDescription"];
  dealerships: ServiceFormPage_InitialDataQuery["group"]["Dealerships"];
  kits: ServiceFormPage_InitialDataQuery["kits"];
  onSubmit: (data: ServiceFormData) => void;
  loading: boolean;
};

const ServiceForm: React.FC<ServiceFormProps> = ({
  serviceCatalogName,
  serviceCatalogId,
  newServiceCatalog,
  systemCode,
  registrationPremium,
  premium1,
  premium2,
  premium3,
  premium4,
  premium5,
  premium6,
  serviceCatalogs,
  premiumDescription,
  dealerships,
  serviceDealerships,
  salePrice,
  Kit,
  kits,
  onSubmit,
  loading,
}) => {
  const defaultValues = {
    newServiceCatalog,
    serviceCatalogName,
    serviceCatalogId,
    systemCode,
    registrationPremium,
    Kit,
    salePrice,
    premium1,
    premium2,
    premium3,
    premium4,
    premium5,
    premium6,
    serviceDealerships,
  };
  const { handleSubmit, control, setValue, watch } = useForm({
    defaultValues,
  });
  const newServiceCatalogWatched = watch("newServiceCatalog");
  const premium1Float = parseFieldToFloat(watch("premium1")) ?? 0;
  const premium2Float = parseFieldToFloat(watch("premium2")) ?? 0;
  const premium3Float = parseFieldToFloat(watch("premium3")) ?? 0;
  const premium4Float = parseFieldToFloat(watch("premium4")) ?? 0;
  const premium5Float = parseFieldToFloat(watch("premium5")) ?? 0;
  const premium6Float = parseFieldToFloat(watch("premium6")) ?? 0;
  const premiumTotal =
    premium1Float +
    premium2Float +
    premium3Float +
    premium4Float +
    premium5Float +
    premium6Float;

  const registrationPremiumWatched = watch("registrationPremium");
  const registrationPremiumFloat =
    parseFieldToFloat(registrationPremiumWatched) ?? 0;

  useEffect(() => {
    if (newServiceCatalogWatched) {
      setValue("serviceCatalogId", undefined);
    }
  }, [newServiceCatalogWatched]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <GroupDropdown disabled />
      <Line />

      {newServiceCatalogWatched ? (
        <Controller
          name="serviceCatalogName"
          control={control}
          defaultValue={defaultValues.serviceCatalogName ?? ""}
          render={({ field }) => (
            <TextField
              inputProps={field}
              label="Nome serviço"
              helperText="Sem acento e sem cedilha"
            />
          )}
        />
      ) : (
        <Autocomplete
          options={orderBy(serviceCatalogs, "name")}
          renderInput={(params) => <TextField {...params} label="Nome" />}
          onChange={(_e, value) => setValue("serviceCatalogId", value?.uuid)}
          isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
          getOptionLabel={(i) => i.name}
          defaultValue={
            serviceCatalogs.filter(
              (i) => i.uuid === defaultValues.serviceCatalogId
            )?.[0] || null
          }
        />
      )}
      <FormControlLabel
        style={{ marginTop: -16 }}
        labelPlacement="start"
        control={
          <Checkbox
            name="newServiceCatalog"
            size="small"
            defaultChecked={defaultValues.newServiceCatalog}
            onChange={(e) => {
              setValue("newServiceCatalog", e.target.checked);
            }}
          />
        }
        label={
          <Typography style={{ fontSize: 12, color: "rgba(0,0,0,0.5)" }}>
            Novo
          </Typography>
        }
      />
      <Line style={{ marginTop: -16 }} />
      <Autocomplete
        multiple
        options={orderBy(dealerships, "name")}
        renderInput={(params) => (
          <TextField {...params} label="Concessionárias" />
        )}
        onChange={(_e, value) => setValue("serviceDealerships", value)}
        isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
        getOptionLabel={(i) => i.fantasyName ?? ""}
        defaultValue={defaultValues.serviceDealerships}
        sx={{ marginBottom: 2, width: "100%" }}
      />
      <Controller
        name="systemCode"
        control={control}
        defaultValue={defaultValues.systemCode ?? ""}
        render={({ field }) => (
          <TextField inputProps={field} label="Codigo Sistema" />
        )}
      />

      <Autocomplete
        options={orderBy(kits, "KitCatalog.hierarchy")}
        renderInput={(params) => <TextField {...params} label="Kit" />}
        onChange={(_e, value) => setValue("Kit", value)}
        isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
        getOptionLabel={(i) => i.KitCatalog.name}
        defaultValue={defaultValues.Kit}
      />

      <Controller
        name="salePrice"
        control={control}
        defaultValue={defaultValues.salePrice ?? ""}
        render={({ field: { onChange, name, value } }) => (
          <MoneyField
            label="Preço de venda"
            name={name}
            onChange={onChange}
            value={value}
          />
        )}
      />

      <Line />
      <Typography>Prêmios: </Typography>

      <Controller
        name="registrationPremium"
        control={control}
        defaultValue={defaultValues.registrationPremium ?? ""}
        render={({ field: { onChange, name, value } }) => (
          <MoneyField
            label="Prêmio cadastro"
            name={name}
            onChange={onChange}
            value={value}
            helperText={
              registrationPremiumFloat
                ? `Valor sugerido para premiações: ${Intl.NumberFormat(
                    "pt-BR",
                    {
                      style: "currency",
                      currency: "BRL",
                      maximumFractionDigits: 2,
                    }
                  ).format(registrationPremiumFloat * 0.85)}`
                : ""
            }
          />
        )}
      />
      <Controller
        name="premium1"
        control={control}
        defaultValue={defaultValues.premium1 ?? ""}
        render={({ field: { onChange, name, value } }) => (
          <MoneyField
            label="Prêmio 1"
            name={name}
            onChange={onChange}
            value={value}
            InputProps={{
              endAdornment: <Info title={premiumDescription?.premium1} />,
            }}
          />
        )}
      />
      <Controller
        name="premium2"
        control={control}
        defaultValue={defaultValues.premium2 ?? ""}
        render={({ field: { onChange, name, value } }) => (
          <MoneyField
            label="Prêmio 2"
            name={name}
            onChange={onChange}
            value={value}
            InputProps={{
              endAdornment: <Info title={premiumDescription?.premium2} />,
            }}
          />
        )}
      />
      <Controller
        name="premium3"
        control={control}
        defaultValue={defaultValues.premium3 ?? ""}
        render={({ field: { onChange, name, value } }) => (
          <MoneyField
            label="Prêmio 3"
            name={name}
            onChange={onChange}
            value={value}
            InputProps={{
              endAdornment: <Info title={premiumDescription?.premium3} />,
            }}
          />
        )}
      />
      <Controller
        name="premium4"
        control={control}
        defaultValue={defaultValues.premium4 ?? ""}
        render={({ field: { onChange, name, value } }) => (
          <MoneyField
            label="Prêmio 4"
            name={name}
            onChange={onChange}
            value={value}
            InputProps={{
              endAdornment: <Info title={premiumDescription?.premium4} />,
            }}
          />
        )}
      />
      <Controller
        name="premium5"
        control={control}
        defaultValue={defaultValues.premium5 ?? ""}
        render={({ field: { onChange, name, value } }) => (
          <MoneyField
            label="Prêmio 5"
            name={name}
            onChange={onChange}
            value={value}
            InputProps={{
              endAdornment: <Info title={premiumDescription?.premium5} />,
            }}
          />
        )}
      />
      <Controller
        name="premium6"
        control={control}
        defaultValue={defaultValues.premium6 ?? ""}
        render={({ field: { onChange, name, value } }) => (
          <MoneyField
            label="Prêmio 6"
            name={name}
            onChange={onChange}
            value={value}
            InputProps={{
              endAdornment: <Info title={premiumDescription?.premium6} />,
            }}
          />
        )}
      />
      <PremiumText $invalid={premiumTotal > registrationPremiumFloat * 0.85}>
        Total Prêmios: {formatCurrency(premiumTotal)}
      </PremiumText>
      <Button type="submit" variant="contained" disabled={loading}>
        Cadastrar
      </Button>
    </Form>
  );
};

type InfoProps = {
  title?: string;
};

const Info: React.FC<InfoProps> = ({ title }) =>
  title && title.length > 0 ? (
    <InputAdornment position="end">
      <Tooltip title={title}>
        <Icon style={{ color: "rgba(0,0,0,0.4)" }}>info</Icon>
      </Tooltip>
    </InputAdornment>
  ) : (
    <></>
  );
