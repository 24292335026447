const theme = {
  appBarColor: "#FD383C",
  primaryColor: "#FD383C",
  tabTitle: "Mercosul Veiculos Ltda - SCALAR/",
  faviconUrl: "https://www.usoft.com.br/scalar/favicon.ico",
  loginRightBackgroundColor: "#FD383C",
  hideLogoWhenMenuUncollapsed: false,
};

export default theme;
