const theme = {
  appBarColor: "rgba(2,150,215,1)",
  primaryColor: "rgba(2,150,215,1)",
  tabTitle: "SCALAR/ :: Business Intelligence",
  faviconUrl: "https://www.usoft.com.br/scalar/favicon.ico",
  loginRightBackgroundColor:
    "linear-gradient(90deg, rgba(2,150,215,1) 0%, rgba(161,56,148,1) 100%)",
  hideLogoWhenMenuUncollapsed: true,
};

export default theme;
