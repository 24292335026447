const theme = {
  appBarColor: "#353C45",
  primaryColor: "#00529B",
  tabTitle: "DAF Caminhões - Scalar BI",
  faviconUrl: "https://scalar.ws/favicon.ico",
  loginRightBackgroundColor: "#FFFFFF",
  hideLogoWhenMenuUncollapsed: true,
};

export default theme;
