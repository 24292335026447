import styled from "@emotion/styled";
import { useTenant } from "../context/TenantContext";
import { DealershipDropdown } from "./DealershipDropdown";
import { GroupDropdown } from "./GroupDropdown";

const SelectDealershipText = styled("div")`
  text-align: center;
  padding: 32px;
`;

type TenantSelectorFrameProps = {
  groupDisabled?: boolean;
  dealershipDisabled?: boolean;
};

export const TenantSelectorFrame: React.FC<TenantSelectorFrameProps> = ({
  children,
  groupDisabled,
  dealershipDisabled,
}) => {
  const { groupId, dealershipId } = useTenant();
  return (
    <div>
      <GroupDropdown disabled={groupDisabled} />
      <DealershipDropdown disabled={dealershipDisabled} />
      <div style={{ height: 40 }} />
      {!groupId ? (
        <SelectDealershipText>Selecione um grupo</SelectDealershipText>
      ) : null}
      {groupId && !dealershipId ? (
        <SelectDealershipText>Selecione um concessionária</SelectDealershipText>
      ) : null}
      {groupId && dealershipId ? children : null}
    </div>
  );
};
