import styled from "@emotion/styled";
import { useTenant } from "../context/TenantContext";
import { GroupDropdown } from "./GroupDropdown";

const GroupContainer = styled("div")`
  margin: 32px;
`;

const SelectDealershipText = styled("div")`
  text-align: center;
  padding: 32px;
`;

export const GroupSelectorFrame: React.FC = ({ children }) => {
  const { groupId } = useTenant();
  return (
    <div>
      <GroupContainer>
        <GroupDropdown />
      </GroupContainer>
      {!groupId ? (
        <SelectDealershipText>Selecione um grupo</SelectDealershipText>
      ) : null}
      {groupId ? children : null}
    </div>
  );
};
