import Box from "@mui/material/Box";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import {
  ConsultantsFormPage_GroupQuery,
  Premium,
  useConsultantsFormPage_ConsultantQuery,
  useConsultantsFormPage_GroupQuery,
  useConsultantsFormPage_UpsertConsultantMutation,
} from "../../../../generated/graphql";
import { useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { toast } from "react-hot-toast";
import { useTenant } from "../../../context/TenantContext";
import { BackToolbar } from "../../../components/BackToolbar";
import { CPFTextField } from "../../../../components/CPFTextField";
import { validateCPF } from "./validateCPF";

type ConsultantFormData = {
  active?: boolean;
  name?: string;
  cpf?: string;
  premiumCardNumber?: string;
  proxyNumber?: string;
  Premium?: Premium | string;
};

export function ConsultantFormPage() {
  const { id } = useParams();
  const { dealershipId, groupId } = useTenant();
  const { data, loading } = useConsultantsFormPage_ConsultantQuery({
    variables: { uuid: id! },
    skip: !id,
  });
  const { data: groupData, loading: groupLoading } =
    useConsultantsFormPage_GroupQuery({
      variables: { uuid: groupId! },
      skip: !groupId,
    });
  const [upsertConsultant, { loading: upsertLoading }] =
    useConsultantsFormPage_UpsertConsultantMutation();
  const navigate = useNavigate();
  const onSubmit = async (data: ConsultantFormData) => {
    if (data.cpf && data.cpf.trim().length < 14) {
      toast.error("CPF deve ser preenchido");
      return;
    }
    if (!validateCPF(data.cpf!)) {
      toast.error("CPF inválido");
      return;
    }
    await toast.promise(
      upsertConsultant({
        variables: {
          uuid: id,
          active: data.active,
          name: data.name?.toUpperCase(),
          cpf: data.cpf?.trim(),
          premiumCardNumber: data.premiumCardNumber,
          proxyNumber: data.proxyNumber,
          Premium: data.Premium,
          dealershipId: dealershipId!,
        },
      }),
      {
        loading: "Cadastrando",
        success: "Sucesso!",
        error: "Erro, tente novamente.",
      }
    );
    navigate("/ravpro/consultants");
  };
  return (
    <Box>
      <BackToolbar />
      {!loading || !groupLoading ? (
        <ConsultantForm
          {...data?.consultant}
          onSubmit={onSubmit}
          loading={upsertLoading}
          PremiumDescription={groupData?.group.PremiumDescription}
        />
      ) : (
        <LinearProgress />
      )}
    </Box>
  );
}

const Form = styled("form")`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 400px;
  margin: 16px auto;
`;

type ConsultantFormProps = ConsultantFormData & {
  onSubmit: (data: ConsultantFormData) => void;
  loading: boolean;
  PremiumDescription: ConsultantsFormPage_GroupQuery["group"]["PremiumDescription"];
};

const ConsultantForm: React.FC<ConsultantFormProps> = ({
  active,
  name,
  cpf,
  premiumCardNumber,
  proxyNumber,
  Premium: premium,
  onSubmit,
  loading,
  PremiumDescription,
}) => {
  const defaultValues = {
    active,
    name,
    cpf,
    premiumCardNumber,
    proxyNumber,
    Premium: premium ?? "",
  };
  const { handleSubmit, control, setValue } = useForm({
    defaultValues,
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormControlLabel
        control={
          <Checkbox
            name="active"
            defaultChecked={
              defaultValues.active === undefined ? true : defaultValues.active
            }
            onChange={(e) => {
              setValue("active", e.target.checked);
            }}
          />
        }
        label="Ativo"
      />
      <Controller
        name="name"
        control={control}
        defaultValue={defaultValues.name ?? ""}
        render={({ field }) => (
          <TextField
            inputProps={{ ...field, style: { textTransform: "uppercase" } }}
            label="Nome"
          />
        )}
      />
      <Controller
        name="cpf"
        control={control}
        defaultValue={defaultValues.cpf ?? ""}
        render={({ field: { onChange, onBlur, ref, ...rest } }) => (
          <CPFTextField
            {...rest}
            label="CPF"
            onBlur={onBlur}
            onChange={onChange}
            inputRef={ref}
          />
        )}
      />
      <Controller
        name="premiumCardNumber"
        control={control}
        defaultValue={defaultValues.premiumCardNumber ?? ""}
        render={({ field }) => (
          <TextField inputProps={field} label="Número cartão premiação" />
        )}
      />
      <Controller
        name="proxyNumber"
        control={control}
        defaultValue={defaultValues.proxyNumber ?? ""}
        render={({ field }) => (
          <TextField inputProps={field} label="Número proxy (Fábio)" />
        )}
      />
      <FormControl>
        <InputLabel>Premio gerente</InputLabel>
        <Controller
          defaultValue={defaultValues.Premium ?? ""}
          render={({ field }) => (
            <Select {...field} label="Premio gerente">
              <MenuItem value="">
                <em>Nenhum</em>
              </MenuItem>
              <MenuItem value={Premium.Premium_2}>
                Prêmio 2{" "}
                {PremiumDescription?.premium2 !== ""
                  ? ` - ${PremiumDescription?.premium2}`
                  : ""}
              </MenuItem>
              <MenuItem value={Premium.Premium_3}>
                Prêmio 3{" "}
                {PremiumDescription?.premium3 !== ""
                  ? ` - ${PremiumDescription?.premium3}`
                  : ""}
              </MenuItem>
              <MenuItem value={Premium.Premium_4}>
                Prêmio 4{" "}
                {PremiumDescription?.premium4 !== ""
                  ? ` - ${PremiumDescription?.premium4}`
                  : ""}
              </MenuItem>
              <MenuItem value={Premium.Premium_5}>
                Prêmio 5{" "}
                {PremiumDescription?.premium5 !== ""
                  ? ` - ${PremiumDescription?.premium5}`
                  : ""}
              </MenuItem>
              <MenuItem value={Premium.Premium_6}>
                Prêmio 6{" "}
                {PremiumDescription?.premium6 !== ""
                  ? ` - ${PremiumDescription?.premium6}`
                  : ""}
              </MenuItem>
            </Select>
          )}
          control={control}
          name="Premium"
        />
      </FormControl>
      <Button type="submit" variant="contained" disabled={loading}>
        Cadastrar
      </Button>
    </Form>
  );
};
