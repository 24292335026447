import styled from "@emotion/styled";
import { Typography } from "@mui/material";

export const TableTitleText = styled(Typography)`
  text-align: center;
  font-weight: bold;
  width: 100%;
  padding: 0 0 8px 0;
  font-size: 24px;
`;

export const TableContainer = styled("div")`
  height: 400px;
  padding: 32px 0 32px 0;
`;
