import { PremiumCalculationType } from "../../../../generated/graphql";
import { useSearchParams } from "react-router-dom";
import { SaleEntry } from "./sale_entry";
import { OrderEntry } from "./order_entry";

export function EntryFormPage() {
  const [searchParams] = useSearchParams();
  const premiumCalculationType = searchParams.get(
    "premiumCalculationType"
  ) as PremiumCalculationType;

  if (premiumCalculationType === PremiumCalculationType.Sale) {
    return <SaleEntry />;
  } else {
    return <OrderEntry />;
  }
}
