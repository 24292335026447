import {
  EntryFormPage_EntryQuery,
  EntryFormPage_InitialDataQuery,
} from "../../../../generated/graphql";
import { DataGrid, GridColumns, GridRowsProp } from "@mui/x-data-grid";
import { useState, forwardRef, useImperativeHandle } from "react";
import { TableContainer, TableTitleText } from "./components";

type KitsTableProps = {
  rows: GridRowsProp;
  columns: GridColumns;
};

type KitRowMap = Record<
  string,
  {
    consultantId: string;
    kitId: string;
    quantity: number;
  }
>;

export type KitsTableHandler = {
  getValue: () => KitRowMap;
};

export const KitsTable = forwardRef<KitsTableHandler, KitsTableProps>(
  ({ rows, columns }, ref) => {
    const [value, setValue] = useState<KitRowMap>({});
    useImperativeHandle(
      ref,
      () => ({
        getValue: () => value,
      }),
      [value]
    );
    return (
      <TableContainer>
        <TableTitleText>Kits</TableTitleText>
        <DataGrid
          rows={rows}
          columns={columns}
          hideFooter
          disableColumnFilter
          disableColumnMenu
          onCellEditCommit={(params) => {
            setValue({
              ...value,
              [`${[params.field]}:${params.id}`]: {
                consultantId: params.id as string,
                kitId: params.field,
                quantity: params.value as number,
              },
            });
          }}
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              textOverflow: "clip",
              whiteSpace: "break-spaces",
              lineHeight: 1,
            },
          }}
        />
      </TableContainer>
    );
  }
);

type ParseKitTablePropsParams = {
  kits: EntryFormPage_InitialDataQuery["kits"];
  consultants: EntryFormPage_InitialDataQuery["consultants"];
  entry: EntryFormPage_EntryQuery["entry"] | undefined;
};

export const parseKitTableProps = ({
  kits,
  consultants,
  entry,
}: ParseKitTablePropsParams) => {
  const kitColumns: GridColumns = [
    {
      field: "consultant",
      headerName: "Consultor",
      editable: false,
      width: 250,
    },
    {
      field: "revision",
      headerName: "Revisões",
      editable: true,
      type: "number",
      width: 100,
    },
    ...(kits.map((i) => ({
      field: i.uuid,
      headerName: `${i.KitCatalog?.name}`,
      editable: true,
      width: 400,
      type: "number",
    })) ?? []),
  ];

  const createdRows: Record<
    string,
    EntryFormPage_EntryQuery["entry"]["KitEntries"][0]
  > =
    entry?.KitEntries.reduce(
      (acc, i) => ({
        ...acc,
        [`${i.Kit.uuid}:${i.Consultant.uuid}`]: i,
      }),
      {}
    ) ?? {};
  const createdRevisionRows: Record<
    string,
    EntryFormPage_EntryQuery["entry"]["RevisionEntries"][0]
  > =
    entry?.RevisionEntries.reduce(
      (acc, i) => ({
        ...acc,
        [i.Consultant.uuid]: i,
      }),
      {}
    ) ?? {};

  const kitRows: GridRowsProp =
    consultants.map((i) => ({
      id: i.uuid,
      consultant: i.name,
      revision: createdRevisionRows[i.uuid]?.quantity ?? 0,
      ...kits.reduce(
        (acc, j) => ({
          ...acc,
          [j.uuid]: createdRows[`${j.uuid}:${i.uuid}`]?.quantity ?? 0,
          entry: createdRows[`${j.uuid}:${i.uuid}`],
        }),
        {}
      ),
    })) ?? [];

  return {
    kitColumns,
    kitRows,
  };
};

export const createKitConsultantList = ({
  kits,
  consultants,
}: CreateKitConsultantList) => {
  const arr: [
    key: string,
    value: { consultantId: string; kitId: string; quantity: 0 }
  ][] = [];
  for (var i of kits) {
    for (var j of consultants) {
      arr.push([
        `${i.uuid}:${j.uuid}`,
        {
          kitId: i.uuid,
          consultantId: j.uuid,
          quantity: 0,
        },
      ]);
    }
  }
  return arr;
};

type CreateKitConsultantList = {
  kits: EntryFormPage_InitialDataQuery["kits"];
  consultants: EntryFormPage_InitialDataQuery["consultants"];
};
