import { ChevronLeft } from "@mui/icons-material";
import { Button, Toolbar } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const BackToolbar = () => {
  const navigate = useNavigate();
  return (
    <Toolbar>
      <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
        Voltar
      </Button>
    </Toolbar>
  );
};
