import Box from "@mui/material/Box";
import { Autocomplete, Button, LinearProgress, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "@emotion/styled";
import { toast } from "react-hot-toast";
import { BackToolbar } from "../../../components/BackToolbar";
import { GroupDropdown } from "../../../components/GroupDropdown";
import {
  Manufacturers,
  useProductCatalogFormPage_ProductCatalogQuery,
  useProductCatalogFormPage_UpsertProductCatalogMutation,
} from "../../../../generated/graphql";
import { useTenant } from "../../../context/TenantContext";

type ProductCatalogFormData = {
  uuid?: string;
  name?: string;
  Manufacturer?: Manufacturers;
  code?: string;
};

export function ProductCatalogFormPage() {
  const { id } = useParams();
  const { data, loading } = useProductCatalogFormPage_ProductCatalogQuery({
    variables: { uuid: id! },
    skip: !id,
  });
  const [upsertProductCatalog, { loading: upsertLoading }] =
    useProductCatalogFormPage_UpsertProductCatalogMutation();
  const { groupId } = useTenant();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const manufacturer = searchParams.get("manufacturer") as Manufacturers;

  const onSubmit = async (data: ProductCatalogFormData) => {
    if (groupId) {
      await toast.promise(
        upsertProductCatalog({
          variables: {
            uuid: id,
            name: data.name,
            code: data.code,
            Manufacturer: manufacturer,
          },
        }),
        {
          loading: "Cadastrando",
          success: "Sucesso!",
          error: "Erro, tente novamente.",
        }
      );
      navigate(`/ravpro/product-catalogs?manufacturer=${manufacturer}`);
    }
  };

  if (!groupId) {
    return (
      <Box>
        <BackToolbar />
        Selecione um grupo
      </Box>
    );
  }
  return (
    <Box>
      <BackToolbar />
      {!loading ? (
        <ProductCatalogForm
          {...data?.productCatalog}
          Manufacturer={manufacturer}
          onSubmit={onSubmit}
          loading={upsertLoading}
        />
      ) : (
        <LinearProgress />
      )}
    </Box>
  );
}

const Form = styled("form")`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 400px;
  margin: 16px auto;
`;

const Line = styled("div")`
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
`;

type ProductCatalogFormProps = ProductCatalogFormData & {
  onSubmit: (data: ProductCatalogFormData) => void;
  loading: boolean;
};

const ProductCatalogForm: React.FC<ProductCatalogFormProps> = ({
  name,
  Manufacturer,
  code,
  onSubmit,
  loading,
}) => {
  const defaultValues = {
    name,
    Manufacturer,
    code,
  };
  const { handleSubmit, control, setValue } = useForm({
    defaultValues,
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <GroupDropdown disabled />
      <Line />
      <Autocomplete
        disabled
        options={[
          Manufacturers.Original,
          Manufacturers.Ravpro,
          Manufacturers.Other,
        ]}
        renderInput={(params) => <TextField {...params} label="Fabricante" />}
        onChange={(_e, value) => value && setValue("Manufacturer", value)}
        isOptionEqualToValue={(option, value) => option === value}
        defaultValue={Manufacturer || null}
      />
      <Controller
        name="name"
        control={control}
        defaultValue={defaultValues.name ?? ""}
        render={({ field }) => (
          <TextField
            inputProps={field}
            label="Nome"
            helperText="Sem acento e sem cedilha"
          />
        )}
      />

      <Controller
        name="code"
        control={control}
        defaultValue={defaultValues.code ?? ""}
        render={({ field }) => (
          <TextField inputProps={field} label="Codigo Sistema" />
        )}
      />

      <Button type="submit" variant="contained" disabled={loading}>
        Cadastrar
      </Button>
    </Form>
  );
};

// const parseManufacturer = (x: string | undefined): Manufacturers => {
//   if (!x) return Manufacturers.Original;
//   switch (x) {
//     case "ORIGINAL": {
//       return Manufacturers.Original;
//     }
//     case "RAVPRO": {
//       return Manufacturers.Ravpro;
//     }
//     case "OTHER": {
//       return Manufacturers.Other;
//     }
//     default: {
//       return Manufacturers.Original;
//     }
//   }
// };
