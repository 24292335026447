export default {
  RAVPRO: "dashboard",
  DAF: "dashboard",
  PREMIAÇÕES: "cup",
  PEÇAS: "package",
 "PEÇAS - INDIVIDUAL": "hummer",
  SYONET: "shortcode",
  BDC: "headphone-alt",
  VEICULOS: "car",
  "VEICULOS - INDIVIDUAL": "key",
  "PÓS-VENDAS": "hummer",
  "PÓS-VENDAS - INDIVIDUAL": "receipt",
  VENDAS: "shopping-cart-full",
  "VENDAS - INDIVIDUAL": "shopping-cart",
  ADMINISTRATIVO: "marker-alt",
  "1-TABELAS": "layout-accordion-merged",
  "2-INTEGRAÇÕES": "flickr-alt",
  "3-AUTOMAÇÕES": "control-play",

} as Record<string, string>;
