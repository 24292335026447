import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

export type DeleteModalProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  loading: boolean;
};

export const DeleteModal: React.FC<DeleteModalProps> = ({
  open,
  onClose,
  onConfirm,
  loading,
}) => {
  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      onBackdropClick={onClose}
      open={open}
    >
      <DialogTitle id="confirmation-dialog-title">Excluir</DialogTitle>
      <DialogContent dividers>
        Você tem certeza que deseja excluir este registro?
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          Cancelar
        </Button>
        <Button variant="contained" onClick={onConfirm} disabled={loading}>
          {loading ? (
            <CircularProgress style={{ color: "white" }} />
          ) : (
            "Excluir"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
