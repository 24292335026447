import styled from "@emotion/styled";
import {
  Icon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useTenant } from "../../../context/TenantContext";
import { TenantSelectorFrame } from "../../../components/TenantSelectorFrame";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../../../components/DeleteModal";
import { useState } from "react";
import {
  useSellersTablePage_DeleteSellerMutation,
  useSellersTablePage_SellersQuery,
} from "../../../../generated/graphql";

const Container = styled("div")`
  margin: 32px;
`;

const CToolbar = styled(Toolbar)`
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
`;

const Title = styled(Typography)`
  flex: 1;
`;

export const SellerTablePage = () => {
  const navigate = useNavigate();
  const [deleteId, setDeleteId] = useState<string | undefined>();
  const [deleteSeller, { loading: deleteLoading }] =
    useSellersTablePage_DeleteSellerMutation();
  const { data, refetch } = useSellersTablePage_SellersQuery();

  return (
    <Container>
      <Paper>
        <CToolbar>
          <Title>Vendedores Rav</Title>
          <IconButton onClick={() => navigate("/ravpro/sellers/create")}>
            <Icon>add</Icon>
          </IconButton>
        </CToolbar>
        <Table>
          <TableBody>
            {_.orderBy(data?.sellers, "name").map((row) => (
              <TableRow key={row.uuid} hover>
                <TableCell>{row.name}</TableCell>
                <TableCell align="right">
                  <IconButton
                    onClick={() =>
                      navigate(`/ravpro/sellers/update/${row.uuid}`)
                    }
                  >
                    <Icon>edit</Icon>
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setDeleteId(row.uuid);
                    }}
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {data?.sellers && data.sellers.length === 0 ? (
              <TableRow>
                <TableCell align="center">Nenhum registro encontrado</TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </Paper>
      <DeleteModal
        open={!!deleteId}
        onClose={() => setDeleteId(undefined)}
        loading={deleteLoading}
        onConfirm={async () => {
          await deleteSeller({ variables: { uuid: deleteId! } });
          setDeleteId(undefined);
          refetch();
        }}
      />
    </Container>
  );
};
