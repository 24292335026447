import {
  OrderEntryFormPage_EntryQuery,
  OrderEntryFormPage_InitialDataQuery,
} from "../../../../generated/graphql";
import { DataGrid, GridColumns, GridRowsProp } from "@mui/x-data-grid";
import { useState, forwardRef, useImperativeHandle } from "react";
import { TableContainer, TableTitleText } from "./components";

type ProductOrdersTableProps = {
  rows: GridRowsProp;
  columns: GridColumns;
};

type ProductOrdersRowMap = Record<
  string,
  {
    productId: string;
    quantity: number;
  }
>;

export type ProductOrdersTableHandler = {
  getValue: () => ProductOrdersRowMap;
};

export const OrderProductTable = forwardRef<
  ProductOrdersTableHandler,
  ProductOrdersTableProps
>(({ rows, columns }, ref) => {
  const [value, setValue] = useState<ProductOrdersRowMap>({});
  useImperativeHandle(
    ref,
    () => ({
      getValue: () => value,
    }),
    [value]
  );
  return (
    <TableContainer>
      <TableTitleText>Produtos</TableTitleText>
      <DataGrid
        rows={rows}
        columns={columns}
        hideFooter
        disableColumnFilter
        disableColumnMenu
        onCellEditCommit={(params) => {
          setValue({
            ...value,
            [params.id]: {
              productId: params.id as string,
              quantity: params.value as number,
            },
          });
        }}
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            textOverflow: "clip",
            whiteSpace: "break-spaces",
            lineHeight: 1,
          },
        }}
      />
    </TableContainer>
  );
});

type ParseProductTablePropsParams = {
  products: OrderEntryFormPage_InitialDataQuery["products"];
  entry: OrderEntryFormPage_EntryQuery["entry"] | undefined;
};

export const parseProductOrderTableProps = ({
  products,
  entry,
}: ParseProductTablePropsParams) => {
  const productColumns: GridColumns = [
    {
      field: "product",
      headerName: "Product",
      editable: false,
      width: 400,
    },
    {
      field: "quantity",
      headerName: "Pedido",
      editable: true,
      width: 400,
      type: "number",
    },
  ];

  const createdRows: Record<
    string,
    OrderEntryFormPage_EntryQuery["entry"]["ProductOrderEntries"][0]
  > =
    entry?.ProductOrderEntries.reduce(
      (acc, i) => ({
        ...acc,
        [i.Product.uuid]: i,
      }),
      {}
    ) ?? {};
  const productRows: GridRowsProp =
    products.map((i) => ({
      id: i.uuid,
      product: `${i.ProductCatalog?.name} ${
        i.productCode ? `(${i.productCode})` : ""
      }`,
      quantity: createdRows[i.uuid]?.quantity,
    })) ?? [];
  return {
    productColumns,
    productRows,
  };
};

export const createProductOrderList = ({
  products,
}: CreateProductOrderList) => {
  const obj: Record<string, { productId: string; quantity: 0 }> = {};
  for (var i of products) {
    obj[i.uuid] = {
      productId: i.uuid,
      quantity: 0,
    };
  }
  return obj;
};

type CreateProductOrderList = {
  products: OrderEntryFormPage_InitialDataQuery["products"];
};
