import { TextField, TextFieldProps } from "@mui/material";
import React from "react";
import { IMaskInput } from "react-imask";

export const CPFTextField = (props: TextFieldProps) => {
  return (
    <TextField
      {...props}
      InputProps={{
        inputComponent: CPFMaskInput,
      }}
    />
  );
};

const CPFMaskInput = React.forwardRef<HTMLElement, any>(function TextMaskCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="000.000.000-00"
      inputRef={ref}
      onAccept={(value: any) =>
        onChange({ target: { name: props.name, value } })
      }
      overwrite
    />
  );
});
