import Box from "@mui/material/Box";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import {
  Premium,
  useSellersFormPage_SellerQuery,
  useSellersFormPage_SellersQuery,
  useSellersFormPage_UpsertSellerMutation,
} from "../../../../generated/graphql";
import { useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { toast } from "react-hot-toast";
import { useTenant } from "../../../context/TenantContext";
import { BackToolbar } from "../../../components/BackToolbar";
import { CPFTextField } from "../../../../components/CPFTextField";
import { validateCPF } from "../../../../util/validateCPF";

type SellerFormData = {
  active?: boolean;
  name?: string;
  cpf?: string;
  erpCode?: string;
  email?: string;
  sellerId?: string;
};

export function SellerFormPage() {
  const { id } = useParams();
  const { data, loading } = useSellersFormPage_SellerQuery({
    variables: { uuid: id! },
    skip: !id,
  });
  const [upsertSeller, { loading: upsertLoading }] =
    useSellersFormPage_UpsertSellerMutation();
  const { data: sellers, loading: sellersLoading } =
    useSellersFormPage_SellersQuery();
  const navigate = useNavigate();
  const onSubmit = async (data: SellerFormData) => {
    if (data.cpf && data.cpf.trim().length < 14) {
      toast.error("CPF deve ser preenchido");
      return;
    }
    if (!validateCPF(data.cpf!)) {
      toast.error("CPF inválido");
      return;
    }
    await toast.promise(
      upsertSeller({
        variables: {
          uuid: id,
          active: data.active,
          name: data.name?.toUpperCase(),
          cpf: data.cpf?.trim(),
          erpCode: data.erpCode,
          email: data.email,
          sellerId: data.sellerId,
        },
      }),
      {
        loading: "Cadastrando",
        success: "Sucesso!",
        error: "Erro, tente novamente.",
      }
    );
    navigate("/ravpro/sellers");
  };
  return (
    <Box>
      <BackToolbar />
      {!loading && !sellersLoading ? (
        <SellerForm
          {...data?.seller}
          onSubmit={onSubmit}
          loading={upsertLoading}
          sellers={sellers?.sellers ?? []}
          sellerId={data?.seller.Manager?.uuid}
        />
      ) : (
        <LinearProgress />
      )}
    </Box>
  );
}

const Form = styled("form")`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 400px;
  margin: 16px auto;
`;

type SellerFormProps = SellerFormData & {
  onSubmit: (data: SellerFormData) => void;
  loading: boolean;
  sellers: { uuid: string; name?: string }[];
};

const SellerForm: React.FC<SellerFormProps> = ({
  active,
  name,
  cpf,
  erpCode,
  email,
  sellerId,
  sellers,
  onSubmit,
  loading,
}) => {
  const defaultValues = {
    active,
    name,
    cpf,
    erpCode,
    email,
    sellerId,
  };
  const { handleSubmit, control, setValue } = useForm({
    defaultValues,
  });
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormControlLabel
        control={
          <Checkbox
            name="active"
            defaultChecked={
              defaultValues.active === undefined ? true : defaultValues.active
            }
            onChange={(e) => {
              setValue("active", e.target.checked);
            }}
          />
        }
        label="Ativo"
      />
      <Controller
        name="name"
        control={control}
        defaultValue={defaultValues.name ?? ""}
        render={({ field }) => (
          <TextField
            inputProps={{ ...field, style: { textTransform: "uppercase" } }}
            label="Nome"
          />
        )}
      />
      <Controller
        name="cpf"
        control={control}
        defaultValue={defaultValues.cpf ?? ""}
        render={({ field: { onChange, onBlur, ref, ...rest } }) => (
          <CPFTextField
            {...rest}
            label="CPF"
            onBlur={onBlur}
            onChange={onChange}
            inputRef={ref}
          />
        )}
      />
      <Controller
        name="erpCode"
        control={control}
        defaultValue={defaultValues.erpCode ?? ""}
        render={({ field }) => (
          <TextField inputProps={field} label="Código ERP" />
        )}
      />
      <Controller
        name="email"
        control={control}
        defaultValue={defaultValues.email ?? ""}
        render={({ field }) => <TextField inputProps={field} label="Email" />}
      />
      <FormControl>
        <InputLabel>Gerente</InputLabel>
        <Controller
          defaultValue={defaultValues.sellerId ?? ""}
          render={({ field }) => (
            <Select {...field} label="Gerente">
              <MenuItem value="">
                <em>Selecione</em>
              </MenuItem>
              {sellers.map((i) => (
                <MenuItem key={i.uuid} value={i.uuid}>
                  {i.name}
                </MenuItem>
              ))}
            </Select>
          )}
          control={control}
          name="sellerId"
        />
      </FormControl>
      <Button type="submit" variant="contained" disabled={loading}>
        Cadastrar
      </Button>
    </Form>
  );
};
