import { Grid } from "@mui/material";

import { Outlet } from "react-router";
import { styled } from "@mui/material/styles";
import { useTheme, useMediaQuery } from "@mui/material";
import { getGroupTheme } from "../groups";

const LeftGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
`;

const RightGrid = styled(Grid)`
  background: ${() => getGroupTheme().theme.loginRightBackgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 900px) {
    display: none;
  }
`;

const LogoContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
`;

const Logo = styled("img")`
  height: ${() =>
    getGroupTheme().theme.loginLogoHeight
      ? `${getGroupTheme().theme.loginLogoHeight}px`
      : "130px"};
`;

const LogoSubtitle = styled("div")`
  color: rgba(255, 255, 255, 0.5);
  font-size: 10px;
  margin-bottom: 24px;
  font-weight: 100px;
`;

const Spacer = styled("div")`
  flex: 1;
`;

export const LoginLayout = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Grid container>
        <LeftGrid item xs={12} sm={12} md={5}>
          <Outlet />
        </LeftGrid>
        {!matches ? (
          <RightGrid item xs={false} sm={false} md={7}>
            <LogoContainer>
              <Spacer />
              <Logo src={getGroupTheme().loginLogo} alt="" />
              <Spacer />
              <LogoSubtitle>Powered by USoft Tecnologia</LogoSubtitle>
            </LogoContainer>
          </RightGrid>
        ) : null}
      </Grid>
    </>
  );
};
