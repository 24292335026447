import styled from "@emotion/styled";
import {
  Box,
  Icon,
  IconButton,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material";
import _ from "lodash";
import {
  useEntryTablePage_EntriesQuery,
  useEntryTablePage_DeleteEntryMutation,
  PremiumCalculationType,
} from "../../../../generated/graphql";
import { useTenant } from "../../../context/TenantContext";
import { TenantSelectorFrame } from "../../../components/TenantSelectorFrame";
import { useNavigate, useSearchParams } from "react-router-dom";
import DeleteModal from "../../../../components/DeleteModal";
import { useState } from "react";
import { format } from "date-fns";
import { TabPanel } from "../../../components/TabPanel";

const Container = styled("div")`
  margin: 32px;
`;

const CToolbar = styled(Toolbar)`
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
`;

const Title = styled(Typography)`
  flex: 1;
`;

export const EntryTablePage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTab = searchParams.get(
    "premiumCalculationType"
  ) as PremiumCalculationType;
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={selectedTab}
          onChange={(_, value) => {
            setSearchParams({ premiumCalculationType: value });
          }}
        >
          <Tab label="Venda" value={PremiumCalculationType.Sale} />
          <Tab
            label="Pedido (VHSYS)"
            value={PremiumCalculationType.OrderVhsys}
          />
        </Tabs>
      </Box>
      <TabPanel value={selectedTab} tab={PremiumCalculationType.Sale}>
        <EntryTable tab={selectedTab} />
      </TabPanel>
      <TabPanel value={selectedTab} tab={PremiumCalculationType.OrderVhsys}>
        <EntryTable tab={selectedTab} />
      </TabPanel>
    </>
  );
};

type EntryTableProps = {
  tab: PremiumCalculationType;
};

export const EntryTable = ({ tab }: EntryTableProps) => {
  const { dealershipId } = useTenant();
  const navigate = useNavigate();
  const [deleteId, setDeleteId] = useState<string | undefined>();
  const [deleteEntry, { loading: deleteLoading }] =
    useEntryTablePage_DeleteEntryMutation();
  const { data, refetch } = useEntryTablePage_EntriesQuery({
    variables: { dealershipId: dealershipId!, PremiumCalculationType: tab },
    skip: !dealershipId,
  });
  const [searchParams] = useSearchParams();
  const premiumCalculationType = searchParams.get(
    "premiumCalculationType"
  ) as PremiumCalculationType;
  return (
    <Container>
      <TenantSelectorFrame>
        <Paper>
          <CToolbar>
            <Title>Lançamentos</Title>
            <IconButton
              onClick={() =>
                navigate(
                  `/ravpro/entries/create?premiumCalculationType=${premiumCalculationType}`
                )
              }
            >
              <Icon>add</Icon>
            </IconButton>
          </CToolbar>
          <Table>
            {data?.entries && data?.entries.length > 0 ? (
              <TableHead>
                <TableRow>
                  <TableCell>Empresa</TableCell>
                  <TableCell>Data lançamento</TableCell>
                  <TableCell>Data criação</TableCell>
                  <TableCell>Data atualização</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
            ) : null}
            <TableBody>
              {_.orderBy(data?.entries, "date").map((row) => (
                <TableRow key={row.uuid} hover>
                  <TableCell>{row.Dealership.fantasyName}</TableCell>
                  <TableCell>
                    {format(new Date(row.date), "dd/MM/yyyy")}
                  </TableCell>
                  <TableCell>
                    {format(new Date(row.createdAt), "dd/MM/yyyy")}
                  </TableCell>
                  <TableCell>
                    {format(new Date(row.updatedAt), "dd/MM/yyyy")}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() =>
                        navigate(
                          `/ravpro/entries/update/${row.uuid}?premiumCalculationType=${premiumCalculationType}`
                        )
                      }
                    >
                      <Icon>edit</Icon>
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setDeleteId(row.uuid);
                      }}
                    >
                      <Icon>delete</Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {data?.entries && data.entries.length === 0 ? (
                <TableRow>
                  <TableCell align="center">
                    Nenhum registro encontrado
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </Paper>
        <DeleteModal
          open={!!deleteId}
          onClose={() => setDeleteId(undefined)}
          loading={deleteLoading}
          onConfirm={async () => {
            await deleteEntry({ variables: { uuid: deleteId! } });
            setDeleteId(undefined);
            refetch();
          }}
        />
      </TenantSelectorFrame>
    </Container>
  );
};
