import theme from "./theme";
import logo from "./logo.png";
import group from "./group.png";
import loginLogo from "./login_logo.png";

const data = {
  logo,
  group,
  loginLogo,
  theme,
};

export default data;
