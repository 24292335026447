import React, { useState } from "react";
import { axios } from "../api";
import { parseDashboardNames } from "../util/parseDashboardNames";
import orderBy from "lodash.orderby";
import groupBy from "lodash.groupby";
import {
  AuthContext_UserRolesQuery,
  useAuthContext_UserRolesQuery,
} from "../generated/graphql";

export type Dashboards = {
  Id: string;
  CategoryName: string;
  Dashboards: Dashboard[];
}[];

interface AuthContextType {
  user: User | undefined;
  userDetails: UserDetails | undefined;
  signin: (username: string, password: string) => Promise<User>;
  signout: () => void;
  loading: boolean;
  dashboards: Dashboards | undefined;
  reloadUserDetails: () => void;
  userRoles: AuthContext_UserRolesQuery["userRoles"];
}

type UserDetails = {
  Avatar: string;
  ContactNumber: string;
  DirectoryType: number;
  DisplayName: string;
  Email: string;
  FirstName: string;
  IsActive: boolean;
  Lastname: string;
  UserId: number;
  UserStatus: number;
  Username: string;
};

export interface Dashboard {
  Id: string;
  Name: string;
  EmbedUrl: string;
  CategoryId: string;
  CategoryName: string;
}

export type User = {
  email: string;
  idpreferenceid: string;
  username: string;
  access_token: string;
  token_type: string;
  expires_in?: string;
  ".issued": string;
  ".expires": string;
  LoginResult: string;
  LoginStatusInfo: string;
};

export const getUserInLocalStorage = (): User | undefined => {
  const userString = localStorage.getItem("user") || "";
  try {
    return JSON.parse(userString) as User;
  } catch (err) {}
};

export const setUserInLocalStorage = (user: User) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const AuthContext = React.createContext<AuthContextType>(null!);

export const authProvider = {
  async signin(username: string, password: string) {
    const res = await axios.post("/token", {
      username,
      password,
      grant_type: "password",
    });
    if (res.data.error) {
      throw new Error(res.data.error);
    }
    const user = res.data as User;
    localStorage.setItem("user", JSON.stringify(user));
    return user;
  },
  async signout() {
    localStorage.removeItem("user");
    localStorage.removeItem("session");
    localStorage.removeItem("dealershipId");
    localStorage.removeItem("groupId");
  },
};

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = React.useState<User | undefined>();
  const [dashboards, setDashboards] = React.useState<Dashboards | undefined>();
  const [loading, setLoading] = React.useState(true);
  const [userDetails, setUserDetails] = useState<UserDetails | undefined>();
  const { data } = useAuthContext_UserRolesQuery({
    variables: { email: user?.email! },
    skip: !user?.email,
  });
  const loadUserDetails = async (u: User) => {
    await loadUserDetailsByEmail(u.email);
  };

  const loadUserDetailsByEmail = async (email: string) => {
    try {
      const res = await axios.get(`/v2.0/users/${email}`);
      setUserDetails({
        ...res.data,
        Avatar: parseAvatarBase64(res.data.Avatar),
      });
    } catch (err) {}
  };

  const reloadUserDetails = async () => {
    user?.email && (await loadUserDetailsByEmail(user.email));
  };

  const loadDashboard = async () => {
    try {
      const res = await axios.get<{ Data: Dashboard[] }>("/v4.0/dashboards", {
        params: {
          page_size: 100,
        },
      });

      setDashboards(
        orderBy(
          Object.values(groupBy(res.data.Data, "CategoryId")).map((i) => ({
            Id: i[0].CategoryId,
            CategoryName: parseDashboardNames(i[0].CategoryName),
            Dashboards: orderBy(
              i.filter(mobileFilter(window.screen.width)).map((j) => ({
                ...j,
                Name: j.Name.replaceAll("MOBILE", "").replaceAll("DESKTOP", ""),
              })),
              "Name"
            ),
          })),
          "CategoryName"
        ).filter((i) => i.Dashboards.length > 0)
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const preloadData = async () => {
    const userString = localStorage.getItem("user");
    if (userString && userString.length > 0) {
      const user = JSON.parse(userString);
      setUser(user);
      await loadUserDetails(user);
      await loadDashboard();
    } else {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    preloadData();
  }, []);

  const signin = async (username: string, password: string) => {
    const u = await authProvider.signin(username, password);
    setUser(u);
    await loadUserDetails(u);
    await loadDashboard();
    return u;
  };

  const signout = () => {
    authProvider.signout();
    setUser(undefined);
    setUserDetails(undefined);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        userDetails,
        signin,
        signout,
        dashboards,
        loading,
        reloadUserDetails,
        userRoles: data?.userRoles ?? [],
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return React.useContext(AuthContext);
}

const parseAvatarBase64 = (str?: string) =>
  str ===
    "iVBORw0KGgoAAAANSUhEUgAAAJYAAACWCAYAAAFLBkF0AAAABGdBTUEAALGPC/xhBQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAlqADAAQAAAABAAAAlgAAAAA0g2oaAAABoElEQVR4Ae3QgQAAAADDoPlTH+SFUGHAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDBgwYMGDAgAEDXwMDYLkAAWcPVEUAAAAASUVORK5CYII=" ||
  str === undefined
    ? undefined
    : `data:image/png;base64,${str}`;

const mobileFilter = (width: number) => (i: Dashboard) => {
  const isMobile = width < 770;

  if (isMobile) {
    return !i.Name.includes("DESKTOP");
  }
  return !i.Name.includes("MOBILE");
};
